import { gql } from '@apollo/client';

export const GET_INCOMPLETE_ORDERS = gql`
    query GetIncompleteOrders($searchString: String) {
        getIncompleteOrders(searchString: $searchString) {
            results {
                agronomist
                agronomistGuid
                contractNumber
                dateOrdered
                field{
                  guid
                  fieldName
                }
                grower{
                  guid
                  name
                }
                guid
                lineItems{
                    bridgeFeaturesDelivered
                    complete
                    deviceName
                    deviceNotes
                    deviceNameNeeded
                    externalData
                    fieldAccessNotes
                    gatewayNeeded
                    guid
                    imageFileName
                    itemGuid
                    lat
                    lng
                    minimumQuantity
                    name
                    pinFileName
                    price
                    sellingUnit
                    summary
                }
                salesPerson
                salesPersonGuid
                totalCost
            }
        }
    }
`;

export const GET_ORDER_BY_GROWER = gql`
    query GetOrderByGrower($guid: ID!){
        getOrderByGrower(guid: $guid){
            ok
            results{
                contractNumber
                dateOrdered
                field {
                  guid
                  fieldName
                }
                grower {
                  guid
                  name
                }
                guid
                lineItems {
                  bridgeFeaturesDelivered
                  complete
                  deviceName
                  deviceNotes
                  deviceNameNeeded
                  externalData
                  fieldAccessNotes
                  guid
                  imageFileName
                  itemGuid
                  lat
                  lng
                  pinFileName
                  price
                  sellingUnit
                  summary
                }
                salesPerson
                salesPersonGuid
                totalCost
              }
        }
    }
`;