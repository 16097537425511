import React from 'react';

import PageHeader from '../PageHeader';
// import TopNav from '../TopNav';

const PageWrapper = (props) => {
    return(
        <>
            <PageHeader {...props} />
            <div className={(props.className) ? 'content ' + props.className : "content" }>
                {/* <TopNav {...props} /> */}
                {props.children}
            </div>
        </>
    );
}

export default PageWrapper;