import React, { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import AccordionHeader from '../../components/AccordionHeader';
import FieldListCard from './FieldListCard';
import { properCase } from '../../services/Data';

const GrowerCard = (props) => {
    const { grower, handleGrowerOpen, growerOpen } = props;
    const [ fieldOpen, setFieldOpen ] = useState(null);

    const handleFieldOpen = (fieldId) => {
        const fieldName = 'field-' + fieldId;

        if ( fieldId === null ) {
            return;
        }

        if ( fieldName !== fieldOpen ) {
            setFieldOpen(fieldName);
        } else {
            setFieldOpen(null);
        }
    }

    useEffect(() => {
        return function cleanUp() {
            handleFieldOpen(null);
        }
    });

    return(
        <Card>
            <AccordionHeader 
                eventKey={grower.guid} 
                onClick={() => handleGrowerOpen(grower.guid)} 
                isOpen={growerOpen}
            >
                {properCase(grower.name)}
            </AccordionHeader>
            <Accordion.Collapse eventKey={grower.guid} mountOnEnter unmountOnExit>
                <Card.Body>
                    <Accordion>
                        <FieldListCard 
                            grower={grower} 
                            handleFieldOpen={handleFieldOpen}
                            fieldOpen={fieldOpen}
                        />
                    </Accordion>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

export default GrowerCard;