import React, { useEffect, useState, useCallback } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ContentHeader from '../../../components/ContentHeader';
import Firmware from '../../Dashboard/Types/Firmware'
import Gateway from '../../Dashboard/Types/Gateway'
import Status from '../../Dashboard/Types/Status'
import Moisture from '../../Dashboard/Types/Moisture'
import Rain from '../../Dashboard/Types/Rain'
import Gps from '../../Dashboard/Types/Gps'
import Pivot from '../../Dashboard/Types/Pivot'
import Sensor from '../../Dashboard/Types/Sensor';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';

const messageTypes = ['firmware', 'rain', 'pivot', 'moisture', 'gps', 'status'];

const CheckDeviceModal = (props) => {
  const {
    isOpen,
    setModalOpen,
    selectedDevice,
  } = props;
  //const currentDevice = selectedDevice.properties.deveui;

  const handleOnHide = () => {
    setModalOpen(!isOpen);
    setMessageType(null);
    setCurrentDevice('');
  }

  const now = new Date();
  const lastWeek = new Date(now.valueOf() - (7 * 24 * 60 * 60 * 1000));

  const [form, setForm] = useState(null);
  const [messageType, setMessageType] = useState(null);
  const [startDate, setStartDate] = useState(lastWeek);
  const [endDate, setEndDate] = useState(now);
  const [deveui, setDeveui] = useState('');
  const [device, setDevice] = useState('');
  const [checkDevice, setCheckDevice] = useState('');
  const [currentDevice, setCurrentDevice] = useState('');
  // 1049169

  const handleDeviceInput = useCallback(() => {
    setCheckDevice(currentDevice);
  }, [currentDevice, setCheckDevice]);

  const handleSetType = useCallback((event) => {
    setMessageType(event.target.value);
  }, [setMessageType]);

  const handleSetDeveui = useCallback(() => {
    setDeveui(parseInt(currentDevice));
  }, [currentDevice, setDeveui]);

  const handleSetDeviceToCheck = useCallback(() => {
    setDevice(parseInt(checkDevice))
  }, [checkDevice, setDevice]);


  useEffect(() => {
    if (form === null) {
      setForm({ messageType: '', deveui: '' });
    }
  }, [form]);

  useEffect(() => {
    if (currentDevice !== '' && deveui !== currentDevice) {
      handleSetDeveui();
    }
  }, [handleSetDeveui, deveui, currentDevice]);

  useEffect(() => {
    if (checkDevice !== '' && device !== checkDevice) {
      setDevice(null);
    }
  }, [handleSetDeviceToCheck, device, checkDevice]);

  useEffect(() => {
    if (currentDevice !== '' && checkDevice !== currentDevice) {
      handleDeviceInput();
    }
  }, [handleDeviceInput, checkDevice, currentDevice]);

  useEffect(() => {
    if (selectedDevice !== '' && currentDevice === '' && selectedDevice.properties && selectedDevice.properties.deveui !== currentDevice) {
      setCurrentDevice(selectedDevice.properties.deveui);
    }
  }, [selectedDevice, currentDevice])

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isOpen}
      onHide={handleOnHide}
    >
      <div className="dashboard-modal">
        <Modal.Body className="device-checker-modal">
          <ContentHeader title="Moisture Device Checker" />
          <Col xs={12} md={6} align="center">
            <Row>
              <Form.Control
                type="text"
                as="input"
                name="device"
                size="sm"
                placeholder="Enter a deveui"
                value={device || '' || checkDevice}
                onChange={handleDeviceInput}
              />
              <Button variant="primary" size="sm" onClick={handleSetDeviceToCheck}>Check Device</Button>
            </Row>
          </Col>
          <Col xs={12}>
            {device && !isNaN(device) && <Sensor device={device} />}
          </Col>
          <ContentHeader title="Device Dashboard" />
          <Col xs={12} md={9} align="center">
            {/* {form && */}
            <Row>
              <Form.Control
                type="text"
                as="input"
                name="deveui"
                size="sm"
                placeholder="Enter a deveui"
                value={deveui || ''}
                onChange={handleSetDeveui}
              >
              </Form.Control>
            </Row>
            {/* } */}
            {/* {form && */}
            <Row>
              <Form.Control
                as="select"
                size="sm"
                name="messageType"
                value={messageType || ''}
                onChange={handleSetType}
              >

                <option value="">Select a Message Type</option>
                {messageTypes.map((m, index) =>
                  <option value={m} key={index}>{m}</option>
                )}
              </Form.Control>
            </Row>
            {/* } */}

            <Row>
              <Form.Label>Start Date: &nbsp;</Form.Label>
              <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
              <div style={{ width: "2.5vw" }}></div>
              <Form.Label>End Date: &nbsp;</Form.Label>
              <DatePicker selected={endDate} onChange={date => setEndDate(date)} />
            </Row>
          </Col>
          <Col xs={12}>
            {deveui && !isNaN(deveui) &&
              <div>
                {messageType === 'firmware' && <Firmware deveui={deveui} start={startDate.valueOf()} end={endDate.valueOf()} />}
                {messageType === 'status' && <Status deveui={deveui} start={startDate.valueOf()} end={endDate.valueOf()} />}
                {messageType === 'gateway' && <Gateway deveui={deveui} start={startDate.valueOf()} end={endDate.valueOf()} />}
                {messageType === 'moisture' && <Moisture deveui={deveui} start={startDate.valueOf()} end={endDate.valueOf()} />}
                {messageType === 'rain' && <Rain deveui={deveui} start={startDate.valueOf()} end={endDate.valueOf()} />}
                {messageType === 'pivot' && <Pivot deveui={deveui} start={startDate.valueOf()} end={endDate.valueOf()} />}
                {messageType === 'gps' && <Gps deveui={deveui} start={startDate.valueOf()} end={endDate.valueOf()} />}
              </div>
            }
          </Col>

        </Modal.Body>

        <Modal.Footer><Button variant="light" onClick={handleOnHide}>Close</Button></Modal.Footer>
      </div> </Modal>
  );

}


export default CheckDeviceModal;
