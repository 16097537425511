import React, { useRef } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { ChevronDown, ChevronRight } from 'react-feather';

const AccordionHeader = (props) => {
    const {
        onClick,
        parent,
        title,
        children,
        isOpen,
        locked,
        eventKey,
    } = props;

    const toggleRef = useRef(null);

    const getParent = () => {
        let $parent = undefined;

        if (parent) {
            if (parent.indexOf('#') > -1 && parent.indexOf('#') < 1) {
                $parent = document.getElementById(parent.replace(/#/, ''));

            } else if (parent.indexOf('.') > -1 && parent.indexOf('.') < 1) {
                $parent = document.getElementsByClassName(parent.replace(/./, ''));
                $parent = $parent[0];
            }

        } else {
            $parent = window;
        }

        return $parent;
    }

    const handleClick = ({ ...props }) => {
        onClick();
        if ((!locked && !isOpen) || (isOpen !== eventKey)) {
            const $parent = getParent();

            if (!parent || parent === null) {
                window.setTimeout(() => {
                    const offset = toggleRef.current.getBoundingClientRect();
                    const scrollTop = offset.top + (window.scrollY - 75);

                    window.scrollTo({
                        top: scrollTop,
                        behavior: 'smooth'
                    });
                }, 500);

            } else if (!locked) {
                $parent.scrollTop = 0;
                window.setTimeout(() => {
                    const offset = toggleRef.current.getBoundingClientRect();
                    const pOffset = $parent.getBoundingClientRect();
                    const newY = offset.y - pOffset.y;

                    $parent.scrollTop = newY - 4;

                }, 500);
            }
        }
    }

    return (
        <Accordion.Toggle
            as={Card.Header}
            eventKey={eventKey}
            onClick={handleClick}
            ref={toggleRef}
        >
            {(title) ? (
                title
            ) : (
                children
            )}
            {(isOpen === eventKey || isOpen === true) ? (
                <ChevronDown className="chevron" />
            ) : (
                <ChevronRight className="chevron" />
            )}
        </Accordion.Toggle>
    )
}

export default AccordionHeader;
