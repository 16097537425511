import { gql } from '@apollo/client';

export const GET_ALL_CROPS = gql`
    query {
        getAllCrops {
            results {
                guid
                name
                growthStages{
                    name
                }
            }
        }
    }
`;