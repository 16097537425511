import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    Form,
    Modal,
    Row,
} from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import {
    irrigationTypes,
    cropYears,
} from './Charts/data/lookupFields';

import { GET_SOIL_TEXTURE_LIST } from '../../GraphQL/Queries/Things';
import { GET_ALL_CROPS } from '../../GraphQL/Queries/Crops';

const FieldSettingsModal = (props) => {
    const { place, show, onHide, handleFieldSettings, handleSetCropYear } = props;
    const [form, setForm] = useState(null);
    const [cropsList, setCropsList] = useState(null);
    const [growthStages, setGrowthStages] = useState(null);
    const [plantingDate, setPlantingDate] = useState(moment().unix());
    const [previousCrop, setPreviousCrop] = useState(null);
    const [previousGrowthStages, setPreviousGrowthStages] = useState(null);
    const [settingsCropYear, setSettingsCropYear] = useState("");

    const { loading: gettingCrops, error: errorGettingCrops, data: cropsData } = useQuery(GET_ALL_CROPS, { context: { service: 'crops' } });
    const { loading: gettingSoilTextures, error: errorSoilTextures, data: dataSoilTextures } = useQuery(GET_SOIL_TEXTURE_LIST, { context: { service: 'things' } });

    const handleSetFieldForm = (event) => {
        const place = { ...form.place };
        place[event.target.name] = event.target.value;
        setForm(f => ({ ...f, ...place }));
    }

    const handleResetCropForm = () => {
        const place = { ...form.place };
        if (previousCrop !== null) {
            place.link = previousCrop;
            setPreviousCrop(null);
        }

        if (previousGrowthStages !== null) {
            setGrowthStages(previousGrowthStages);
            setPreviousGrowthStages(null);
        }


        setForm(f => ({ ...f, ...place }));
    }

    const handleSetPlantingDate = (event) => {
        setPlantingDate(moment(event.target.value).unix());

        handleSetCrop(event);
    }

    const handleSetCrop = (event) => {
        const { name, value } = event.target;
        const place = form
        // console.log("PLACE", place);
        let thisCrop = (place.link) ? JSON.parse(JSON.stringify(place.link)) : {};
        if (previousCrop === null) {
            setPreviousCrop({ ...thisCrop });
        }


        if (Object.keys(thisCrop).length === 0) {
            thisCrop = {
                plantingDate: '',
                currentGrowthStage: '',
                maturity: ''
            }
            // console.log("BLANK CROP FOUND", thisCrop);
        }

        const selectedCrop = cropsList.filter((crop) => {
            return (crop.guid === value);
        });

        if (selectedCrop.length > 0) {
            if (previousGrowthStages === null) {
                setPreviousGrowthStages(growthStages);
            }
            const stages = selectedCrop[0].growthStages;
            setGrowthStages([...stages]);
            const cropName = selectedCrop[0].name;
            thisCrop.name = cropName;
        }

        if (name === 'cropName') {
            thisCrop['cropGuid'] = value;
        } else if (name === 'plantingDate') {
            thisCrop['plantingDate'] = moment(value).unix();

        } else {
            thisCrop[name] = value;
        }

        if (thisCrop.guid) {
            thisCrop.guid = null;
        }
        if (thisCrop !== null && thisCrop.cropGuid !== undefined) {
            place.link = thisCrop;
        }

        setForm(f => ({ ...f, ...place }));
    }

    const handleCropYearChange = (event) => {
        const value = event.target.value
        setSettingsCropYear(value)
    }

    const handleSave = () => {
        setPreviousGrowthStages(null);
        setPreviousCrop(null);
        handleFieldSettings(form);
        handleSetCropYear(settingsCropYear)
        onHide();
    }

    const getPlacePropertyValue = (prop) => {

        if (form === null) {
            return '';
        }

        return form[prop];
    }

    const getCropPropertyValue = (prop) => {
        // console.log("FORM", form);
        if (form === null) {
            return '';
        }
        // console.log("CROP PROPERTY VALUE", prop);
        let crop = { ...form.link };
        // console.log("CROP", crop); 

        if (Object.keys(crop).length === 0) {
            return '';
        }

        if (crop) {
            if (prop === 'cropName' && crop.cropGuid) {
                return crop.cropGuid;
            } else if (crop[prop]) {
                return crop[prop];
            } else {
                return '';
            }
        }

        return '';
    }

    useEffect(() => {
        if (place !== null) {
            // const place = { ...place };
            const { pivot } = place;

            if (pivot === null) {
                var placeCopy = { ...place };

                placeCopy.pivot = {
                    acresInches: 0,
                    pivotPassAmount: 0,
                    wellCapacity: 0,
                }
            }

            setForm(f => ({ ...f, ...place }));
        }
    }, [place]);

    useEffect(() => {
        if (!gettingSoilTextures && !errorSoilTextures) {
            const { getSoilTextureList } = dataSoilTextures;
            let results = JSON.parse(JSON.stringify(getSoilTextureList.results));
            results.sort();
        }
    }, [gettingSoilTextures, errorSoilTextures, dataSoilTextures]);

    useEffect(() => {
        if (!gettingCrops && !errorGettingCrops) {
            const { getAllCrops: { results } } = cropsData;

            if (results && results.length) {
                setCropsList([...results]);
            }
        }
    }, [gettingCrops, errorGettingCrops, cropsData]);

    useEffect(() => {
        if (place !== null && cropsList !== null) {
            var link = { ...place.link };
            if (link === null) {
                setPlantingDate(moment().unix());
            }

            if (!!link && !link.plantingDate) {
            } else if (!!link && !!link.plantingDate) {
                setPlantingDate(link.plantingDate);
            }

            const selectedCrop = cropsList.filter((crop) => {
                return (
                    !!link &&
                    crop.guid === link.cropGuid
                );
            });

            if (selectedCrop.length > 0) {
                const stages = selectedCrop[0].growthStages;
                setGrowthStages([...stages]);
            }
        }
    }, [place, cropsList])

    useEffect(() => {
        // const currentYear = new Date().getFullYear();
        if(settingsCropYear === ""){
            setSettingsCropYear("2023")
        }
    }, [setSettingsCropYear, settingsCropYear])

    if (form === null) {
        return (null);
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group>Crop Year</Form.Group>
                        <Form.Control
                            as="select"
                            name="cropYear"
                            value={settingsCropYear}
                            onChange={handleCropYearChange}
                        >
                            <option value=""></option>
                            {cropYears.map((type, index) =>
                                <option value={type} key={index}>{type}</option>
                            )}
                        </Form.Control>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label>Crop</Form.Label>
                            <Form.Control
                                as="select"
                                name="cropName"
                                value={getCropPropertyValue('cropName')}
                                onChange={handleSetCrop}
                            >
                                <option value=""></option>
                                {cropsList !== null && cropsList.map((crop, index) =>
                                    <option value={crop.guid} key={index}>{crop.name}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label>Irrigation Type</Form.Label>
                            <Form.Control
                                as="select"
                                name="irrigationType"
                                value={form['irrigationType'] || ''}
                                onChange={handleSetFieldForm}
                            >
                                <option value=""></option>
                                {irrigationTypes.map((type, index) =>
                                    <option value={type} key={index}>{type}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                {/* Row */}
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label>Planting Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="plantingDate"
                                value={moment.unix(plantingDate).format("YYYY-MM-DD")}
                                onChange={handleSetPlantingDate}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label>Well Capacity</Form.Label>
                            <Form.Control
                                type="text"
                                name="wellCapacity"
                                value={getPlacePropertyValue('wellCapacity')}
                                onChange={handleSetFieldForm}
                            // onBlur={handleOnBlur}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label>Maturity</Form.Label>
                            <Form.Control
                                type="text"
                                name="maturity"
                                value={getCropPropertyValue('maturity')}
                                onChange={handleSetCrop}
                                disabled={getCropPropertyValue('cropName') === ''}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label>Pivot Pass Amount</Form.Label>
                            <Form.Control
                                type="text"
                                name="pivotPassAmount"
                                value={getPlacePropertyValue('pivotPassAmount')}
                                onChange={handleSetFieldForm}
                            // onBlur={handleOnBlur}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label>Growth Stage</Form.Label>
                            <Form.Control
                                as="select"
                                name="currentGrowthStage"
                                value={getCropPropertyValue('currentGrowthStage')}
                                onChange={handleSetCrop}
                                disabled={(growthStages === null)}
                            >
                                <option value=""></option>
                                {growthStages !== null && growthStages.map((stage, index) =>
                                    <option value={stage.name} key={index}>{stage.name}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col />
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={() => { onHide(); handleResetCropForm(); }}>Cancel</Button>
                <Button variant="primary" onClick={handleSave}>Save</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FieldSettingsModal