import { gql } from '@apollo/client';

export const UPDATE_PLACE = gql`
    mutation UpdatePlace($guid: ID!, $place: InputPlace) {
        updatePlace(guid: $guid, place: $place) {
            ok
            message
        }
    }
`;

export const UPDATE_PLACE_LINK_CROP = gql`
    mutation UpdatePlaceLinkCrop($placeGuid: ID!, $link: InputLink, $cropGuid: ID!) {
        updatePlaceLinkCrop(placeGuid: $placeGuid, link: $link, cropGuid: $cropGuid) {
            ok
            results
            message
        }
    }
`;

export const ADD_PLACE_LINK_CROP = gql`
    mutation UpdatePlaceLinkCrop($placeGuid: ID!, $link: InputLink) {
        updatePlaceLinkCrop(placeGuid: $placeGuid, link: $link) {
            ok
            results
            message
        }
    }
`;

export const SHARE_FIELD = gql`
    mutation ShareField($place: ID!, $person: ID!) {
        shareField(place: $place, person: $person) {
            ok
            success
            message
        }
    }
`;

export const REMOVE_SHARED_FIELD = gql`
    mutation RemoveSharedField($place: ID!, $person: ID!) {
        removeSharedField(place: $place, person: $person) {
            ok
            success
            message
        }
    }
`;