import React from "react";

// UI
export { default as ArrowDown } from "./ui/arrow-down";
export { default as ArrowLeft } from "./ui/arrow-left";
export { default as ArrowRight } from "./ui/arrow-right";
export { default as ArrowUp } from "./ui/arrow-up";
export { default as Camera } from "./ui/camera";
export { default as Check } from "./ui/check";
export { default as ChevronDown } from "./ui/chevron-down";
export { default as ChevronLeft } from "./ui/chevron-left";
export { default as ChevronRight } from "./ui/chevron-right";
export { default as ChevronUp } from "./ui/chevron-up";
export { default as Close } from "./ui/x";
export { default as CloseCircle } from "./ui/x-circle";
export { default as Download } from "./ui/download";
export { ReactComponent as Gauge } from "./ui/gauge.svg";
export { default as Loading } from "./ui/loading";
export { default as Maximize } from "./ui/maximize";
export { default as Menu } from "./ui/menu";
export { default as Plus } from "./ui/plus";
export { default as Retake } from "./ui/retake";
export { default as Search } from "./ui/search";
export { default as Settings } from "./ui/settings";
export { default as Trash } from "./ui/trash";
// Vendor
export { default as Google } from "./vendor/google";
// Weather
export { ReactComponent as Barometer } from "./weather/barometer.svg";
export { ReactComponent as Humidity } from "./weather/humidity.svg";
export { ReactComponent as Precipitation } from "./weather/precipitation.svg";
export { ReactComponent as Soil } from "./weather/soil.svg";
export { ReactComponent as Sun } from "./weather/sun.svg";
export { ReactComponent as Temperature } from "./weather/temperature.svg";
export { ReactComponent as Uv } from "./weather/uv.svg";
export { ReactComponent as Wind } from "./weather/wind.svg";

export const Icon = ({ name, ...rest }) => {
    const ImportedIconRef = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const type = (rest.type && rest.type !== '' ) ? rest.type : 'ui';

    React.useEffect(() => {
        setLoading(true);
        const importIcon = async () => {
            try {
                ImportedIconRef.current = (await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!./${type}/${name.replace('.svg', '')}.svg`)).default;
                setLoading(false);
            } catch (err) {
                ImportedIconRef.current = (await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!./${type}/default.svg`)).default;
                setLoading(false);
            } finally {
                setLoading(false);
            }
        };
        importIcon();
    }, [name, type]);

    if (!loading && ImportedIconRef.current) {
        const { current: ImportedIcon } = ImportedIconRef;
        const fill = (rest.fill && rest.fill !== '' ) ? rest.fill : '#000';
        const radius = (rest.radius && rest.radius !== '' ) ? rest.radius : 804.672;
        return(
            ( rest.title && rest.title !== '' ) ? (
                <span 
                    className="icon-wrapper" 
                    style={{
                        display: 'inline-block', 
                        textAlign: 'center',
                    }}
                >
                    <em className="icon-svg" style={{ display: 'block'}}>
                        <ImportedIcon {...rest} fill={fill} radius={radius} />
                    </em>
                    <em className="icon-title">{rest.title}</em>
                </span>
            ) : (
                <ImportedIcon {...rest} fill={fill} />
            )
        );
    }

    return null;
};
