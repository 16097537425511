import React, { useState, useEffect, useRef, } from 'react';
import { useMutation } from '@apollo/client';
import GoogleMapReact from 'google-map-react'
import { Button, Modal } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';
import { Icon } from '../../common/icons';
import MarkerModal from './MarkerModal';
import { GMAPS_API_KEY } from '../../config';
import { ADD_ORDER, DELETE_ORDER, UPDATE_ORDER_LAT_LNG } from '../../GraphQL/Mutations/Orders';
import { getUserDetails } from '../../services/User';

const svg = `<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 75 100" style="enable-background:new 0 0 75 100;" height="64px" width="64px" xml:space="preserve">
<style type="text/css">
	.st00{fill:#0000FF;}
	.st11{fill:#FFFFFF;}
	.st22{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
</style>
<g id="PinOutside_33_">
	<path class="st00" d="M13.3,64.1c-5-6.8-11.8-16.1-11.8-26.8c0-19.7,16.1-35.7,36-35.7c19.9,0,36,16,36,35.7
		c0,9.9-5.8,18.8-11.2,26.3L37.6,97.5L13.3,64.1z"/>
	<path class="st11" d="M37.5,3C56.5,3,72,18.4,72,37.2c0,9.5-5.6,18.1-10.9,25.4L37.6,94.9L14.5,63.2C9.6,56.5,3,47.4,3,37.2
		C3,18.4,18.5,3,37.5,3 M37.5,0C16.8,0,0,16.7,0,37.2c0,10.9,6.5,20,12.1,27.7L37.6,100l25.9-35.5C68.5,57.5,75,48,75,37.2
		C75,16.7,58.2,0,37.5,0L37.5,0z"/>
</g>
<g>
	<path class="st22" d="M43.8,23.4c-5.6-1.3-11.1-0.9-16.5,1.2C24,26,19.5,29,18.5,30.6c1.6,1.6,3.1,3.1,4.7,4.7
		c0.2-0.2,0.5-0.4,0.7-0.6c4.9-4.5,11.9-6.3,18.4-4.7c3.4,0.8,6.4,2.4,9,4.8c0.2,0.2,0.4,0.3,0.5,0.5c0.6-0.5,4.4-4.3,4.7-4.8
		C52.9,26.9,48.7,24.5,43.8,23.4z"/>
	<path class="st22" d="M37,33.7c-4.3,0.2-9.1,2.4-10.9,4.9c1.6,1.5,3.2,3.1,4.7,4.6c0.1,0,0.1,0,0.2-0.1c0.2-0.2,0.4-0.3,0.6-0.5
		c1.7-1.4,3.7-2.2,5.9-2.2c2.3,0,4.4,0.7,6.1,2.2c0.2,0.1,0.3,0.3,0.5,0.4c0.2-0.2,0.3-0.3,0.4-0.4c1.4-1.4,2.8-2.8,4.2-4.2
		c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1C46,35.4,41.5,33.5,37,33.7z"/>
	<path class="st22" d="M37.5,44.9c-2.2,0-4,1.7-4,4c0,2.4,2,4,4,3.9c2.2,0,3.9-1.8,3.9-4C41.4,46.7,39.8,44.9,37.5,44.9z"/>
</g>
</svg>`;

const url = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(svg);
// const position = {
//     lat: 35.8032451,
//     lng: -95.992775
// }

const InfoWindow = (props) => {
    const { grower, field, marker } = props;

    const growerName = () => {
        if (grower.name) {
            return (
                <>{grower.name}</>
            )
        }
        
        return (
            <>{grower.firstName} {grower.lastName}</>
        )
    }

    return (
        <div className="marker-bubble" style={{ zIndex: 10000 }}>
            <div className="map-info-window-details">
                {/* <h6>{marker.title}</h6> */}
                <ul>
                    <li>
                        <label>Type: </label>
                        <span>{marker.summary}</span>
                    </li>
                    <li>
                        <label>Customer: </label>
                        <span>{growerName()}</span>
                    </li>
                    <li>
                        <label>Field: </label>
                        <span>{field.fieldName}</span>
                    </li>
                    <li>
                        <label>Contract #: </label>
                        <span>{field.contractNumber}</span>
                    </li>
                    <li>
                        <label>Order Date:</label>
                        <span>{field.dateOrdered}</span>
                    </li>
                    <li>
                        <label>GPS</label>
                        <span>{marker.lat}, {marker.lng}</span>
                    </li>
                </ul>
                <div className="button-row">
                    <Button variant="danger" onClick={props.onRemoveMarker}>Remove Pin</Button>
                    <Button variant="primary" onClick={props.onClose}>Done</Button>
                </div>
            </div>
        </div>
    );
}

const LocationPin = ({ marker, centerMap, ...props }) => {
    const pin = marker.pinFileName || 'default';

    return (
        <>
            {props.showInfo &&
                <InfoWindow
                    grower={props.grower}
                    field={props.field}
                    marker={props.selectedMarker}
                    onRemoveMarker={props.removeMarkerConfirm}
                    onClose={props.closeInfoWindow}
                    style={{ zIndex: 1000 }}
                />
            }
            <div className="map-marker" onClick={(e) => props.onClick(e, marker)}>
                <Icon
                    type="pins"
                    name={pin}
                    fill="#000"
                    width={32}
                />
            </div>
        </>
    );
}

const MapComponent = (props) => {
    const mapRef = useRef(null);
    const mapsRef = useRef(null);
    const { addToast } = useToasts();
    const userObject = getUserDetails();
    const {
        devices,
        existingGateways,
        field,
        fieldBoundaries,
        grower,
        handleAddPin,
        handleCancelPin,
        pendingMarker,
        position
    } = props;

    const { gateways } = field;

    const [currentOrders, setCurrentOrders] = useState([]);
    const [mapDevices, setMapDevices] = useState(null);
    const [mapPendingMarker, setPendingMarker] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [infoWindowOpen, setInfoWindowOpen] = useState(null);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [mapCircles, setMapCircles] = useState(null);
    const [polygons, setPolygons] = useState(null);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [mapDraggable, setMapDraggable] = useState(true);

    const [deleteOrder] = useMutation(DELETE_ORDER);
    const [addOrder, { loading: loadingSaveOrder, called: calledSaveOrder }] = useMutation(ADD_ORDER);
    const [updateOrderLatLng, { loading: loadingUpdatedOrderLatLng, called: calledUpdatedOrderLatLng }] = useMutation(UPDATE_ORDER_LAT_LNG);

    useEffect(() => {
        if (devices && devices !== null) {
            setMapDevices([...devices]);
        }
    }, [devices]);

    useEffect(() => {
        setInfoWindowOpen(null);
        setPendingMarker(pendingMarker);
    }, [pendingMarker]);

    /* **
    *
    * Marker Related Methods
    *
    */
    const onMarkerClick = (event, marker, index) => {
        event.stopPropagation();

        if (infoWindowOpen !== null && index === infoWindowOpen) {
            setInfoWindowOpen(null);
            setSelectedMarker(null);
        } else {
            setInfoWindowOpen(index);
            setSelectedMarker(marker);
        }
    }

    const onMarkerSave = async (form) => {
        let orderGuid = undefined;
        let gatewayName = '';
        const filteredMarker = mapDevices.filter((marker) => {
            return (marker.lat === mapPendingMarker.lat && marker.lng === mapPendingMarker.lng);
        });

        const updatedMarkerDetails = filteredMarker.map((marker) => {
            if (marker.lat === mapPendingMarker.lat && marker.lng === mapPendingMarker.lng) {
                if (marker.deviceNameNeeded === null || marker.deviceNameNeeded === '') {
                    marker.deviceNameNeeded = false;
                }

                if (form.gatewayNeeded === null || form.gatewayNeeded === '') {
                    marker.gatewayNeeded = false;
                    form.gatewayNeeded = false;
                }

                marker.name = '';
                form.name = '';

                if (form.gatewayName && form.gatewayName !== '') {
                    gatewayName = form.gatewayName;
                    form.gatewayNeeded = true;
                    marker.gatewayNeeded = true;
                }

                delete marker['__typename'];
                delete form['gatewayName'];

                const combined = {
                    ...marker,
                    ...form
                };

                return combined;
            }

            return false;
        });

        if (updatedMarkerDetails.length > 0) {
            const growerData = { ...grower };
            delete growerData['__typename'];

            var lineItems = [...updatedMarkerDetails];

            lineItems.forEach((li) => {
                li.itemGuid = li.guid;
                delete li.guid;
            });

            const response = await addOrder({
                variables: {
                    order: {
                        agronomistGuid: userObject.guid,
                        field: {
                            guid: field.guid,
                            fieldName: field.fieldName
                        },
                        gatewayNeeded: gatewayName,
                        grower: growerData,
                        growerGuid: grower.guid,
                        lineItems,
                        salesPersonGuid: userObject.guid,
                    }
                },
                context: {
                    service: 'orders'
                }
            });

            const { data: { addOrder: { ok, results } } } = response;

            if (ok && ok === true) {
                if (results.length > 0) {
                    const result = results[0];
                    orderGuid = result.guid;
                    setCurrentOrders([...currentOrders, orderGuid]);

                    // console.log("SETTING PENDING MARKER", mapPendingMarker);
                    // setPendingMarker({ ...mapPendingMarker, orderGuid: orderGuid });
                    setPendingMarker(null);
                }

                addToast('Order Has Been Saved', {
                    appearance: 'success',
                    autoDismiss: true,
                });

            } else {
                addToast('There was a problem submitting the order', {
                    appearance: 'error',
                });
            }
        }

        const updatedMarkers = mapDevices.map((marker) => {
            if (marker.lat === mapPendingMarker.lat && marker.lng === mapPendingMarker.lng) {
                const newObj = {
                    ...marker,
                    ...form
                }

                if (orderGuid) {
                    newObj['orderGuid'] = orderGuid;
                }
                return newObj;
            } else {
                return marker;
            }
        });

        setMapDevices([...updatedMarkers]);

        handleAddPin(mapPendingMarker);
        setModalShow(!modalShow);
    }
    const onMarkerMoveSave = async (form) => {
        const filteredMarker = mapDevices.filter((marker) => {
            return (marker.latLngChanged);
        });

        if (filteredMarker.length > 0) {
            const response = await updateOrderLatLng({
                variables: {
                    lineItemGuid: filteredMarker[0].guid,
                    orderGuid: filteredMarker[0].orderGuid,
                    lat: filteredMarker[0].lat,
                    lng: filteredMarker[0].lng,
                },
                context: {
                    service: 'orders'
                }
            });

            const { data: { updateLineItemLatLng: { ok } } } = response;

            if (ok && ok === true) {
                addToast('Order Has Been Saved', {
                    appearance: 'success',
                    autoDismiss: true,
                });

            } else {
                addToast('There was a problem updating the order', {
                    appearance: 'error',
                });
            }
        }

        setMapDevices([...mapDevices]);
    }

    const onMarkerCancel = (form) => {
        handleCancelPin(mapPendingMarker);

        const newDevices = mapDevices.filter((marker) => {
            return (marker.lat !== mapPendingMarker.lat && marker.lng !== mapPendingMarker.lng);
        });

        setMapDevices([...newDevices]);
        handleAddPin(null);
        setPendingMarker(null);
        setModalShow(false);
    }

    const removeMarkerConfirm = (event) => {
        event.stopPropagation();
        setShowRemoveModal(true);
    }

    const handleCloseRemoveModal = () => {
        setShowRemoveModal(false);
    }

    const removeMarker = async () => {
        const list = mapDevices;
        const device = mapDevices[infoWindowOpen];
        let index = undefined;

        console.log("LIST", list, "DEVICE", device);

        for (let i = 0; i < list.length; i++) {
            if (device.lat === list[i].lat && device.lng === list[i].lng) {
                index = i;
            }
        }

        const response = await deleteOrder({
            variables: {
                guid: list[index].orderGuid
            },
            context: {
                service: 'orders'
            }
        });

        if (response) {

            if (typeof index !== 'undefined') {
                list.splice(index, 1);
            }

            setInfoWindowOpen(null);
            setMapDevices([...list]);
            setShowRemoveModal(false);

            addToast('Order Has Been Deleted Successfully', {
                appearance: 'success',
                autoDismiss: true,
            });
        }
    }

    const showInfoWindow = (index) => {
        return (infoWindowOpen !== null && infoWindowOpen === index);
    }

    const closeInfoWindow = (event) => {
        event.stopPropagation();
        setInfoWindowOpen(null);
        setSelectedMarker(null);
        setMapDraggable(true);
    }
    /*
    *
    * // Marker Related Methods
    *
    ** */


    /* **
    *
    * Map Related Methods
    *
    */
    const onMapClick = (mapProps) => {
        if (mapPendingMarker !== null) {
            console.log("MAP PROPS", mapProps);
            const lat = mapProps.lat;
            const lng = mapProps.lng;
            const newMarker = {
                ...mapPendingMarker,
                lat,
                lng,
            };

            let devices = mapDevices;

            if (devices !== null && devices.length) {
                devices.push(newMarker);
            } else {
                devices = [newMarker];
            }

            setMapDevices([...devices]);
            console.log("SETTING PENDING MARKER", mapPendingMarker);
            setPendingMarker({ ...mapPendingMarker, ...{ lat, lng } });
            setModalShow(!modalShow);
        } else {
            console.log("FIELD", field);
        }
    }

    const addPolygons = () => {
        const polygonsToAdd = fieldBoundaries.map((boundary) => {
            const featureArray = [];
            if (boundary.length > 0) {
                boundary.forEach((feature) => {
                    feature.geometry.coordinates.map(c => {
                        const pathArray = c.map(x => ({ lat: x[1], lng: x[0] }));
                        featureArray.push(pathArray);
                        return c;
                    });
                });
                const poly = new mapsRef.current.Polygon({
                    paths: featureArray,
                    strokeColor: "red",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "white",
                    fillOpacity: 0,
                });
                poly.setMap(mapRef.current);
                return poly;
            }
            return boundary;
        });

        // console.log("POLYGONS", polygonsToAdd);
        setPolygons(polygonsToAdd);
    }

    const addGateways = (map, maps) => {
        const circles = [];
        if (gateways && gateways.length) {
            for (let i = 0; i < gateways.length; i++) {
                const gateway = gateways[i];
                if ((gateway.lat !== undefined && gateway.lng !== undefined && gateway.lat !== null && gateway.lng !== null)
                    && (!isNaN(gateway.lng) && !isNaN(gateway.lat))) {
                    circles[i] = new maps.Circle({
                        strokeColor: '#FFF',
                        strokeOpacity: 0.8,
                        strokeWeight: 1,
                        fillColor: '#FFF',
                        fillOpacity: 0.2,
                        map,
                        center: {
                            lat: gateway.lat,
                            lng: gateway.lng
                        },
                        radius: 804.672,
                    });
                    circles[i].setMap(mapRef.current);
                    const marker = new maps.Marker({
                        position: {
                            lat: gateway.lat,
                            lng: gateway.lng
                        },
                        map,
                        icon: {
                            url,
                            scaledSize: new maps.Size(32, 32),
                        }
                    });
                    // marker.setIcon(svg);
                    marker.setMap(mapRef.current);
                }
            }
        }

        if (existingGateways && existingGateways.length) {
            for (let i = 0; i < existingGateways.length; i++) {
                const gateway = existingGateways[i];
                if (gateway && gateway.geometry && gateway.geometry.coordinates) {
                    circles[i] = new maps.Circle({
                        strokeColor: '#FFF',
                        strokeOpacity: 0.8,
                        strokeWeight: 1,
                        fillColor: '#FFF',
                        fillOpacity: 0.2,
                        map,
                        center: {
                            lat: gateway.geometry.coordinates[1],
                            lng: gateway.geometry.coordinates[0]
                        },
                        radius: 804.672,
                    });

                    const marker = new maps.Marker({
                        position: {
                            lat: gateway.geometry.coordinates[1],
                            lng: gateway.geometry.coordinates[0]
                        },
                        map,
                        icon: {
                            url,
                            scaledSize: new maps.Size(32, 32),
                        }
                    });
                    // marker.setIcon(svg);
                    marker.setMap(mapRef.current);
                }
            }
        }

        setMapCircles([...circles]);
    }

    // Fit map to its bounds after the api is loaded
    const apiIsLoaded = (map, maps) => {
        mapRef.current = map;
        mapsRef.current = maps;


        if (gateways || existingGateways) {
            addGateways(map, maps);
        }


        if (fieldBoundaries && fieldBoundaries !== null) {
            addPolygons();
        }

    }
    /*
    *
    * // Map Related Methods
    *
    ** */

    const onMapMouseDown = (key, marker, newCoords) => {
        // console.log(key, marker, newCoords);
        setMapDraggable(false);
    }

    const onMapMouseUp = (key, marker, newCoords) => {
        const list = mapDevices;
        if (selectedMarker && list[key].guid === selectedMarker.guid) {
            list[key].lat = newCoords.lat;
            list[key].lng = newCoords.lng;
            list[key].latLngChanged = true;
            // how do I save new lat/lng back to order.lineItem
            onMarkerMoveSave([...list]);
            setMapDevices([...list]);
        }
    }

    const onMapMouseMove = (key, marker, newCoords) => {
        const list = mapDevices;
        // console.log("MARKER", marker);
        if (selectedMarker && list[key].guid === selectedMarker.guid) {
            list[key].lat = newCoords.lat;
            list[key].lng = newCoords.lng;
            setMapDevices([...list]);
        }
    }

    const onMapTypeIdChange = (mapTypeId) => {
        const type = mapTypeId.toString().toUpperCase();

        if (type === 'HYBRID' || type === 'SATELLITE') {
            if (mapCircles && mapCircles !== null) {
                for (let i = 0; i < mapCircles.length; i++) {
                    mapCircles[i].setOptions({
                        strokeColor: '#FFF',
                        strokeOpacity: 0.5,
                        fillColor: '#FFF',
                        fillOpacity: 0.1,
                    });
                }
            }

            polygons.forEach((polygon) => {
                polygon.setOptions({
                    fillColor: 'white',
                });
            });


        } else {
            if (mapCircles && mapCircles !== null) {
                for (let i = 0; i < mapCircles.length; i++) {
                    mapCircles[i].setOptions({
                        strokeColor: '#000',
                        strokeOpacity: 0.5,
                        fillColor: '#000',
                        fillOpacity: 0.6,
                    });
                }
            }

            polygons.forEach((polygon) => {
                polygon.setOptions({
                    fillColor: 'black'
                });
            });
        }
    }

    useEffect(() => {
        if (pendingMarker !== null) {
            console.log("MARKER CHANGING");
            console.log(pendingMarker);
        }
    }, [pendingMarker])
    return (
        <div className="map-wrapper">
            {/* { mapPosition && mapPosition !== null && */}
            <GoogleMapReact
                bootstrapURLKeys={{ key: GMAPS_API_KEY }}
                center={position}
                defaultZoom={16}
                draggable={mapDraggable}
                onClick={onMapClick}
                ref={mapRef}
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
                options={{
                    mapTypeControl: true,
                    mapTypeId: 'hybrid'
                }}
                onChildMouseDown={onMapMouseDown}
                onChildMouseUp={onMapMouseUp}
                onChildMouseMove={onMapMouseMove}
                onMapTypeIdChange={onMapTypeIdChange}
            >
                {mapDevices && mapDevices.map((marker, index) =>
                    <LocationPin
                        key={index}
                        marker={marker}
                        grower={grower}
                        field={field}
                        lat={marker.lat}
                        lng={marker.lng}
                        onClick={(e) => onMarkerClick(e, marker, index)}
                        showInfo={showInfoWindow(index)}
                        removeMarkerConfirm={removeMarkerConfirm}
                        selectedMarker={selectedMarker}
                        closeInfoWindow={closeInfoWindow}
                    />
                )}
            </GoogleMapReact>
            {/* } */}
            <MarkerModal
                show={modalShow}
                onHide={() => setModalShow(!modalShow)}
                onSave={onMarkerSave}
                onCancel={onMarkerCancel}
                pendingMarker={pendingMarker}
                loadingSaveOrder={loadingSaveOrder}
                calledSaveOrder={calledSaveOrder}
                loadingUpdatedOrderLatLng={loadingUpdatedOrderLatLng}
                calledUpdatedOrderLatLng={calledUpdatedOrderLatLng}
            />
            <Modal
                show={showRemoveModal}
                onHide={handleCloseRemoveModal}
            >
                <Modal.Header>
                    <Modal.Title>Remove Pin From Map?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to remove this pin from the map?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => setShowRemoveModal(false)}>Cancel</Button>
                    <Button variant="danger" onClick={removeMarker}>Yes, Remove the Pin</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default MapComponent;