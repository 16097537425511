import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { Check, XOctagon } from 'react-feather';
import DevicesComponent from '../../routes/Devices';
import OrdersComponent from '../../routes/Orders';
import WeatherComponent from '../../routes/Weather';
import SoilMoisture from '../../routes/SoilMoisture';
import Dashboard from '../../routes/Dashboard';
import People from '../../routes/People';
import LoginComponent from '../../routes/Login';
import Logout from '../../routes/Logout';
import Spinner from '../../common/Spinner';
import { isLoggedIn, setLastLocation, } from '../../services/User';

const ToastSnack = (props) => {
    const snackStates = {
      entering: { transform: 'translate3d(0, 120%, 0) scale(0.9)' },
      entered: { transform: 'translate3d(0, 0, 0) scale(1)' },
      exiting: { transform: 'translate3d(0, 120%, 0) scale(0.9)' },
      exited: { transform: 'translate3d(0, 120%, 0) scale(0.9)' },
    };
    return(
        <div className={"toast-wrapper " + props.appearance} style={{
            ...snackStates[props.transitionState]
        }}>
            <div className="checkmark-wrapper">
                {props.appearance === 'success' ? (
                    <Check />
                ) : (
                    <XOctagon />
                )}
            </div>
            <div className="children">{props.children}</div>
            <div className="close-toast" onClick={props.onDismiss}>X</div>
        </div>
    )
}

const AppWrapper = () => {
    const loading = false;
    const error = false;
    
    const location = useLocation();
    const { pathname } = location;

    const pathNameIsNotLoginLogout = () => {
        return !pathname.includes('login') && !pathname.includes('logout')
    }
    
    if ( !pathname.includes('login') && !pathname.includes('logout') ) {
        setLastLocation(pathname);
    }

    useEffect(() => {
        const y = window.scrollY;
        if ( y > 0 ) {
            window.setTimeout(() => {
                window.scrollTo(0, 0);
            }, 200);
        }
    });

    const handleErrorOutput = () => {
        return ( typeof error === 'string' ) ? error : error.toString();
    }

    if ( !isLoggedIn() && pathNameIsNotLoginLogout() ) {
        return <Redirect to="/login" />
    }

    if ( loading ) { 
        return(
            <div className="content-wrapper">
                <Spinner>...Loading</Spinner>
            </div>
        ); 
    }

    if ( error ) { return(<div>Error { handleErrorOutput() }</div>); }
    
    return(
        <ToastProvider placement="bottom-center" components={{ Toast: ToastSnack }} autoDismissTimeout={6000}>
            <Switch>
                    <Route path="/soil-moisture" exact component={SoilMoisture} />
                    <Route path="/people" exact component={People} />
                    {/* <Route path="/imagery" exact component={Imagery} /> */}
                    <Route path="/devices" exact component={DevicesComponent} />
                    {/* <Route path="/files" exact component={FilesComponent} /> */}
                    {/* <Route path="/maps" exact component={MapsComponent} /> */}
                    <Route path="/logout" exact component={Logout} />/
                    <Route path="/orders" exact component={OrdersComponent} />
                    {/* <Route path="/recommendations" exact component={RecommendationsComponent} /> */}
                    {/* <Route path="/reports" exact component={ReportsComponent} /> */}
                    {/* <Route path="/dashboard" exact component={Dashboard} />                     */}
                    <Route path="/weather" exact component={WeatherComponent} />
                    <Route path="/login" exact component={LoginComponent} />
                    <Route path="/" exact component={SoilMoisture} />
            </Switch>
        </ToastProvider>
    );
}

export default AppWrapper;
