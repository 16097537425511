import React, { useEffect, useState } from 'react';
import { Accordion, Card, } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import AccordionHeader from '../../components/AccordionHeader';
// import FieldCard from './FieldCard';
import GrowerFields from './GrowerFields';
import Spinner from '../../common/Spinner';
import { properCase, sortCollection } from '../../services/Data';
import { GET_AGRONOMIST_GROWERS } from '../../GraphQL/Queries/User';

const Growers = (props) => {
    const {
        agronomist,
        guid,
        growerOpen,
        handleGrowerOpen,
    } = props;

    const [growersList, setGrowersList] = useState(null);
    const [fieldOpen, setFieldOpen] = useState(null);

    const {
        loading: gettingGrowers,
        error: growersError,
        data: growerResults
    } = useQuery(
        GET_AGRONOMIST_GROWERS,
        {
            context: { service: 'people' },
            variables: { guid: guid || agronomist.guid }
        }
    );

    const growerHeaderClicked = (grower) => {
        if (grower !== growerOpen) {
            handleGrowerOpen(grower);
            setFieldOpen(null);
        } else {
            handleGrowerOpen(null);
            setFieldOpen(null);
        }
    }

    const handleFieldOpen = (field) => {
        if (field !== fieldOpen) {
            setFieldOpen(field);
        } else {
            setFieldOpen(null);
        }
    }

    const hasGrowersList = () => {
        return (growersList && growersList !== null && growersList.hasOwnProperty('length') && growersList.length > 0);
    }

    useEffect(() => {
        if (!gettingGrowers && !growersError) {
            const { getAgronomistGrowers } = growerResults;
            const { results } = getAgronomistGrowers;
            const sorted = sortCollection(results, { 'name': 'asc' });

            setGrowersList(sorted);
        }
    }, [growerResults, gettingGrowers, growersError]);

    return (
        gettingGrowers ? (
            <Spinner align="left">Getting Growers List</Spinner>
        ) : (
            hasGrowersList() ? (
                growersList.map((grower, index) =>
                    <Card key={index}>
                        <AccordionHeader
                            eventKey={grower.guid}
                            onClick={() => growerHeaderClicked(grower.guid)}
                            isOpen={growerOpen}
                        >
                            {properCase(grower.name)}
                        </AccordionHeader>
                        <Accordion.Collapse eventKey={grower.guid} mountOnEnter unmountOnExit>
                            <Card.Body>
                                <Accordion>
                                    <GrowerFields
                                        fieldOpen={fieldOpen}
                                        grower={grower}
                                        handleFieldOpen={handleFieldOpen}
                                    />
                                </Accordion>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                )
            ) : (
                <div>No Growers Found</div>
            )
        )
    );
}

export default Growers;