import { gql } from '@apollo/client';

export const GET_THINGS_BY_PLACE = gql`
    query GetThingsByPlace($guid: ID!) {
        getThingsByPlace(guid: $guid) {
            ok
            results {
                deviceName
                deveui
                deviceNotes
                fieldAccessNotes
                gatewayNeeded
                guid
                links{
                    fullSF
                    guid
                    lat
                    lng
                    pinFileName
                    startDate
                    state
                    technician
                    wiltSF
                    probesToAverage
                    deviceName
                }
                title
                type
                tribusProbeType
                sensorSerial
                depthMeasurement
            }
        }
    }
`;

export const GET_FIELD_SENSOR_PREVIEW = gql`
    query GetFieldSensorPreview($placeGuid: ID!) {
        getFieldSensorPreview(placeGuid: $placeGuid) {
            ok
            results {
                rain24h
                stage
                crop
                et
                devices{
                    deviceGuid
                    svgGauge,
                    gaugeValue
                    probesToAverage
                    state
                }
            }
        }
    }
`;

export const GET_FIELD_SENSOR_PREVIEWS = gql`
    query GetFieldSensorPreviews( $placeGuids: [ID!]) {
        getFieldSensorPreviews( placeGuids: $placeGuids) {
            ok
            results{
                rain24h
                stage
                et
                fieldGuid
                devices{
                    deviceGuid
                    svgGauge,
                    gaugeValue
                    probesToAverage
                    state
                }
            }
        }
    }
`;

export const GET_DEVICE_MOISTURE_GAUGE = gql`
    query GetDeviceMoistureGauge( $deviceGuid: [ID!]) {
        getDeviceMoistureGauge( deviceGuid: $deviceGuid) {
            ok
            results{
                deviceGuid
                deveui
                type
                svgGauge
                probesToAverage
                wiltSF
                fullSF
                gaugeValue
                pivot
            }
        }
    }
`;

export const GET_SOIL_TEXTURE_LIST = gql`
    query {
        getSoilTextureList {
            ok
            results
        }
    }
`;

export const GET_ALL_THINGS = gql`
    query GetAllThings($searchString: String, $stateFilter: [String], $deviceTypeFilter:[String], $technicianFilter:[String]){
        getAllThings(searchString: $searchString, stateFilter: $stateFilter, deviceTypeFilter: $deviceTypeFilter, technicianFilter: $technicianFilter) {
            results{
                guid
                deveui
                deviceName
                title
                type
                fieldAccessNotes
                deviceNotes
                links{
                    fullSF
                    guid
                    lat
                    lng
                    pinFileName
                    startDate
                    state
                    technician
                    wiltSF
                }
            }
        }
    }
`;


export const GET_DATA_BY_KEY_AND_TIME_FRAME = gql`
    query getDataByKeyAndTimeFrame($key: String, $start: Float, $end: Float){
        getDataByKeyAndTimeFrame(key: $key, start: $start, sne: $end){
            ok
            message
            results  
        }
    }
`;

export const GET_LAST_MESSAGE = gql`
    query GetLastMessage($key: String){
        getLastMessage(key: $key) {
            ok
            message
            results            
        }
    }
`;

export const GET_GPS_DATA = gql`
    query GetGpsData($deveui: Int, $start: Float, $end: Float){
        getGpsData(deveui: $deveui, start: $start, end: $end){
        ok
            results{
                snr
                rssi
                lat
                lng
                epoch
                dateTime
            }
        }
    }
`;

export const GET_STATUS_DATA = gql`
    query GetStatusData($deveui: Int, $start: Float, $end: Float){
        getStatusData(deveui: $deveui, start: $start, end: $end){
        ok
            results{
                snr
                rssi
                epoch
                dateTime
                intern_temp_c10
                frmver
                charge_v1000
                sensor_status  
                vcc_v1000
            }
        }
    }
`;

export const GET_THINGS_FOR_MAP = gql`
    query GetThingsForMap($searchString: String, $stateFilter: [String], $deviceTypeFilter:[String], $technicianFilter:[String]){
        getThingsForMap(searchString: $searchString, stateFilter: $stateFilter, deviceTypeFilter: $deviceTypeFilter, technicianFilter: $technicianFilter) {
            ok
            results            
        }
    }
`;

export const GET_DEVICE_MOISTURE_CHART = gql`
    query GetDeviceMoistureChart( $deviceGuid: ID!, $start: Float, $end: Float ) {
        getDeviceMoistureChart( deviceGuid: $deviceGuid, start: $start, end: $end ) {
            ok
            results
        }
    }
`;

export const GET_DEVICE_RAIN_CHART = gql`
    query GetDeviceRainChart( $placeGuid: ID! ) {
        getDeviceRainChart( placeGuid: $placeGuid ) {
            ok
            results
        }
    }
`;

export const GET_PIVOT_CHART = gql`
    query getPivotChart( $deviceGuid: ID!) {
        getPivotChart( deviceGuid: $deviceGuid) {
            ok
            results{
                highChartData
                status{
                    currentBearing
                    currentCompass
                    currentStatus
                    previousBearing
                    previousCompass
                    previousStatus
                }
                pivotTotals{
                    epoch
                    event
                    dateTime
                    hoursOff
                    hoursForward
                    hoursReverse
                }
            }
        }
    }
`;

export const GET_DEVICE_MOISTURE_AVERAGE_CHART = gql`
    query GetDeviceMoistureChartAverage(
            $deviceGuid: ID!, 
            $start: Float, 
            $end: Float, 
            $probesToAverage: [Int]!
        ) {
            getDeviceMoistureChartAverage(
                deviceGuid: $deviceGuid, 
                start: $start, 
                end: $end, 
                probesToAverage: $probesToAverage
            ) {   
                ok
                results
            }
    }
`;

export const GET_PLACE_LINK_THING = gql`
    query GetPlaceLinkThing($placeGuid: ID!, $linkGuid: ID!, $thingGuid: ID!) {
        getPlaceLinkThing(placeGuid: $placeGuid, linkGuid: $linkGuid, thingGuid: $thingGuid) {
            ok
            results {
                thing {
                    deviceName
                    deviceNotes
                    fieldAccessNotes
                    gatewayNeeded
                    guid
                    title
                    type
                }
                link {
                    avgFullSF
                    avgWiltSF
                    fullSF
                    guid
                    lat
                    lng
                    pinFileName
                    startDate
                    state
                    technician
                    wiltSF
                    oneFtSoilTexture
                    threeFtSoilTexture
                    twoFtSoilTexture
                    complete
                }
                place {
                    acres
                    boundary
                    contractNumber
                    endDate
                    fieldName
                    guid
                    irrigationType
                    lat
                    lng
                    orderDate
                    wellCapacity
                    pivotPassAmount
                    acreInches
                    startDate
                    link {
                        guid
                        plantingDate
                        currentGrowthStage
                        maturity
                        crop{
                            guid
                            name
                        }
                    }
                }
            }
        }
    }
`;

export const GET_RECOMMENDATIONS_BY_PLACE = gql`
    query GetRecommendationsByPlace($guid: ID!) {
        getRecommendationsByPlace(guid: $guid) {
            ok
            results{
                guid
                recommendation
                dateCreated
                dateUpdated
                authorGuid
                authorName
            }
        }
    }
`;

export const GET_NOTES_BY_PLACE = gql`
    query GetNotesByPlace($guid: ID!) {
        getNotesByPlace(guid: $guid) {
            results{
                guid
                message
                dateCreated
                dateUpdated
                authorGuid
                authorName
            }
        }
    }
`;

export const GET_PLANT_AVAILABLE_WATER_CHART = gql`
    query GetPlantAvailableWaterChart($deviceGuid: ID!) {
        getPlantAvailableWaterChart(deviceGuid: $deviceGuid) {
            ok
            results{
                depth
                waterDepleted
                paw
                raw
                soilTexture
                sensorReading
            }
        }
    }
`;


export const GET_FIRMWARE_DATA = gql`
    query getFirmWareData($deveui: Int, $start: Float, $end: Float) {
        getFirmWareData(deveui: $deveui, start: $start, end: $end) {
            ok
            results{
                epoch
                dateTime
                snr
                rssi
                hw_pn
                fw_ver_eng
                fw_ver_rev
                fw_ver_major
                fw_ver_minor
                fw_build_type
            }
        }
    }
`;


export const GET_GATEWAY_DATA = gql`
    query getGatewayData($deveui: Int, $start: Float, $end: Float) {
        getGatewayData(deveui: $deveui, start: $start, end: $end) {
            ok
            results{
                epoch
                dateTime
                snr
                rssi
                intern_temp_c10
                frmver
                charge_v1000
                sensor_status  
            }
        }
    }
`;

export const GET_MOISTURE_DATA = gql`
    query getMoistureData($deveui: Int, $start: Float, $end: Float) {
        getMoistureData(deveui: $deveui, start: $start, end: $end) {
            ok
            results{
                epoch
                dateTime
                snr
                rssi
                moisture0
                moisture1
                moisture2
                moisture3  
                moisture4  
                moisture5  
            }
        }
    }
`;

export const GET_RAIN_DATA = gql`
    query getRainData($deveui: Int, $start: Float, $end: Float) {
        getRainData(deveui: $deveui, start: $start, end: $end) {
            ok
            results{
                epoch
                dateTime
                snr
                rssi
                totalRainfallCount 
            }
        }
    }
`;

export const GET_PIVOT_DATA = gql`
    query getPivotStatusData($deveui: Int, $start: Float, $end: Float) {
        getPivotStatusData(deveui: $deveui, start: $start, end: $end) {
            ok
            results{
                epoch
                dateTime
                snr
                rssi
                status 
            }
        }
    }
`;

export const GET_BROKEN_DEVICE = gql`
    query getBrokenDevice($deveui: Int!){
        getBrokenDevice(deveui: $deveui){
            ok
        }
    }
`;

export const GET_ET_SUMMARY = gql`
    query GetEtSummary($placeGuid: ID!) {
        getEtSummary(placeGuid: $placeGuid) {
            ok
            results {
                lastThree
                yesterday
                today
                tomorrow
                nextThree
                nextWeek
            }
        }
    }
`;

export const GET_ALL_CURRENT_GPS = gql`
    query GetAllCurrentGps{
        getAllCurrentGps{
            ok
            results
        }
    }
`;
export const GET_ALL_GPS_HISTORY = gql`
    query GetAllGpsHistory{
        getAllGpsHistory{
            ok
            results
        }
    }
`;

export const GET_DEVICE_GPS_HISTORY = gql`
    query GetDeviceGpsHistory ($deveui: Int){
        getDeviceGpsHistory(deveui: $deveui){
            ok
            results
        }
    }
`;