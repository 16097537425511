import React from 'react';
import './spinner.scss';

const Spinner = (props) => {
    return(
        <div 
            className={props.align ? 'spinner-wrapper ' + props.align : 'spinner-wrapper' }
        >
            <div className={(props.color) ? "lds-spinner " + props.color : "lds-spinner"}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className="spinner-children">{props.children}</div>
        </div>
    );
}

export default Spinner;