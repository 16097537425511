import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Row, } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import { GET_PLACE_BY_GUID, GET_PLACES_BY_GROWER } from '../../GraphQL/Queries/Places';
import { GET_ORDER_BY_GROWER } from '../../GraphQL/Queries/Orders';
import { GET_THINGS_FOR_MAP } from '../../GraphQL/Queries/Things';
import OrderItems from './OrderItems';
import MapComponent from './ReactGoogleMaps';

const FieldCard = (props) => {
    const { field, grower } = props;
    const mapRef = useRef();
    const [resetZoom, setResetZoom] = useState(false);
    const [pendingMarker, setPendingMarker] = useState(null);
    const [fieldDetails, setFieldDetails] = useState(null);
    const [fieldDevices, setFieldDevices] = useState(null);
    const [orderItems, setOrderItems] = useState(null);
    const [existingGateways, setExistingGateways] = useState(null);
    const [existingThings, setExistingThings] = useState(null);
    const [localGateways, setLocalGateways] = useState(null);
    const [localThings, setLocalThings] = useState(null);
    const [fieldBoundaries, setFieldBoundaries] = useState(null);
    const [currentBoundary, setCurrentBoundary] = useState(null);
    const [position, setPosition] = useState({ lat: 35.8032451, lng: -95.992775 });
    const { loading: gettingPlace, error: errorGettingPlace, data: placeData } = useQuery(
        GET_PLACE_BY_GUID,
        {
            variables: {
                guid: field.guid,
            },
            context: {
                service: 'places'
            }
        }
    );

    const { loading: gettingBoundaries, error: errorGettingBoundaries, data: fieldBoundaryData } = useQuery(
        GET_PLACES_BY_GROWER,
        {
            variables: {
                guid: grower.guid,
            },
            context: {
                service: 'places'
            }
        }
    );

    const { loading: gettingOrders, error: errorGettingOrders, data: ordersData } = useQuery(
        GET_ORDER_BY_GROWER,
        {
            variables: {
                guid: grower.guid,
            },
            context: {
                service: 'orders'
            }
        }
    );
    const { loading: gettingGateways, error: errorGettingGateways, data: thingsForMap } = useQuery(
        GET_THINGS_FOR_MAP,
        {
            // variables: { "deviceTypeFilter": ["Gateway"] },
            fetchPolicy: "network-only",
            context: { service: 'things' }
        }
    );

    const handlePinClick = (item) => {
        setPendingMarker(item);
        mapRef.current.classList.add('opened');
        mapRef.current.classList.add('covered');

        window.setTimeout(() => {
            setResetZoom(true);
        }, 600);

        window.setTimeout(() => {
            mapRef.current.classList.remove('covered');
        }, 700);
    }

    const handleCloseMap = () => {
        mapRef.current.classList.remove('opened');
        setPendingMarker(null);
        setResetZoom(false);
    }

    const handleAddPin = (markers) => {
        setPendingMarker(null);
    }

    const handleCancelPin = (pin) => {
        setPendingMarker(null);
        setResetZoom(false);
        mapRef.current.classList.remove('opened');
        mapRef.current.classList.remove('covered');
    }

    const getCenter = (arr) => {
        var x = arr.map(function (a) { return a[0] });
        var y = arr.map(function (a) { return a[1] });
        var minX = Math.min.apply(null, x);
        var maxX = Math.max.apply(null, x);
        var minY = Math.min.apply(null, y);
        var maxY = Math.max.apply(null, y);
        return [(minX + maxX) / 2, (minY + maxY) / 2];
    };

    useEffect(() => {
        if (!gettingPlace && !errorGettingPlace) {
            const { getPlaceByGuid } = placeData;
            const { results } = getPlaceByGuid;
            if (results && results.length > 0) {
                const result = results[0];
                const finalBoundaries = [];
                setFieldDetails(f => ({ ...f, ...result }));
                let center = getCenter(result.boundary.features[0].geometry.coordinates[0]);
                setPosition({ lat: center[1], lng: center[0] });
                for (let i = 0; i < results.length; i++) {
                    const { boundary: { features } } = results[i];
                    if (features && features.length > 0) {
                        const boundariesArr = [];
                        features.map((feature) => {
                            const { geometry } = feature;
                            const { coordinates } = geometry;
                            if (geometry.type === "Polygon") {
                                const coords = coordinates[0];
                                for (let i = 0; i < coords.length; i++) {
                                    const obj = { lat: coords[i][1], lng: coords[i][0] };

                                    boundariesArr.push(obj);

                                }
                            } else {
                                console.log("NOT A POYLGON", feature);
                            }
                            return feature;
                        });
                        finalBoundaries.push(boundariesArr);
                    }
                }
                setCurrentBoundary(finalBoundaries);
            }
        }
    }, [gettingPlace, errorGettingPlace, placeData]);

    useEffect(() => {
        if (!gettingBoundaries && !errorGettingBoundaries) {
            const { getPlacesByGrower } = fieldBoundaryData;
            const { results } = getPlacesByGrower;

            if (results && results.length > 0) {
                const finalBoundaries = [];
                for (let i = 0; i < results.length; i++) {
                    const { boundary: { features } } = results[i];
                    if (features && features.length > 0) {
                        finalBoundaries.push(features);
                    }
                }
                console.log(finalBoundaries);
                setFieldBoundaries(finalBoundaries);
            }
        }
    }, [gettingBoundaries, errorGettingBoundaries, fieldBoundaryData])

    // useEffect(() => {
    //     if ( !gettingDevices && !errorGettingDevices ) {
    //         const { getThingsByPlace } = devicesData;
    //         const { results } = getThingsByPlace;

    //         if ( results && results.length > 0 ) {
    //             setFieldDevices(results);
    //         }
    //     }
    // }, [gettingDevices, errorGettingDevices, devicesData]);

    useEffect(() => {
        if (!gettingOrders && !errorGettingOrders) {
            const { getOrderByGrower } = ordersData;
            const { results } = getOrderByGrower;

            let items = [];
            for (let i = 0; i < results.length; i++) {
                const row = results[i];
                if (!row.complete) {
                    const lineItems = row.lineItems.map((item) => {
                        let r = { ...item };
                        r.orderGuid = row.guid;
                        r.contractNumber = row.contractNumber;
                        r.dateOrdered = row.dateOrdered;

                        return r;
                    });

                    if (lineItems.length > 0) {
                        items = [...items, ...lineItems];
                    }
                }
            }

            setOrderItems([...items]);
        }
    }, [gettingOrders, errorGettingOrders, ordersData]);

    useEffect(() => {
        if (orderItems !== null) {
            setFieldDevices([...orderItems]);
        }
    }, [orderItems]);

    useEffect(() => {
        if (!gettingGateways && !errorGettingGateways) {
            const { getThingsForMap: { results } } = thingsForMap;
            // console.log("RESULTS", results);
            const gateways = results[0].features.filter(x => x.properties.type === "Gateway");
            const everythingElse = results[0].features.filter(x => x.properties.type !== null && x.properties.type !== "Gateway" && x.properties.pinFileName !== "pinHouseBlue.svg");
            setLocalGateways([...gateways]);
            setLocalThings([...everythingElse]);
        }
    }, [gettingGateways, errorGettingGateways, thingsForMap]);

    useEffect(() => {
        if (localGateways !== null) {
            setExistingGateways([...localGateways]);
        }
    }, [localGateways]);

    useEffect(() => {
        if (localThings !== null) {
            // console.log("SETTING LOCAL THINGS", localThings);
            setExistingThings([...localThings]);
        }
    }, [localThings]);

    return (
        <Row className="order-items-map-wrapper">
            <Col sm={12} md={4} className="order-items-col">
                <OrderItems
                    onClick={handlePinClick}
                    onCancelPin={handleCancelPin}
                    pendingMarker={pendingMarker}
                />
            </Col>
            <Col sm={12} md={8} className="map-col" ref={mapRef}>
                {fieldDetails && fieldDetails !== null &&
                    <>
                        <MapComponent
                            position={position}
                            field={fieldDetails}
                            devices={fieldDevices}
                            fieldBoundaries={fieldBoundaries}
                            currentBoundary={currentBoundary}
                            existingGateways={existingGateways}
                            existingThings={existingThings}
                            grower={grower}
                            pendingMarker={pendingMarker}
                            handleAddPin={handleAddPin}
                            handleCancelPin={handleCancelPin}
                            resetZoom={resetZoom}
                        />
                        <div className="button-row">
                            <Button variant="primary" onClick={handleCloseMap}>Close</Button>
                        </div>
                    </>
                }
            </Col>
        </Row>
    );
}

export default FieldCard;