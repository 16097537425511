

// type gatewayData{
//   epoch: Float
//   dateTime: String
//   snr: Float,
//   rssi: Float,
// }
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Table } from 'react-bootstrap';
import PageWrapper from '../../../components/PageWrapper';
import ContentHeader from '../../../components/ContentHeader';
import { GET_RAIN_DATA } from '../../../GraphQL/Queries/Things';

const Rain = (props) => {
  const [dashboardData, setMessages] = useState(null);

  const {
    deveui,
    start,
    end,
  } = props;

  const { loading: gettingMessages, error: errorGettingMessages, data: dashboardMessages } = useQuery(
    GET_RAIN_DATA, { context: { service: 'things' }, variables: { deveui, start, end }, fetchPolicy: "network-only" });


    useEffect(() => {
      if ( !gettingMessages && !errorGettingMessages) {
        console.log(deveui, start, end);
        const { getRainData: { results } } = dashboardMessages;  
        if (results && results.length) {
          setMessages([...results]);
        }
      }
    }, [gettingMessages, errorGettingMessages, dashboardMessages, deveui, end, start]);

    return (
    <PageWrapper>
      <div className="content-wrapper">
        <ContentHeader title="Rain" />
      </div>

      <Table responsive>
        <thead>
          <tr>
            <th>Date/Time</th>
            <th>snr</th>
            <th>rssi</th>
            <th>total</th>
          </tr>
        </thead>
        <tbody>
          {dashboardData !== null && dashboardData.length > 0 &&
            dashboardData.map((row, index) => {
              return (
                <tr key={index}>
                  <td>{row.dateTime}</td>
                  <td>{row.snr}</td>
                  <td>{row.rssi}</td>                
                  <td>{row.totalRainfallCount}</td> 
                </tr>
              )
            })
          }
        </tbody>
      </Table>
    </PageWrapper>
  );
}


export default Rain;


// type RainData{
//   epoch: Float
//   dateTime: String
//   snr: Float,
//   rssi: Float
//   totalRainfallCount Float
// }
