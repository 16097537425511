import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_PLANT_AVAILABLE_WATER_CHART } from '../../../../GraphQL/Queries/Irrigation';
import Spinner from '../../../../common/Spinner';

const PawTable = (props) => {
    const { plt: { thing } } = props;
    const [ pawTableData, setPawTableData ] = useState(null);

    const { 
        loading: gettingPAW, 
        error: errorGettingPAW, 
        data: pawData, 
        refetch: refetchPaw 
    } = useQuery(
        GET_PLANT_AVAILABLE_WATER_CHART,
        {
            variables: { deviceGuid: thing.guid },
            context: { service: 'things' }
        }
    );

    useEffect(() => {
        refetchPaw();
    }, [thing, refetchPaw]);

    useEffect(() => {
        if ( !gettingPAW && !errorGettingPAW ) {
            const { getPlantAvailableWaterChart: { results } } = pawData;
            if ( results !== null && results.length > 0 ) {
                setPawTableData([...results]);
            }
        }
    }, [gettingPAW, errorGettingPAW, pawData]);

    const convertRatioToPercent = (value) => {
        return Number(value).toFixed(2);
    }

    const turnToFixed = (value, place) => {
        return Number(value).toFixed(place);
    }

    if ( gettingPAW ) {
        return(<Spinner>...Getting PAW Chart</Spinner>);
    }

    return (
        <>
            <div className="soil-type-table-heading">Plant Available Water Chart</div>
            <div className="soil-type-table">
                <div className="soil-type-table-header">
                    <div>Sensor Depth</div>
                    <div className="align-right">Water Depleted (in/ft)</div>
                    <div className="align-right">Plant Available Water %</div>
                    <div className="align-right">Readily Available Water (in/ft)</div>
                    <div className="align-right">Soil Texture</div>
                    <div className="align-right">Sensor Reading</div>
                </div>
                {pawTableData !== null && pawTableData.map(reading => (
                    <div className="soil-type-table-row" key={reading.depth}>
                        <div>{`${reading.depth}"`}</div>
                        <div className="align-right">{`${turnToFixed(reading.waterDepleted, 2)} in`}</div>
                        <div className="align-right">{`${convertRatioToPercent(reading.paw)}%`}</div>
                        <div className="align-right">{`${turnToFixed(reading.raw, 2)} in`}</div>
                        <div className="align-right">{reading.soilTexture}</div>
                        <div className="align-right">{`${turnToFixed(reading.sensorReading, 2)} cb`}</div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default PawTable;
