export const sampleGrowers = [
    {
        id: '235232431',
        firstName: 'Chad',
        lastName: 'Johnson'
    },
    {
        id: '123442525234',
        firstName: 'Perry',
        lastName: 'Anderson',
    }
]

export const sampleGrowerFields = {
    'c52af9f8-33d5-480a-a25e-ddcda3bd46aa': [
        {
            fieldId: '52579e55-c1a3-42fc-9ee2-dbecd391d84d',
            fieldName: 'Moms 80',
            lat: 35.8032451,
            lng: -95.992775,
            markers: [
                {
                  "lat": 35.80621515021369,
                  "lng": -95.9331012976557,
                  "title": "Another Marker Went Here",
                  "pin": "pinRainGaugeGray",
                  "deviceName": "N Sensor",
                  "deviceNotes": "These are some very expansive and detailed notes to be displayed there.",
                  "fieldAccessNotes": "Access to this specific field are very detailed and quite lengthy. Also, they are somewhere confusing.",
                },
                {
                  "lat": 35.87301140191298,
                  "lng": -96.01412546757757,
                  "title": "Another Marker Went Here",
                  "pin": "pinPersonGray",
                  "deviceName": "",
                  "deviceNotes": "",
                  "fieldAccessNotes": "",
                },
                {
                  "lat": 35.81735176439651,
                  "lng": -96.12124216679632,
                  "title": "Another Marker Went Here",
                  "pin": "pinRainGaugeGray",
                  "deviceName": "",
                  "deviceNotes": "",
                  "fieldAccessNotes": "",
                },
                {
                  "lat": 35.75942431861896,
                  "lng": -96.04433786992132,
                  "title": "Another Marker Went Here",
                  "pin": "pinPersonGray",
                  "deviceName": "",
                  "deviceNotes": "",
                  "fieldAccessNotes": "",
                }
              ]
        },
        {
            fieldId: '975f8226-1432-4add-aded-712f1fd87088',
            fieldName: 'Spadys',
            lat: 35.8032451,
            lng: -95.992775,
            markers: [
                {
                  "lat": 35.8032451,
                  "lng": -95.992775,
                  "title": "This is a marker",
                  "pin": "pinRainGaugeGray",
                  "deviceName": "",
                  "deviceNotes": "",
                  "fieldAccessNotes": "",
                },
                {
                  "lat": 35.75459511456793,
                  "lng": -95.87542307499945,
                  "title": "Another Marker Went Here",
                  "pin": "pinRainGaugeGray",
                  "deviceName": "",
                  "deviceNotes": "",
                  "fieldAccessNotes": "",
                },
                {
                  "lat": 35.63860660143226,
                  "lng": -95.95232737187445,
                  "title": "Another Marker Went Here",
                  "pin": "pinRainGaugeGray",
                  "deviceName": "",
                  "deviceNotes": "",
                  "fieldAccessNotes": "",
                },
                {
                  "lat": 35.93048529961114,
                  "lng": -96.1748005164057,
                  "title": "Another Marker Went Here",
                  "pin": "pinRainGaugeGray",
                  "deviceName": "",
                  "deviceNotes": "",
                  "fieldAccessNotes": "",
                }
              ]
        },
        {
            fieldId: '32e5d39a-12bf-4e55-b3b5-9c851b537c0b',
            fieldName: 'Sac East',
            lat: 35.8032451,
            lng: -95.992775,
            markers: [
                {
                  "lat": 35.75533807503356,
                  "lng": -95.9935261023432,
                  "title": "Another Marker Went Here",
                  "pin": "pinRainGaugeGray",
                  "deviceName": "",
                  "deviceNotes": "",
                  "fieldAccessNotes": "",
                },
                {
                  "lat": 35.85446250708707,
                  "lng": -95.97979319218695,
                  "title": "Another Marker Went Here",
                  "pin": "pinRainGaugeGray",
                  "deviceName": "",
                  "deviceNotes": "",
                  "fieldAccessNotes": "",
                },
                {
                  "lat": 35.853349436714474,
                  "lng": -96.02236521367132,
                  "title": "Another Marker Went Here",
                  "pin": "pinRainGaugeGray",
                  "deviceName": "",
                  "deviceNotes": "",
                  "fieldAccessNotes": "",
                },
                {
                  "lat": 35.82440412285833,
                  "lng": -95.94683420781195,
                  "title": "Another Marker Went Here",
                  "pin": "pinRainGaugeGray",
                  "deviceName": "",
                  "deviceNotes": "",
                  "fieldAccessNotes": "",
                },
                {
                  "lat": 35.79099256249963,
                  "lng": -96.08004343632757,
                  "title": "Another Marker Went Here",
                  "pin": "pinRainGaugeGray",
                  "deviceName": "",
                  "deviceNotes": "",
                  "fieldAccessNotes": "",
                }
              ]
        }
    ],
    'd43d68d8-cab1-485a-a151-ef0a51a34b92': [
        {
            fieldId: "8391484c-4e18-4d55-adc8-c615060d3e90",
            fieldName: 'Home Pivot',
            lat: 35.8032451,
            lng: -95.992775,
            markers: [
                {
                  "lat": 35.89489347557938,
                  "lng": -96.11162912968695,
                  "title": "Another Marker Went Here",
                  "pin": "pinRainGaugeGray",
                  "deviceName": "",
                  "deviceNotes": "",
                  "fieldAccessNotes": "",
                },
                {
                  "lat": 35.84815491302421,
                  "lng": -96.07317698124945,
                  "title": "Another Marker Went Here",
                  "pin": "pinRainGaugeGray",
                  "deviceName": "",
                  "deviceNotes": "",
                  "fieldAccessNotes": "",
                },
                {
                  "lat": 35.730073153806714,
                  "lng": -95.89052927617132,
                  "title": "Another Marker Went Here",
                  "pin": "pinRainGaugeGray",
                  "deviceName": "",
                  "deviceNotes": "",
                  "fieldAccessNotes": "",
                },
                {
                  "lat": 35.89378097309062,
                  "lng": -95.84795725468695,
                  "title": "Another Marker Went Here",
                  "pin": "pinRainGaugeGray",
                  "deviceName": "",
                  "deviceNotes": "",
                  "fieldAccessNotes": "",
                },
                {
                  "lat": 35.70777390859006,
                  "lng": -96.07317698124945,
                  "title": "Another Marker Went Here",
                  "pin": "pinRainGaugeGray",
                  "deviceName": "",
                  "deviceNotes": "",
                  "fieldAccessNotes": "",
                }
              ],
        }
    ]
}

export const itemCardList = [
    {
        "id": 1,
        "title": "360 Agronomy (Irrigated-8)",
        "price": "$11.95",
        "unit": "acre",
        "description": "8 scouting trips with capacitance soil moisture probe",
        "tile": "tile360Ag",
        "pin": "pinPersonGray",
        "deviceType": "probe",
        "fields": [
          {
            "name": "deviceName",
            "required": true, 
          },
          {
            "name": "gatewayNeeded",
            "required": true, 
          },
          {
            "name": "deviceNotes"
          }, 
          {
            "name": "fieldAccessNotes"
          }
        ]
    },
    {
        "id": 2,
        "title": "Add-on Rain Bucket",
        "price": "$150",
        "unit": "year",
        "description": "Add rain bucket to a probe installation",
        "tile": "tileRainGauge",
        "pin": "pinRainGaugeGray",
        "deviceType": "other",
        "fields": [
          {
            "name": "deviceNotes"
          }, 
          {
            "name": "fieldAccessNotes"
          }
        ]
    },
    {
        "id": 3,
        "title": "Single Pre-ordered Flight (Stand Count Option)",
        "price": "$2.75",
        "unit": "acre",
        "description": "Stand count flight option: population map, weed map, and full imagery report",
        "tile": "tileDrone",
        "pin": "pinDroneImagerySage",
        "deviceType": "other",
        "fields": [
          {
            "name": "deviceNotes"
          }, 
          {
            "name": "fieldAccessNotes"
          }
        ]
    }
];

export const deviceOptions = {
  "deviceNames": [
    "N Sensor", "E Sensor", "S Sensor", "W Sensor", "NE Sensor", "NW Sensor", "SE Sensor", "SW Sensor", "Other"
  ]
}

export const gatewayNeededList = [
  'None', 'On Pivot', 'On Pole', 'On Structure'
];