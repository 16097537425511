import React, { useState, useEffect, useCallback } from "react";
import HighStocks from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { Col, Row } from 'react-bootstrap';
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import '../Charts/avgTrendLine.scss';

exporting(HighStocks);
exportData(HighStocks);

const defaultChartData = {
    exporting: {
        fallbackToExportServer: true,
        scale: 3,
        buttons: {
            contextButton: {
                menuItems: [
                    'downloadPNG',
                    'downloadJPEG',
                    'downloadPDF',
                    'downloadCSV',
                    'downloadXLS'
                ]
            }
        },
        csv: {
            itemDelimiter: ` ",`
        }
    },
    plotOptions: {
        series: {
            marker: {
                enabled: false
            },
        }
    },
    series: [],
    legend: {
        enabled: true,
    },
    rangeSelector: {
        buttons: [{
            type: 'day',
            count: 1,
            text: '1 Day'
        }, {
            type: 'week',
            count: 1,
            text: '1 Week'
        }, {
            type: 'week',
            count: 2,
            text: '2 Weeks'
        },
        {
            type: 'month',
            count: 1,
            text: '1 Month'
        }, {
            type: 'year',
            count: 1,
            text: 'Year'
        }, {
            type: 'all',
            text: 'All'
        }, {
            type: 'ytd',
            text: 'YTD'
        }],
        buttonTheme: {
            width: 60
        },
        selected: 2
    },
    xAxis: [{
        type: 'datetime',
        startOnTick: true,
        endOnTick: true,
        labels: {
            formatter: function () {
                const d = new Date(this.value);
                const m = d.getMonth() + 1;
                const dd = d.getDate();
                return m + '/' + dd;
            }
        }
    }, {
        type: 'datetime',
        labels: {
            formatter: function () {
                const d = new Date(this.value);
                const m = d.getMonth() + 1;
                const dd = d.getDate();
                return m + '/' + dd;
            }
        }
    }],
    yAxis: [{
        title: {
            text: 'Plant Available Water (PAW%)',
            enabled: false,
        },
        opposite: false,
        labels: {
            enabled: false,
        },
        plotLines: [
            {
                color: 'red',
                width: 2,
                value: 50,
                dashStyle: 'dash',
                name: 'wiltSF',
            },
            {
                color: 'red',
                width: 2,
                value: 80,
                dashStyle: 'dash',
                name: 'fullSF',
            }
        ]
    },
    {
        title: {
            text: "Temperature (C)",
            enabled: false,
        },
        labels: { enabled: false },
    }],
    tooltip: {
        shared: true,
        useHTML: true,
        backgroundColor: null,
        borderWidth: 0,
        shadow: false,
        followPointer: true,
        formatter: function () {
            const d = new Date(this.x);
            const m = d.getMonth() + 1;
            const dd = d.getDate();
            const y = d.getFullYear();
            let h = d.getHours();
            let mm = d.getMinutes();
            let ss = d.getSeconds();
            let amPm = 'AM'

            if (h > 12) {
                h = h - 12;
                amPm = 'PM';
            }

            if (mm < 10) {
                mm = '0' + mm;
            }

            if (ss < 10) {
                ss = '0' + ss;
            }

            const dFormat = m + '/' + dd + '/' + y + ' ' + h + ':' + mm + ':' + ss + amPm;

            let yLines = '';

            for (let i = 0; i < this.points.length; i++) {
                const point = this.points[i];
                const { color, series, y } = point;
                yLines += '<div class="tooltip-info-line">'
                    + '<span class="tooltip-info-axis">'
                    + '<span class="tooltip-info-color" style="background-color: ' + color + ';">&nbsp;</span>'
                    + '<span class="tooltip-info-name">' + series['name'] + '</span>'
                    + '</span>'
                    + '<span class="tooltip-info-text">'
                    + '<span>' + Number(y).toFixed(2) + '</span>'
                    + '</span>'
                    + '</div>';
            }

            return '<div class="chart-stocks">'
                + '<div class="tooltip-date">'
                + dFormat
                + '</div>'
                + '<div class="tooltip-info">'
                + yLines
                + '</div>'
                + '</div>';
        }
    },
}

const TribusSensorReadingChart = (props) => {
    const { series, plt } = props;
    // const deviceType = (props.deviceType) ? props.deviceType : 'capacitance';
    const [chartSeries, setSeries] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [fieldData, setFieldData] = useState(null);

    const updateChartSeries = useCallback(() => {
        if (series !== chartSeries || plt.place !== fieldData) {
            const cData = defaultChartData;
            const { place, link } = plt;
            const dSeries = series;
            for (let i = 0; i < dSeries.length; i++) {
                dSeries[i]['type'] = 'spline';
            }

            const updatePlotlines = (yAxis) => {
                const plotLines = yAxis.plotLines;

                if (plotLines && plotLines.length) {
                    for (let i = 0; i < plotLines.length; i++) {
                        const name = plotLines[i]['name'];

                        if (name && link[name]) {
                            plotLines[i]['value'] = link[name];
                        }
                    }

                    yAxis.plotLines = plotLines;
                }
                // console.log(dSeries);
                // console.log(plt.thing);
                if (plt.thing.type === 'Capacitance Probe' && dSeries.length > 0) {
                    yAxis = [{
                        title: {
                            text: 'Raw Sensor Reading (SF/each Sensor)',
                            enabled: false,
                        },
                        opposite: false,
                        labels: {
                            enabled: false,
                        },
                    },
                    {
                        title: {
                            text: "Temperature (C)",
                            enabled: true,
                        },
                        labels: {
                            enabled: true
                        },
                        opposite: true
                    }]

                } else if (plt.thing.type === 'Capacitance Probe') {
                    yAxis = [{
                        title: {
                            text: 'Raw Sensor Reading (SF/each Sensor)',
                            enabled: false,
                        },
                        opposite: false,
                        labels: {
                            enabled: false,
                        },
                    },
                    {
                        title: {
                            text: "Temperature (C)",
                            enabled: false,
                        },
                        labels: { enabled: false },

                        opposite: true
                    }]

                } else {
                    yAxis.title.enabled = true
                    yAxis.labels.enabled = true
                }
                return yAxis;
            }

            let yAxis = cData.yAxis;
            if (plt.thing.type === 'Rain Gauge' || plt.thing.type === 'Capacitance Probe'
                || plt.thing.type === 'Pivot Monitor' || plt.thing.type === 'Watermark Probe') {
                yAxis = updatePlotlines(yAxis);
            } else if (yAxis.length) {
                for (let i = 0; i < yAxis.length; i++) {
                    yAxis[i] = updatePlotlines(yAxis[i]);
                }
            }

            cData.yAxis = yAxis;
            cData['series'] = dSeries;

            setChartData(c => ({ ...c, ...cData }));
            setSeries([...dSeries]);
            setFieldData(f => ({ ...f, ...place }));
        }
    }, [plt, fieldData, chartSeries, series]);

    useEffect(() => {
        updateChartSeries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plt, series]);

    return (
        <Row>
            <Col xs={12}>
                {(chartData !== null) ? (
                    <HighchartsReact
                        highcharts={HighStocks}
                        options={chartData}
                        constructorType={"stockChart"}
                        updateArgs={[true, true, false]}
                        containerProps={{ style: { height: "550px" } }}
                    />
                ) : (null)}
            </Col>
        </Row>
    );
};

export default TribusSensorReadingChart;
