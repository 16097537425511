import React, { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_ET_SUMMARY } from '../../../GraphQL/Queries/Things';
import { GET_PLACE_BY_GUID } from "../../../GraphQL/Queries/Places";
import Spinner from "../../../common/Spinner";
import moment from 'moment';

const EtSummary = (props) => {
    const { plt: { place } } = props;
    const [data, setData] = useState(null);
    const { loading: gettingField, error: errorGettingField, data: fieldData } = useQuery(
        GET_PLACE_BY_GUID,
        {
            variables: { guid: place.guid },
            context: { service: 'places' }
        }
    );
    const [getETSummary, { loading: etSummaryLoading, called: etSummaryCalled, data: etSummaryData }] = useLazyQuery(GET_ET_SUMMARY);

    const setFixed = (value, pos) => {
        if (value === null) {
            return 0.00;
        }

        return Number(value).toFixed(pos);
    }

    const dayOffsetMinus = (offset) => {
        const date = moment().subtract(offset, 'days');
        const formatted = moment(date).format('MM/DD');

        return formatted;
    }

    const dayOffsetPlus = (offset) => {
        const date = moment().add(offset, 'days');
        const formatted = moment(date).format('MM/DD');

        return formatted;
    }

    useEffect(() => {
        if (!gettingField && !errorGettingField && fieldData) {
            const { getPlaceByGuid } = fieldData;
            const { results } = getPlaceByGuid;
            const field = results[0];
            getETSummary({
                variables: {
                    placeGuid: field.guid
                },
                context: {
                    service: 'things'
                }
            });
        }
    }, [gettingField, getETSummary, errorGettingField, fieldData]);

    useEffect(() => {
        if (etSummaryCalled && !etSummaryLoading && etSummaryData && etSummaryData !== null) {
            const { getEtSummary: { results } } = etSummaryData;
            if (results.length > 0) {
                const row = results[0];
                setData({ ...row });
            }
        }
    }, [etSummaryData, etSummaryCalled, etSummaryLoading]);

    return (
        <div className="et-summary-wrapper">
            {etSummaryCalled && etSummaryLoading &&
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <Spinner>...Getting ET Summary</Spinner>
                </div>
            }
            {data && data !== null &&
                <>
                    <div className="et-summary">
                        <div className="et-summary-label">Last 3</div>
                        <div className="et-summary-data">{setFixed(data.lastThree, 2)}&quot;</div>
                    </div>
                    <div className="et-summary">
                        <div className="et-summary-label">{dayOffsetMinus(1)}</div>
                        <div className="et-summary-data">{setFixed(data.yesterday, 2)}&quot;</div>
                    </div>
                    <div className="et-summary today">
                        <div className="et-summary-label">Today</div>
                        <div className="et-summary-data">{setFixed(data.today, 2)}&quot;</div>
                        <div className="et-summary-callout"></div>
                    </div>
                    <div className="et-summary">
                        <div className="et-summary-label">{dayOffsetPlus(1)}</div>
                        <div className="et-summary-data">{setFixed(data.tomorrow, 2)}&quot;</div>
                    </div>
                    <div className="et-summary">
                        <div className="et-summary-label">Next 3</div>
                        <div className="et-summary-data">{setFixed(data.nextThree, 2)}&quot;</div>
                    </div>
                    <div className="et-summary">
                        <div className="et-summary-label">Next 7</div>
                        <div className="et-summary-data">{setFixed(data.nextWeek, 2)}&quot;</div>
                    </div>
                </>
            }
        </div>
    );
};

export default EtSummary;
