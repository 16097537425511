import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
} from 'react-bootstrap';
import { Delete } from 'react-feather';

const FieldSettingsModal = (props) => {
  const { place, show, onHide, handleShareField, handleFindPerson, handleDeletePerson, sharedWith, handleClose } = props;
  const [form, setForm] = useState(null);
  // eslint-disable-next-line
  const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

  //TODO: Add call for all users shared to this field, determine ownership


  const handleSave = () => {
    handleShareField(form);
    onHide();
  }

  const handleDeletion = (event, person) => {
    handleDeletePerson(person);
  }

  const handleSearch = (event) => {
    if (emailRegex.test(event.target.value) && (event.target.value.endsWith('.com') || event.target.value.endsWith('.org') || event.target.value.endsWith('.net'))) {
      console.log("GETTING EMAIL", event.target.value);
      handleFindPerson(event.target.value);
    }
  }

  useEffect(() => {
    if (place !== null) {
      // const place = { ...place };
      const { pivot } = place;

      if (pivot === null) {
        var placeCopy = { ...place };

        placeCopy.pivot = {
          acresInches: 0,
          pivotPassAmount: 0,
          wellCapacity: 0,
        }
      }

      setForm(f => ({ ...f, ...place }));
    }
  }, [place]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Form.Label>Share Field</Form.Label>
        <Form.Group>
          <Form.Control
            type="text"
            as="input"
            name="email"
            size="sm"
            placeholder="Add people by email address"
            onChange={(e) => handleSearch(e)}
          >
          </Form.Control>
          <br />
          <Row>
            <Col xs={1}></Col>
            <Col xs={6}>Name</Col>
            <Col>Remove Access?</Col>
          </Row>
          {sharedWith && sharedWith.length > 0 &&
            sharedWith.map((row, index) => {
              return (
                <Row key={index}>
                  <Col xs={1}></Col>
                  <Col xs={7}>{row.name}</Col>
                  <Col><Delete id="deleteIcon" style={{ cursor: "pointer" }} onClick={(e) => handleDeletion(e, row)}></Delete></Col>
                </Row>
              )
            })}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>Cancel</Button>
        <Button variant="primary" onClick={handleSave}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FieldSettingsModal