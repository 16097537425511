import React from 'react';
import { useQuery } from '@apollo/client';
import { Table } from 'react-bootstrap';
import PageWrapper from '../../../components/PageWrapper';
import { GET_BROKEN_DEVICE } from '../../../GraphQL/Queries/Things';


const Sensor = (props) => {

  const {
    deviceSensors,
    device,
  } = props;

  const deveui = device;

  const { loading: gettingResponse, error: errorGettingResponse, data: response } = useQuery(GET_BROKEN_DEVICE, { context: { service: 'things' }, variables: { deveui }, fetchPolicy: "network-only" });
  console.log(deveui);
    if (!gettingResponse && !errorGettingResponse) {
      const { getBrokenDevice: { ok } } = response;
      if (ok & ok === true) {
        let deviceSensors = 'This device needs to be replaced or repaired.'
        return deviceSensors;
      } else {
        let deviceSensors = 'This device is working correctly.'
        return deviceSensors;
      }
    }


  return (
    <PageWrapper>
      <div className="content-wrapper">
      </div>

      <Table responsive>
        <tbody>
          <tr>
            <th>{deviceSensors}</th>
          </tr>
        </tbody>
      </Table>
    </PageWrapper>
  );
}

export default Sensor;