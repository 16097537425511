import { gql } from '@apollo/client';

export const LOGIN_USER = gql`
mutation LoginUser($token: String!, $tokenType: String!) {
    loginUser(token: $token, tokenType: $tokenType ) {
        accessToken
        ok
        error
    }
}
`;

export const GET_PERSON_BY_GUID = gql`
    query GetPersonByGuid($guid: ID!) {
        getPersonByGuid(guid: $guid) { 
            ok 
            message 
            results { 
                guid 
                name
                isManager
                isGrower
                isAgronomist
                emails {
                    email
                }
            } 
        } 
    }
`;

export const GET_PEOPLE_BY_EMAIL = gql`
    query GetPeopleByEmail($email: String!) {
        getPeopleByEmail(email: $email) { 
            ok 
            results { 
                guid 
                name
            } 
        } 
    }
`;

export const GET_MANAGED_PEOPLE = gql`
    query GetManagedPeople($guid: ID!) {
        getManagedPeople(guid: $guid) {
            ok
            results {
                guid 
                name
                isManager
            }
        }
    }
`;

export const GET_AGRONOMIST_GROWERS = gql`
    query GetAgronomistGrowers($guid: ID!) {
        getAgronomistGrowers(guid: $guid) {
            ok
            results {
                guid 
                name
            }
        }
    }
`;

export const GET_GROWER_FIELDS = gql`
    query GetPlacesByGrower($guid: ID!) {
        getPlacesByGrower(guid: $guid) {
            ok
            results {
                guid 
                fieldName
                senteraId
                senteraMapUrl
                cropYear
            }
        }
    }
`;

export default GET_PERSON_BY_GUID;