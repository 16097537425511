import React, { useState, useEffect } from 'react';
import { Accordion, Card, } from 'react-bootstrap';
import { useQuery } from "@apollo/client";
import AccordionHeader from '../../components/AccordionHeader';
import ChartComponent from './Charts';
import TribusChartComponent from './Tribus-Charts';
import Spinner from '../../common/Spinner';

import { GET_PLACE_BY_GUID } from '../../GraphQL/Queries/Places';
import { GET_THINGS_BY_PLACE } from '../../GraphQL/Queries/Things';

const GetGauge = (props) => {
    const { device, fieldPreview } = props;
    const [gauge, setGauge] = useState(null);

    useEffect(() => {
        if (fieldPreview && fieldPreview !== null && fieldPreview.devices) {
            const { devices } = fieldPreview;
            for (let i = 0; i < devices.length; i++) {
                if (devices[i]['deviceGuid'] === device['guid']) {
                    setGauge(devices[i]['svgGauge']);
                }
            }
        }
    }, [fieldPreview, device])

    return (
        gauge === null ? (
            null
        ) : (
            <img src={`data:image/svg+xml;utf8,${encodeURIComponent(gauge)}`} width="42" border="0" alt="Soil Moisture Gauge SVG" />
        )
    );
}

const DeviceCard = (props) => {
    const {
        field,
        fieldPreview,
        deviceOpen,
        handleDeviceOpen,
        refetchFieldSensorPreview,
        lazyPreview,
        guidArray,
        grower,
        cropYearSelected
    } = props;
    const [devices, setDevices] = useState(null);
    const [fieldDetails, setFieldDetails] = useState(null);
    const [cropYear, setCropYear] = useState();

    const { loading: gettingFieldByGuid, error: errorGettingFieldByGuid, data: fieldByGuid } = useQuery(
        GET_PLACE_BY_GUID,
        {
            variables: { guid: field.guid },
            fetchPolicy: "network-only",
            context: { service: 'places' }
        }
    );
    const { loading: gettingDevices, error: devicesError, data: devicesData, refetch: refetchDevices } = useQuery(
        GET_THINGS_BY_PLACE,
        {
            context: { service: 'things' },
            variables: { guid: field.guid }
        }
    );

    const display = (device) => {
        console.log('deviceName: ', device.links[0].deviceName);
        console.log('device: ', device);
        console.log('field: ', field);
        var display = '';
        var reg = /^[+-]?\d+$/;
        if (device.deveui !== null) {
            if ((device.deviceName === null || device.deviceName === "") && (device.links[0].deviceName === null || device.links[0].deviceName === "")) {
                display += device.deveui;
            } else if ((device.deviceName === null || device.deviceName === "") && (device.links[0].deviceName !== null || device.links[0].deviceName !== "")) {
                display += device.links[0].deviceName + ' / ' + device.deveui;;
            }
            else if ((reg.test(device.deviceName) && parseInt(device.deviceName) === device.deveui)) {
                display += device.deviceName;
            } else {
                display += device.deviceName + ' / ' + device.deveui;
            }
            if (device.type) {
                display += ' / ' + device.type;
            }
        } else {
            if ((device.deviceName === null || device.deviceName === "")) {
                display += device.sensorSerial;
            } else if ((reg.test(device.deviceName) && device.deviceName === device.sensorSerial)) {
                display += device.deviceName;
            } else {
                display += device.deviceName + ' / ' + device.sensorSerial;
            }
            if (device.type) {
                display += ' / ' + device.type;
            }
        }
        // if ((device.deviceName !== null && device.deviceName !== "") && (reg.test(device.deviceName) && parseInt(device.deviceName) !== device.deveui)) {
        //     display += ' / ' + device.deveui;
        // }
        return display;
    }

    const refreshDevices = () => {
        refetchDevices();
    }

    useEffect(() => {
        if (!gettingDevices && !devicesError) {
            const { getThingsByPlace } = devicesData;
            const { results } = getThingsByPlace;
            // const sorted = sortCollection(results, { 'deviceName': 'asc' } );

            setDevices(results);
        }
    }, [gettingDevices, devicesError, devicesData]);

    useEffect(() => {
        if (!gettingFieldByGuid && !errorGettingFieldByGuid) {
            const { getPlaceByGuid } = fieldByGuid;
            const { results } = getPlaceByGuid;
            if (results.length > 0) {
                const row = results[0];
                if (cropYearSelected) {
                    setCropYear(cropYearSelected)
                }
                setFieldDetails({ ...row });
            }
        }
    }, [gettingFieldByGuid, errorGettingFieldByGuid, fieldByGuid, cropYear, cropYearSelected]);

    if (gettingDevices) {
        return (<Card.Body><Spinner align="left">Getting Devices</Spinner></Card.Body>)
    }

    if (!devices || devices === null || devices.length === 0) {
        return (<Card.Body>No Devices Found</Card.Body>)
    }

    return (
        (devices !== null && !devices.length && fieldDetails !== null) ? (
            <Card.Body className="soil-moisture-graph-card">
                <ChartComponent
                    field={field}
                    deviceType={devices.type}
                    cropYear={cropYear}
                />
            </Card.Body>
        ) : (
            <Card.Body>
                <Accordion>
                    {devices !== null && devices.map((device, index) =>
                        <Card key={index}>
                            <AccordionHeader
                                eventKey={'device' + device.guid}
                                onClick={() => handleDeviceOpen(device.guid)}
                                isOpen={deviceOpen}
                                className="field-line-header"
                            >
                                <div className="title">{display(device)}</div>
                                <div className="gauge"><GetGauge device={device} fieldPreview={fieldPreview} /></div>
                            </AccordionHeader>
                            <Accordion.Collapse eventKey={'device' + device.guid} mountOnEnter unmountOnExit>
                                <Card.Body className="soil-moisture-graph-card">
                                    {device.deveui !== null &&
                                        <ChartComponent
                                            field={fieldDetails}
                                            device={device}
                                            fieldPreview={fieldPreview}
                                            refetchFieldSensorPreview={refetchFieldSensorPreview}
                                            lazyPreview={lazyPreview}
                                            guidArray={guidArray}
                                            refreshDevices={refreshDevices}
                                            grower={grower}
                                            cropYear={cropYear}
                                        />
                                    }
                                    { device.sensorSerial !== null &&
                                        <TribusChartComponent
                                            field={fieldDetails}
                                            fieldPreview={fieldPreview}
                                            device={device}
                                            guidArray={guidArray}
                                            refreshDevices={refreshDevices}
                                            grower={grower}
                                            cropYear={cropYear}
                                        />
                                    }
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    )}
                </Accordion>
            </Card.Body>
        )
    );
}

export default DeviceCard;