import { gql } from '@apollo/client';

export const GET_AGRONOMISTS = gql`
    query {
        getAgronomists{
            ok
            results{
                guid
                firstName
                lastName
                name
                emails{
                    email
                }
                phones{
                    phone
                    type
                }
                technician
            }
        }
    }
`;

export const GET_TECHNICIANS = gql`
    query {
        getTechnicians{
        ok
        results {
            guid
            firstName
            lastName
            name
        }
        }
    }
`;

export const GET_SHARED_WITH = gql`
    query GetSharedWith($guid: ID!) {
        getSharedWith(guid: $guid){
            ok
            total
            results {
              guid
              name
              emails {
                email
              }
            }
        }
    }
`;

export const GET_PEOPLE_BY_EMAIL = gql`
query GetPeopleByEmail($email: String!){
    getPeopleByEmail(email:$email){
    	ok
    	results
    	{
      	    guid
      	    name
      	    emails {
        	    type
        	    email
            }
        isManager
        isAgronomist
        fieldXGrowerId
        }
    }
}
`;