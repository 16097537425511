import React, { useCallback, useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { Navigation, Send, Phone, MessageSquare } from 'react-feather';
import { Icon } from '../../common/icons';
import { properCase } from '../../services/Data';
import { GET_AGRONOMISTS, GET_TECHNICIANS } from '../../GraphQL/Queries/People';
import { GET_ALL_CROPS } from '../../GraphQL/Queries/Crops';
import { thingStateList } from '../../data/thing';
import { useToasts } from 'react-toast-notifications';
import { CheckDeviceModal } from './Modals';
import { CALL_SONALERT } from '../../GraphQL/Mutations/Things';

let hexTimeout = undefined;

const DeviceDetails = (props) => {
    const {
        asAccordion,
        card,
        selectedDevice,
        setCardOpen,
        closeMarkerDetails,
        handleThingSave,
        handleStateSave,
        handleUnassociatedSave,
        // savingDeviceLink,
        addGateway,
        getDeviceGPSHistory
    } = props;

    const probeDepthMeasurement = ["", "metric system", "imperial system"]
    const tribusProbeTypes = ["", "greenshield", "aquacheck", "hydrawize"]

    const [checkDeviceModalOpen, setCDModalOpen] = useState(false);

    const [wCard, setCard] = useState(null);
    const [hexId, setHexId] = useState('');
    const [tribusProbeType, setTribusProbeType] = useState('');
    const [sensorSerial, setSensorSerial] = useState('');
    const [depthMeasurement, setDepthMeasurement] = useState('');
    const [cropGuid, setCropGuid] = useState('');
    const [cropsList, setCropsList] = useState(null);
    const [growthStages, setGrowthStages] = useState(null);
    const [technicians, setTechnicians] = useState([]);
    const [sDevice, setSelectedDevice] = useState(null);
    const [agronomists, setAgronomists] = useState([]);
    const [oldDeveui, setOldDeveui] = useState(null);
    const [textureNeeded, setTextureNeeded] = useState('');
    const [textureReceived, setTextureReceived] = useState('');
    const { addToast } = useToasts();

    const [callSonalert] = useMutation(CALL_SONALERT)
    const { loading: gettingCrops, error: errorGettingCrops, data: cropsData } = useQuery(GET_ALL_CROPS, { context: { service: 'crops' } });
    const { loading: gettingAgros, error: errorGettingAgros, data: agrosData } = useQuery(
        GET_AGRONOMISTS,
        {
            context: {
                service: 'people'
            }
        }
    );
    const { loading: gettingTechs, error: errorGettingTechs, data: techsData } = useQuery(
        GET_TECHNICIANS,
        {
            context: {
                service: 'people'
            }
        }
    );
    const handleSonalert = (deveui) => {
        console.log("Son-Alert: " + deveui);
        callSonalert({
            variables: { deveui: deveui },
            context: { service: 'things' }
        });
    }

    const handleFormChange = (event) => {
        var { name, value } = event.target;
        const c = JSON.parse(JSON.stringify(wCard));
        if (name === "agronomist") {
            if (c.properties.oldAgronomist === undefined) {
                c.properties.oldAgronomist = c.properties.agronomist;
            }

            const thisAgronomist = agronomists.filter(x => x.name === value)[0];
            const agronObject = {
                guid: thisAgronomist.guid,
                name: thisAgronomist.name
            }
            if (thisAgronomist.emails.length > 0) {
                agronObject.email = thisAgronomist.emails[0].email;
            } else {
                agronObject.email = "";
            }
            if (thisAgronomist.phones.length > 0) {
                agronObject.phone = thisAgronomist.phones[0].phone;
            } else {
                agronObject.phone = "";
            }
            c.properties.agronomist = agronObject;
        }
        if (name === "technician") {
            const thisTechnician = technicians.filter(x => x.guid === value);
            c.properties.technician = thisTechnician[0].guid;
        }
        if (name === "cropGuid" || name === "growthStage") {
            c.properties['crop'][name] = value;
        } else if (c.properties && c.properties[name] !== undefined) {
            c.properties[name] = value;
        } else {
            var tempProps = { ...c.properties };
            tempProps[name] = value;
            c.properties = tempProps;
        }

        setCard({ ...c });
    }
    const handleSetCrop = (event) => {
        const { value } = event.target;
        const selectedCrop = cropsList.filter((crop) => {
            return (crop.guid === value);
        });

        if (selectedCrop.length > 0) {
            const stages = selectedCrop[0].growthStages;
            setGrowthStages([...stages]);
        }
        handleFormChange(event);

        setCropGuid(value);
    }

    const isChecked = (e) => {
        var { name } = e.target;
        const c = JSON.parse(JSON.stringify(wCard));
        if (name === "textureNeeded") {
            if (textureNeeded === null || textureNeeded === false) {
                setTextureNeeded(true);
                c.properties.textureNeeded = true;
            } else {
                setTextureNeeded(false);
                c.properties.textureNeeded = false;
            }
        } else if (name === 'textureReceived') {
            if (textureReceived === null || textureReceived === false) {
                setTextureReceived(true);
                c.properties.textureReceived = true;
            } else {
                setTextureReceived(false);
                c.properties.textureReceived = false;
            }
        }
        setCard({ ...c });
    }
    const prepareForSaveState = () => {
        const li = { ...wCard.properties };
        handleStateSave(li)
    }
    const prepareForSave = () => {
        const li = { ...wCard.properties };
        const deveui = li.deveui;
        const guid = li.guid;
        const place = li.field.guid;
        const orderGuid = li.orderGuid;
        const lineItemGuid = li.lineItemGuid;
        if (li.crop) {
            li.cropGuid = li.crop.cropGuid;
            li.growthStage = li.crop.growthStage;
            delete li.crop;
        }

        if (li.oldAgronomist) {
            delete li.oldAgronomist;
        }
        delete li.agronomist;
        delete li.agronomistGuid;
        delete li.field;
        delete li.fieldGuid;
        delete li.fieldName;
        delete li.grower;
        delete li.growerGuid;
        delete li.growerName;
        delete li.boundary;
        delete li.latitude;
        delete li.longitude;
        delete li.placeGuid;
        delete li.deviceGuid;
        delete li.cropName;
        delete li.pin;
        delete li.probesToAverage;
        delete li.orderGuid;
        delete li.salesPersonGuid;
        delete li.deveui;
        delete li['__typename'];
        delete li.name;
        delete li.summary;
        delete li.hexId;
        delete li.bridgeFeaturesDelivered;
        delete li.deviceNameNeeded;
        delete li.externalData;
        delete li.imageFileName;
        delete li.minimumQuantity;
        delete li.price;
        delete li.sellingUnit;
        delete li.ngd;

        //for tribus
        delete li.tribusProbeType;
        delete li.sensorSerial;
        delete li.depthMeasurement;

        const thing = {
            deveui,
            guid,
            sensorSerial,
            tribusProbeType,
            depthMeasurement,
            orderGuid: orderGuid,
            lineItemGuid: lineItemGuid,
            placeGuid: place,
            link: li
        };

        if (oldDeveui !== thing.deveui) {
            thing.oldDeveui = oldDeveui;
        }
        if (((li.type === "Gateway" || (li.type === "Rain Gauge" && (li.deveui !== undefined && li.deveui !== null) && li.placeGuid === null))) && li.guid !== null) {
            // delete li.agronomistGuid;
            if (deveui !== undefined && deveui !== null) {
                li.deveui = deveui;
            }
            handleUnassociatedSave(li.guid, li);
        } else {
            handleThingSave(thing);
        }
    }

    const getGPSHistory = () => {
        // console.log(getDeviceGPSHistory);
        getDeviceGPSHistory();
    }

    const deveuiToHex = (val) => {
        // let hexString = yourNumber.toString(16);
        return Number(val).toString(16);
    }
    const hexToDeveui = (val) => {
        // yourNumber = parseInt("0300104D", 16)
        return parseInt(val, 16);
    }
    const handleHexIdChange = (event) => {
        const value = event.target.value;
        setHexId(value);
        window.clearTimeout(hexTimeout);
        window.setTimeout(() => {
            const c = JSON.parse(JSON.stringify(wCard));
            const deveui = hexToDeveui(value);

            c.properties.deveui = isNaN(deveui) ? '' : deveui;
            c.properties.hexId = value;
            setCard({ ...c });
        }, 300);
    }

    //for tribus probe
    const handleTProbeTypeChange = (event) => {
        const value = event.target.value;
        setTribusProbeType(value);
        window.clearTimeout(hexTimeout);
        window.setTimeout(() => {
            const c = JSON.parse(JSON.stringify(wCard));
            c.properties.tribusProbeType = value;
            setCard({ ...c });
        }, 300);
    }
    const handleSensorSerialChange = (event) => {
        const value = event.target.value;
        setSensorSerial(value);
        window.clearTimeout(hexTimeout);
        window.setTimeout(() => {
            const c = JSON.parse(JSON.stringify(wCard));
            c.properties.sensorSerial = value;
            setCard({ ...c });
        }, 300);
    }
    const handleDepthMeasurementChange = (event) => {
        const value = event.target.value;
        setDepthMeasurement(value);
        window.clearTimeout(hexTimeout);
        window.setTimeout(() => {
            const c = JSON.parse(JSON.stringify(wCard));
            c.properties.depthMeasurement = value;
            setCard({ ...c });
        }, 300);
    }

    const getVal = useCallback((prop, nested) => {
        const c = (wCard.thing) ? wCard.thing : wCard;
        if (c && c.properties) {
            if (nested !== undefined && nested !== null && c.properties[nested]) {
                return c.properties[nested][prop] || '';
            } else {
                return c.properties[prop] || '';
            }
        }
        return '';
    }, [wCard]);

    const handleGPSChange = (event, type) => {
        try {
            var value = event.target.value;
            const c = JSON.parse(JSON.stringify(wCard));
            value = parseFloat(value);
            if (type === "lng") {
                c.properties.lng = value;
                setCard({ ...c });
            } else if (type === "lat") {
                c.properties.lat = value;
                // let split = value.split(',');

                // if (split.length === 2) {
                //     const lat = parseFloat(split[0]);
                //     const lng = parseFloat(split[1]);
                //     c.properties.lat = lat;
                //     c.properties.lng = lng;
                setCard({ ...c });
            }
        } catch (err) {
            console.log("ERR", err);
            addToast('GPS Coordinates Must Be Numbers', {
                appearance: 'error',
                autoDismiss: true
            });
        }
    }

    const itemHeader = () => {
        const c = (wCard.thing) ? wCard.thing : wCard;
        if (c.properties) {
            const { grower } = c.properties;
            const { field } = c.properties;
            const { deviceName } = c.properties;
            let growerName = 'no grower';
            let fieldName = 'no field';

            if (c && grower) {
                if (grower.firstName) {
                    growerName = grower.firstName + ' ' + grower.lastName
                } else {
                    if (grower.name) {
                        growerName = grower.name;
                    }
                }
                growerName = properCase(growerName)
            }

            if (c && field) {
                if (field.fieldName) {
                    fieldName = field.fieldName;
                }
                fieldName = properCase(fieldName);
            }
            return (
                <>{growerName} &gt;&nbsp; {fieldName} &gt;&nbsp; {deviceName}</>
            );
        }
    }

    const growerDetails = () => {
        const c = (wCard.thing) ? wCard.thing : wCard;
        const { grower } = c.properties;
        if (grower && grower !== null) {
            if (grower.firstName) {
                const growerPhone = grower['phone'];
                const growerEmail = grower['email'];
                return (
                    <>
                        <p>
                            <a href={`sms:${growerPhone}`}>
                                <button className="text-message" style={{ background: "transparent", border: "transparent" }}>
                                    <MessageSquare size={14} />
                                </button>
                            </a>
                            <label>&nbsp; Grower: </label>{grower['name']}

                        </p>
                        <p>
                            <a href={`tel:${growerPhone}`}>
                                <button className="phone-call" style={{ background: "transparent", border: "transparent" }}>
                                    <Phone size={14} />
                                </button>
                            </a>
                            <label>&nbsp; Grower Phone: </label>{grower['phone'] || null}
                        </p>
                        <p>
                            <a href={`mailto:${growerEmail}`}>
                                <button className='send-email' style={{ background: "transparent", border: "transparent" }}>
                                    <Send size={14} />
                                </button>
                            </a>
                            <label>&nbsp; Grower Email: </label>{grower['email'] || null}
                        </p>
                    </>
                )
            } else {
                const growerPhone = grower['phone'];
                const growerEmail = grower['email'];
                return (
                    <>
                        <p>
                            <a href={`sms:${growerPhone}`}>
                                <button className="text-message" style={{ background: "transparent", border: "transparent" }}>
                                    <MessageSquare size={14} />
                                </button>
                            </a>
                            <label>&nbsp; Grower: </label>{grower['name']}

                        </p>
                        <p>
                            <a href={`tel:${growerPhone}`}>
                                <button className="phone-call" style={{ background: "transparent", border: "transparent" }}>
                                    <Phone size={14} />
                                </button>
                            </a>
                            <label>&nbsp; Grower Phone: </label>{grower['phone'] || null}
                        </p>
                        <p>
                            <a href={`mailto:${growerEmail}`}>
                                <button className='send-email' style={{ background: "transparent", border: "transparent" }}>
                                    <Send size={14} />
                                </button>
                            </a>
                            <label>&nbsp; Grower Email: </label>{grower['email'] || null}
                        </p>
                    </>
                )
            }
        }
    }

    const agronomistDetails = () => {
        const c = (wCard.thing) ? wCard.thing : wCard;
        const { agronomist } = c.properties;
        if (agronomist && agronomist !== null && agronomist.name) {
            const agronomistPhone = agronomist['phone'];
            const agronomistEmail = agronomist['email'];
            return (
                <>
                    <p>
                        <a href={`sms:${agronomistPhone}`}>
                            <button className="text-message" style={{ background: "transparent", border: "transparent" }}>
                                <MessageSquare size={14} />
                            </button>
                        </a>
                        <label>&nbsp; Agronomist: </label>{c.properties['agronomist']['name']}
                    </p>
                    <p>
                        <a href={`tel:${agronomistPhone}`}>
                            <button className="phone-call" style={{ background: "transparent", border: "transparent" }}>
                                <Phone size={14} />
                            </button>
                        </a>
                        <label>&nbsp; Agronomist Phone: </label>{c.properties['agronomist']['phone'] || null}
                    </p>
                    <p>
                        <a href={`mailto:${agronomistEmail}`}>
                            <button className='send-email' style={{ background: "transparent", border: "transparent" }}>
                                <Send size={14} />
                            </button>
                        </a>
                        <label>&nbsp; Agronomist Email: </label>{c.properties['agronomist']['email'] || null}
                    </p>
                </>
            )
        } else {
            return <p><label>Agronomist: </label></p>
        }
    }

    // const contactDetails = () => {
    //     const c = (wCard.thing) ? wCard.thing : wCard;
    //     return;
    // }

    const navigateToGPS = () => {
        const url = "https://www.google.com/maps/search/" + getVal('lat', 'lineItem') + "," + getVal('lng', 'lineItem') + "?hl=en"
        window.open(url);
    }

    const handleSetSelectedDevice = useCallback(() => {
        if (selectedDevice !== null && selectedDevice.hasOwnProperty('id')) {
            const sId = selectedDevice['id'].toString();
            const cId = card['id'].toString();
            if (setCardOpen) {
                setCardOpen(sId === cId);
                setSelectedDevice(selectedDevice);
                setOldDeveui(selectedDevice.properties.deveui);
            }
        }

        setHexId('');
        setTextureNeeded('');
        setTextureReceived('');
        setCropGuid('');
        console.log("CARD", card);
        setCard(card);
        setOldDeveui(card.properties.deveui);
        //Tribus properties
        setTribusProbeType('');
        setSensorSerial('');
        setDepthMeasurement('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [card, selectedDevice]);

    /**
     * 
     * Lifecycle Hooks
     * 
     */
    useEffect(() => {
        if (wCard !== null) {
            // tribus probes do not have a hex or deveui
            if (hexId === '') {
                setHexId(getVal('hexId', 'lineItem'));
            }

            if (textureNeeded === '') {
                setTextureNeeded(getVal('textureNeeded'));
            }

            if (textureReceived === '') {
                setTextureReceived(getVal('textureReceived'));
            }

            if (cropGuid === null) {
                setCropGuid(getVal('cropGuid', 'crop'));
            }
            if (growthStages === null && cropsList !== null) {
                const selectedCrop = cropsList.filter((crop) => {
                    return (crop.guid === getVal('cropGuid', 'crop'));
                });

                if (selectedCrop.length > 0) {
                    const stages = selectedCrop[0].growthStages;
                    setGrowthStages([...stages]);
                }
            }

            //tribus properties
            if (tribusProbeType === '') {
                setTribusProbeType(getVal('tribusProbeType'))
            }
            if (sensorSerial === '') {
                setSensorSerial(getVal('sensorSerial'))
            }
            if (depthMeasurement === '') {
                setDepthMeasurement(getVal('depthMeasurement'))
            }

        }
    }, [wCard, hexId, textureNeeded, textureReceived, growthStages, cropsList, cropGuid, getVal, tribusProbeType, sensorSerial, depthMeasurement]);

    useEffect(() => {
        if (sDevice !== selectedDevice) {
            handleSetSelectedDevice();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleSetSelectedDevice, sDevice, selectedDevice]);

    useEffect(() => {
        if (!gettingAgros && !errorGettingAgros) {
            var { getAgronomists: { results } } = agrosData;
            var agrons = [...results]
            agrons.sort((a, b) => a.name.localeCompare(b.name));
            setAgronomists([...agrons]);
        }
    }, [gettingAgros, errorGettingAgros, agrosData]);

    useEffect(() => {
        if (!gettingTechs && !errorGettingTechs) {
            var { getTechnicians: { results } } = techsData;
            var techs = [...results]
            techs.sort((a, b) => a.name.localeCompare(b.name));
            setTechnicians([...techs]);
        }
    }, [gettingTechs, errorGettingTechs, techsData]);

    useEffect(() => {
        if (!gettingCrops && !errorGettingCrops) {
            const { getAllCrops: { results } } = cropsData;

            if (results && results.length) {
                setCropsList([...results]);
            }
        }
    }, [gettingCrops, errorGettingCrops, cropsData]);

    /**
     * 
     * End Lifecyle Hooks
     * 
     */

    if (wCard === null) {
        return (null);
    }

    const openCheckDeviceModal = (modal) => {
        switch (modal) {
            case 'deviceChecker':
                setCDModalOpen(true);
                break;

            default:
                setCDModalOpen(false);
                break;
        }
    }


    return (
        <div className="card-details">
            {!asAccordion &&
                <div className="device-details-header">
                    <Icon
                        type="pins"
                        name={getVal('pinFileName')}
                        // fill="#000"
                        width={24}
                        style={{ marginRight: 8 }}
                    />
                    ID: #{getVal('id')} &gt;&nbsp; {itemHeader()}
                </div>
            }
            <div className="additional-information-section" style={{paddingTop:"26px"}}>
                {wCard.guid}
                <p><label><b>Realm Device IDs:</b></label></p>
                <Form.Group>
                    <Form.Label>Hex ID: </Form.Label>
                    <Form.Control
                        type="text"
                        name="hexId"
                        defaultValue={deveuiToHex(getVal('deveui'))}
                        onChange={handleHexIdChange}
                    />
                </Form.Group>
                <p><label>Decimal ID:</label>{getVal('deveui')}</p>

                <hr style={{ width: "100%", border: ".7px solid black" }} />

                <p><label><b>Tribus Device Info:</b></label></p>
                <p><label>Device Type:</label></p>
                <Form.Control as="select" name="tribusProbeType" onChange={handleTProbeTypeChange} defaultValue={getVal('tribusProbeType') || ""}>{tribusProbeTypes.map((type, index) =>
                    <option value={type} key={index}>{type}</option>
                )}</Form.Control>
                <Form.Group>
                    <Form.Label>Sensor Serial: </Form.Label>
                    <Form.Control
                        type="text"
                        name="sensorSerial"
                        defaultValue={getVal('sensorSerial')}
                        onChange={handleSensorSerialChange}
                    />
                </Form.Group>
                <p><label>Sensor Measurement Type:</label></p>
                <Form.Control as="select" name="depthMeasurement" onChange={handleDepthMeasurementChange} defaultValue={getVal('depthMeasurement') || ""}>{probeDepthMeasurement.map((measurement, index) =>
                    <option value={measurement} key={index}>{measurement}</option>
                )}</Form.Control>

                <hr style={{ width: "100%", border: ".7px solid black", marginTop: "4vh" }} />

                <Form.Group>
                    <Form.Label>Device Name: </Form.Label>
                    <Form.Control
                        type="text"
                        name="deviceName"
                        defaultValue={getVal('deviceName')}
                        onChange={(e) => handleFormChange(e)}
                    />
                </Form.Group>
                {/* <p><label>Device Name: </label>{getVal('deviceName') || null}</p> */}
                <Row>
                    <Col xs={12} md={8}>
                        <p><label>Gateway Needed?</label>{getVal('gatewayNeeded') ? getVal('gatewayNeeded') : 'None'}</p>
                    </Col>
                    <Col xs={12} md={4}>
                        <Button size="sm" onClick={addGateway}>Add Gateway</Button>
                    </Col>
                </Row>
                {/* <p><label>GPS: </label>{getGPS()}</p> */}
                <Form.Group>
                    <Navigation id='navigation' onClick={navigateToGPS}>Locate</Navigation>
                    <Form.Label>GPS: </Form.Label>
                    <p><label>Lat:</label></p>
                    <Form.Control
                        type="text"
                        name="lat"
                        defaultValue={getVal('lat', 'lineItem')}
                        onChange={(e) => handleGPSChange(e, "lat")}
                    />
                    <p><label>Lng:  *should be negative*</label></p>
                    <Form.Control
                        type="text"
                        name="lng"
                        defaultValue={getVal('lng', 'lineItem')}
                        onChange={(e) => handleGPSChange(e, "lng")}
                    />
                </Form.Group>
                <Button
                    onClick={getGPSHistory}
                    size="small"
                >
                    Show GPS History
                </Button>
                <Button
                    onClick={(e) => handleSonalert((getVal('deveui')))}
                    size="small"
                >
                    Son-Alert
                </Button>
                <p><label>Status: </label>{getVal('status') || null}</p>
                {/* <p><label>Installed Date: </label>{getVal('installDate') || null}</p> */}
                <p><label>Device Type: </label>{getVal('type') || getVal('deviceType') || null}</p>
                <Form.Group>
                    <Form.Label>State: </Form.Label>
                    <Form.Control
                        as="select"
                        name="state"
                        onChange={(e) => handleFormChange(e)}
                        value={getVal('state', 'lineItem') || getVal('state') || 'Not Ready'}
                    >
                        <option value="" disabled>Select a State</option>
                        {thingStateList.map((state, index) =>
                            (state !== 'All') &&
                            <option value={state} key={index}>{state}</option>
                        )}
                    </Form.Control>
                    <Button
                        onClick={prepareForSaveState}
                        disabled={!getVal('state')}
                    >Save State</Button>

                </Form.Group>
                <Form.Group>
                    <Form.Label>Technician: </Form.Label>
                    <Form.Control
                        as="select"
                        // size="sm" 
                        name="technician"
                        onChange={(e) => handleFormChange(e)}
                        value={getVal('technician', 'lineItem') || getVal('technician') || ''}
                    >
                        <option value="" disabled>Select a Technician</option>
                        {technicians.map((technician, index) =>
                            <option value={technician.guid} key={index}>{properCase(technician.name)}</option>
                        )}
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Installation Date: </Form.Label>
                    {/* <Form.Control
                        as="select"
                        // size="sm" 
                        name="technician"
                        onChange={(e) => handleFormChange(e)}
                        defaultValue={getVal('technician', 'lineItem') || ''}
                    >
                        <option defaultValue="" disabled>Select a Technician</option>
                        {technicians.map((technician, index) =>
                            <option defaultValue={technician.guid} key={index}>{properCase(technician.name)}</option>
                        )}
                    </Form.Control> */}
                </Form.Group>
                <Form.Group>
                    <Form.Label>Crop</Form.Label>
                    <Form.Control
                        as="select"
                        name="cropGuid"
                        value={getVal('cropGuid', 'crop')}
                        onChange={handleSetCrop}
                    >
                        <option value=""></option>
                        {cropsList !== null && cropsList.map((crop, index) =>
                            <option value={crop.guid} key={index}>{crop.name}</option>
                        )}
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Growth Stage</Form.Label>
                    <Form.Control
                        as="select"
                        name="growthStage"
                        value={getVal('growthStage', 'lineItem') || getVal('growthStage', 'crop')}
                        onChange={(e) => handleFormChange(e)}
                        disabled={(growthStages === null)}
                    >
                        <option value=""></option>
                        {growthStages !== null && growthStages.map((stage, index) =>
                            <option value={stage.name} key={index}>{stage.name}</option>
                        )}
                    </Form.Control>
                </Form.Group>


                <Form.Group>
                    <Form.Label>Operation Notes:</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        name="deviceNotes"
                        defaultValue={getVal('deviceNotes', 'lineItem') || getVal('deviceNotes') || ''}
                        onChange={(e) => handleFormChange(e)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Field Access:</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        name="fieldAccessNotes"
                        defaultValue={getVal('fieldAccessNotes', 'lineItem') || getVal('fieldAccessNotes') || ''}
                        onChange={(e) => handleFormChange(e)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Maintenance Notes:</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        name="maintenanceNotes"
                        defaultValue={getVal('maintenanceNotes', 'lineItem') || getVal('maintenanceNotes') || ''}
                        onChange={(e) => handleFormChange(e)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Agronomist: </Form.Label>
                    <Form.Control
                        as="select"
                        // size="sm"
                        name="agronomist"
                        onChange={(e) => handleFormChange(e)}
                        value={getVal('guid', 'agronomist') || ''}
                    >
                        <option value="" disabled>Select an Agronomist</option>
                        {agronomists.map((row, index) =>
                            <option key={index} value={row['guid']}>{properCase(row['name'])}</option>
                        )}
                    </Form.Control>
                </Form.Group>
                <div className='soil-info'>
                    <Form.Group>
                        <Form.Label>Need Textures?: </Form.Label>
                        <Form.Check
                            inline
                            style={{ paddingLeft: '15px' }}
                            type="checkbox"
                            name="textureNeeded"
                            defaultValue={textureNeeded}
                            onChange={(e) => isChecked(e)}
                            checked={getVal('textureNeeded')}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Textures Received?: </Form.Label>
                        <Form.Check
                            inline
                            style={{ paddingLeft: '15px' }}
                            type="checkbox"
                            name="textureReceived"
                            value={textureReceived}
                            onChange={(e) => isChecked(e)}
                        // checked={textureReceived}
                        /></Form.Group>
                    <p><label>Texture 1 ft: </label>{getVal('oneFtSoilTexture', 'field') || getVal('oneFtSoilTexture') || null}</p>
                    <p><label>Texture 2 ft: </label>{getVal('twoFtSoilTexture', 'field') || getVal('twoFtSoilTexture') || null}</p>
                    <p><label>Texture 3 ft: </label>{getVal('threeFtSoilTexture', 'field') || getVal('threeFtSoilTexture') || null}</p>
                </div>
                {growerDetails()}
                {agronomistDetails()}
                {/* {contactDetails()} */}
            </div>
            <div className="button-row">
                <div className="check-device">
                    <Button
                        variant='light' onClick={() => { openCheckDeviceModal('deviceChecker'); }}>
                        Check Device
                    </Button>
                </div>
                <Button variant="light" onClick={closeMarkerDetails}>Close</Button>
                <Button
                    variant="primary"
                    onClick={prepareForSave}
                    // disabled={savingDeviceLink}
                    // disabled={(savingDeviceLink || hexId === '' || hexId === null)}
                    // disabled={(savingDeviceLink || itemHeader === ' No Grower >  No Field')}
                    disabled={(itemHeader === ' No Grower >  No Field')}
                >Save Changes</Button>
            </div>
            <CheckDeviceModal
                isOpen={checkDeviceModalOpen} setModalOpen={setCDModalOpen} selectedDevice={selectedDevice} />
        </div >
    );
}

export default React.memo(DeviceDetails);