import React, { useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';

import PageWrapper from '../../components/PageWrapper';
import Growers from './Growers';
import { getIsManager, getIsGrower, getIsAgronomist, getUserGUID } from '../../services/User';
import ManagedPeople from './ManagedPeople';
import FieldListCard from './FieldListCard';

import './soilMoisture.scss';
import './Cards/soilMoistureCards.scss';
import AccordionHeader from '../../components/AccordionHeader';

const SoilMoistureComponent = () => {
    const isManager = getIsManager();
    const isGrower = getIsGrower();
    const isAgronomist = getIsAgronomist();
    const guid = getUserGUID();
    
    const [ listToView, setListToView ] = useState(null);
    const [ fieldOpen, setFieldOpen ] = useState(null);

    const handleFieldOpen = (fieldId) => {
        const fieldName = 'field-' + fieldId;

        if ( fieldId === null ) {
            return;
        }

        if ( fieldName !== fieldOpen ) {
            setFieldOpen(fieldName);
        } else {
            setFieldOpen(null);
        }
    }

    const handleSetListToView = (list) => {
        if ( list !== listToView ) {
            setListToView(list);
        } else {
            setListToView(null);
        }
    }

    return(
        <PageWrapper>
            <div className="content-wrapper">
                <Accordion>
                    {isGrower &&
                        <Card>
                            <AccordionHeader
                                eventKey={'fields'}
                                onClick={() => { handleSetListToView('fields') }}
                                isOpen={listToView}
                            >
                                Your Fields
                            </AccordionHeader>
                            <Accordion.Collapse eventKey={'fields'} mountOnEnter unmountOnExit>
                                <Card.Body>
                                    <Accordion>
                                        <FieldListCard 
                                            guid={guid} 
                                            handleFieldOpen={handleFieldOpen}
                                            fieldOpen={fieldOpen}
                                        />
                                    </Accordion>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    }
                    {isAgronomist &&
                        <Card>
                            <AccordionHeader
                                eventKey={'growers'}
                                onClick={() => { handleSetListToView('growers') }}
                                isOpen={listToView}
                            >
                                Your Growers
                            </AccordionHeader>
                            <Accordion.Collapse eventKey={'growers'} mountOnEnter unmountOnExit>
                                <Card.Body>
                                    <Growers
                                        guid={guid}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    }
                    {isManager &&
                        <Card>
                            <AccordionHeader
                                eventKey={'managedPeople'}
                                onClick={() => { handleSetListToView('managedPeople') }}
                                isOpen={listToView}
                            >
                                Your People
                            </AccordionHeader>
                            <Accordion.Collapse eventKey={'managedPeople'} mountOnEnter unmountOnExit>
                                <Card.Body>
                                    <ManagedPeople guid={guid} />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    }
                </Accordion>
            </div>
        </PageWrapper>
    );
}

export default SoilMoistureComponent;