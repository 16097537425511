import jwtDecode from 'jwt-decode';
import { testToken, tokenName, useTestToken } from '../config';

export const isLoggedIn = () => {
    const token = getToken();
    const guid = getUserGUID();
    const isManager = getIsManager();

    return (token !== null && guid !== null && isManager !== null);
}


export const clearSession = () => {
    sessionStorage.clear();
}

// Token GET/SET/CLEAR
export const getToken = () => {
    if (useTestToken) {
        return testToken;
    } else {
        return sessionStorage.getItem(tokenName);
    }
}

export const setToken = (s) => {
    if (s) {
        sessionStorage.setItem(tokenName, s);
    }

    return s;
};

export const clearToken = () => {
    sessionStorage.removeItem(tokenName);
}

// isManager GET/SET
export const getIsManager = () => {
    const isManager = sessionStorage.getItem('isManager');
    return (isManager && isManager === 'true');
}
export const getIsGrower = () => {
    const isGrower = sessionStorage.getItem('isGrower');
    return (isGrower && isGrower === 'true');
}
export const getIsAgronomist = () => {
    const isAgronomist = sessionStorage.getItem('isAgronomist');
    return (isAgronomist && isAgronomist === 'true');
}

export const setIsManager = (isManager) => {
    sessionStorage.setItem('isManager', isManager);
}

export const setIsGrower = (isGrower) => {
    sessionStorage.setItem('isGrower', isGrower);
}

export const setIsAgronomist = (isAgronomist) => {
    sessionStorage.setItem('isAgronomist', isAgronomist);
}

export const clearIsManager = () => {
    sessionStorage.removeItem('isManager');
}

export const clearIsSettings = () => {
    sessionStorage.removeItem('isAgronomist');
    sessionStorage.removeItem('isGrower');
    sessionStorage.removeItem('isManager');
    sessionStorage.removeItem('email');
}

// GUID GET/SET
export const getUserGUID = () => {
    if (useTestToken) {
        const token = getUserObject();
        return token.guid;
    } else {
        return sessionStorage.getItem('guid');
    }
}

export const setUserGUID = (guid) => {
    sessionStorage.setItem('guid', guid);
}

export const setUserDetails = (user) => {
    sessionStorage.setItem('userDetails', JSON.stringify(user));
}

export const clearUserDetails = () => {
    sessionStorage.clearItem('userDetails');
}

export const getUserDetails = (user) => {
    const userDetails = sessionStorage.getItem('userDetails');
    return JSON.parse(userDetails);
}

export const clearUserGUID = () => {
    sessionStorage.removeItem('guid');
}

// UserObject GET
export const getUserObject = () => {
    let decoded = '';
    try {
        decoded = jwtDecode(getToken());
    } catch (error) {
        decoded = undefined;
    }

    return decoded;
}

// Last Location GET/SET
export const setLastLocation = (loc) => {
    sessionStorage.setItem('lastLocation', loc);
}

export const getLastLocation = () => {
    return sessionStorage.getItem('lastLocation');
}

export const clearLastLocation = () => {
    sessionStorage.removeItem('lastLocation');
}

//userEmail get/set
export const getEmail = () => {
    const email = sessionStorage.getItem('email');
    return (email);
}
export const setEmail = (email) => {
    sessionStorage.setItem('email', email);
}

export const getIsServitechUser = () => {
    const servitechEmail = "servitech";
    //alows Emile from tribus acces to the devices page:
    const emileEmail = 'emilejordaan.za@gmail.com';
    const truhannEmail = 'truhann@gmail.com';
    if (sessionStorage.email.includes(servitechEmail)) {
        return (true);
    } else if (sessionStorage.email.includes(emileEmail) || sessionStorage.email.includes('truhann') || sessionStorage.email.includes('ssbryan79')) {
        return (true)
    } else {
        return (false);
    }
}
