import React, { useEffect, useState } from 'react';
import { Accordion, Card, } from 'react-bootstrap';
import { useQuery } from "@apollo/client";
import AccordionHeader from '../../components/AccordionHeader';
import FieldCard from './FieldCard';
import Spinner from '../../common/Spinner';
import { sortCollection } from '../../services/Data';
import { GET_GROWER_FIELDS } from '../../GraphQL/Queries/User';

// import growersList from '../../mockData/growersList.json';

const GrowerFields = (props) => {
    const {
        fieldOpen,
        grower,
        handleFieldOpen,
    } = props;

    const [ fieldList, setFieldList ] = useState(null);

    const { loading: gettingFields, error: fieldsError, data: fields } = useQuery(
        GET_GROWER_FIELDS, 
        { 
            context: { service: 'places' }, 
            variables: { guid: grower.guid }
        }
    );

    const hasAFieldList = () => {
        return (
            fieldList && fieldList !== null &&
            fieldList.hasOwnProperty('length') &&
            fieldList.length > 0
        );
    }

    useEffect(() => {
        if ( !gettingFields && !fieldsError) {
            const { getPlacesByGrower } = fields;
            const { results } = getPlacesByGrower;
            const sorted = sortCollection(results, { 'fieldName': 'asc' } );
            
            setFieldList(sorted);
        }
    }, [gettingFields, fields, fieldsError]);

    if ( gettingFields ) {
        return(<Spinner align="left">Getting Fields</Spinner>)
    }

    if ( !fieldList || fieldList === null || fieldList.length === 0 ) {
        return(<div>No Fields Found</div>);
    }

    return(
        ( hasAFieldList() ) ? (
            fieldList.map((field, index) =>
                <Card key={index}>
                    <AccordionHeader 
                        eventKey={field.guid} 
                        onClick={() => handleFieldOpen(field.guid)} 
                        isOpen={fieldOpen}
                    >
                        {field.fieldName}
                    </AccordionHeader>
                    <Accordion.Collapse eventKey={field.guid} mountOnEnter unmountOnExit>
                        <Card.Body>
                            <FieldCard 
                                grower={grower} 
                                field={field}
                            />
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            )
        ) : (
            <div>No Fields Found</div>
        )
    );
}

export default GrowerFields;