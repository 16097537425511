import React from 'react';

const ContentHeader = (props) => {
    return(
        <div className="content-header">
            {props.title && props.title !== '' &&
                <h4 className="content-header-title">{props.title}</h4>
            }
            {props.children}
        </div>
    )
}

export default ContentHeader;