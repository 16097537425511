import React from 'react';
import { Row, Col, } from 'react-bootstrap';
import { Icon, } from "../../common/icons";
import PageWrapper from '../../components/PageWrapper';

import './weather.scss';

const WeatherComponent = () => {
    const iconWidth = 72;
    const iconType = 'weather';
    const fillColor = '#333';
    return(
        <PageWrapper className="weather-page-wrapper">
            <div className="content-wrapper">
                <Row>
                    <Col xs={12} sm={12} lg={6}>
                        <Row>
                            <Col xs={12} sm={6} md={3} lg={3}>
                                <Icon 
                                    type={iconType} 
                                    name="temperature" 
                                    title={(
                                        <span>74&deg; F </span>
                                    )}
                                    fill={fillColor} 
                                    width={iconWidth} 
                                />
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={3}>
                                <Icon 
                                    type={iconType} 
                                    name="precipitation" 
                                    title="Rainfall" 
                                    fill={fillColor} 
                                    width={iconWidth} 
                                />
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={3}>
                                <Icon 
                                    type={iconType} 
                                    name="wind" 
                                    title={(
                                        <span>12 MPH E<br />Gust: 20 MPH" </span>
                                    )}
                                    fill={fillColor} 
                                    width={iconWidth} 
                                />
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={3}>
                                <Icon 
                                    type={iconType} 
                                    name="barometer" 
                                    title="Baro Rising" 
                                    fill={fillColor} 
                                    width={iconWidth} 
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={12} lg={6}>
                        <Row>
                            <Col xs={12} sm={6} md={3} lg={3}>
                                <Icon 
                                    type={iconType} 
                                    name="humidity" 
                                    title={(
                                        <span>Humidity: 50%<br />Dew Point: 50&deg;</span>
                                    )} 
                                    fill={fillColor} 
                                    width={iconWidth} 
                                />
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={3}>
                                <Icon 
                                    type={iconType} 
                                    name="sun" 
                                    title={(
                                        <span>Sunrise: 7:15 AM<br />Sunset: 8:23 PM</span>
                                    )}  
                                    fill={fillColor} 
                                    width={iconWidth} 
                                />
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={3}>
                                <Icon 
                                    type={iconType} 
                                    name="uv" 
                                    title="UV Index: 9" 
                                    fill={fillColor} 
                                    width={iconWidth} 
                                />
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={3}>
                                <Icon 
                                    type={iconType} 
                                    name="soil" 
                                    title="Soil Temp: 52&deg;" 
                                    fill={fillColor} 
                                    width={iconWidth} 
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </PageWrapper>
    );
}

export default WeatherComponent;