import React, { useCallback, useEffect, useState } from 'react';
import {
    Accordion,
    Button,
    Card,
    Col,
    Form,
    Row,
    // Table,
} from 'react-bootstrap';
import AccordionHeader from '../../../components/AccordionHeader';
import TribusAverageTrendLineChart from './TribusAverageTrendLineChart';
import SensorReadingChart from './TribusSensorReadingChart';
import {
    EtSummary,
    IrrigationCalculator,
    IrrigationRecommendations,
    IrrigationSpecs
} from '../Cards';
import SettingsModal from '../Charts/SettingsModal';
import Spinner from '../../../common/Spinner';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';

import {
    GET_PLACE_LINK_THING,
} from '../../../GraphQL/Queries/Things';

import {
    GET_TRIBUS_DEVICE_MOISTURE_CHART,
    GET_TRIBUS_DEVICE_AVERAGE_MOISTURE_CHART
} from '../../../GraphQL/Queries/TribusThings';

import { UPDATE_PLACE_LINK_THING } from '../../../GraphQL/Mutations/Things';
import {
    UPDATE_PLACE_LINK_CROP
} from '../../../GraphQL/Mutations/Places';

import '../Charts/charts.scss';


const acreInches = '0.5';
const irrigatedAcres = '125.25';
const wellCapacity = '400';

const calculateDaysToApplyIrrigation = (data) => {
    if (
        data.irrigatedAcres === '0'
        || data.acreInches === '0'
        || data.wellCapacity === '0'
    ) {
        return '0.0';
    }
}

const TribusChartComponent = (props) => {
    const {
        field,
        device,
        refreshDevices,
        cropYear,
    } = props;
    const tribusDeviceType = device.tribusProbeType;
    const { addToast } = useToasts();
    const [selectedChart, setSelectedChart] = useState('sensorReading');
    // const [rerunPreviews, setRerunPreviews] = useState(true);
    const [modalShow, setModalShow] = useState(false);
    const [depthSensors, setDepthSensors] = useState(null);
    const [sensorSeries, setSensorSeries] = useState([]);
    const [averageSeries, setAverageSeries] = useState([]);
    const [specsOpen, setSpecsOpen] = useState(null);
    const [calcOpen, setCalcOpen] = useState(null);
    const [etsOpen, setEtsOpen] = useState(null);
    const [irOpen, setIrOpen] = useState(null);
    const [plt, setPLT] = useState(null);
    const [instantiated, setInstantiated] = useState(false);
    // const [previousMoistureData, setPreviousMoistureData] = useState(null);
    const [updatePlaceLinkCrop] = useMutation(UPDATE_PLACE_LINK_CROP);
    const [updatePlaceLinkThing] = useMutation(UPDATE_PLACE_LINK_THING);

    const [
        getTribusDeviceMoistureChart,
        { called: tribusDeviceMoistureChartCalled, data: tribusDeviceMoistureChartData}
    ] = useLazyQuery(GET_TRIBUS_DEVICE_MOISTURE_CHART);
    const [
        getTribusDeviceAverageMoistureChart,
        { called: tribusDeviceAverageMoistureChartCalled, data: tribusDeviceAverageMoistureChartData, loading: loadingAverageChart}
    ] = useLazyQuery(GET_TRIBUS_DEVICE_AVERAGE_MOISTURE_CHART);

    const {
        loading: gettingPLT,
        error: errorGettingPLT,
        data: pltData,
        refetch: refetchPlaceLinkThing
    } = useQuery(
        GET_PLACE_LINK_THING,
        {
            variables: {
                placeGuid: field.guid,
                thingGuid: device.guid,
                linkGuid: device.links[0]['guid']
            },
            context: { service: 'things' }
        }
    );

    const [calculatorData, setCalculatorData] = useState({
        acreInches,
        daysToApplyIrrigation: calculateDaysToApplyIrrigation({ acreInches, irrigatedAcres, wellCapacity }),
        irrigatedAcres,
        wellCapacity,
    });

    const [tileToggle] = useState({
        etSummary: true,
        irrigationCalculator: false,
        irrigationRecommendations: true,
        irrigationSpecs: false,
    });

    const getSensorReadingData = useCallback(async () => {
        const key = "sample";
        let startDate = new Date('2022/02/01');
        if (cropYear === "2022") {
            switch (device.type) {
                case "Rain Gauge":
                    break;
                case "Pivot Monitor":
                    break;
                default:
                    getTribusDeviceMoistureChart({
                        variables: {
                            key,
                            start: startDate.getTime(),
                            end: new Date().getTime(),
                            sensorSerial: device.sensorSerial,
                            depthMeasurement: device.depthMeasurement
                        },
                        context: {
                            service: 'tribusThings'
                        }
                    });
                    break;
            }
        }

    }, [device, getTribusDeviceMoistureChart, cropYear])

    const handleFieldSettings = React.useCallback(async (data) => {
        const d = JSON.parse(JSON.stringify(data));
        const placeData = { ...d.place };
        const thingData = { ...d.thing };
        const linkData = { ...d.link };
        const cropData = { ...placeData.link }

        delete thingData['__typename'];
        delete placeData['__typename'];
        delete linkData['__typename'];

        const thing = {
            ...thingData,
        };

        delete thing.links;

        const link = {
            ...linkData,
            startDate: Number(linkData.startDate) || 0,
            fullSF: Number(linkData.fullSF),
            growthStage: linkData.growthStage || '',
            lat: Number(linkData.lat) || 0,
            lng: Number(linkData.lng) || 0,
            pinFileName: linkData.pinFileName || '',
            state: linkData.state || '',
            technician: linkData.technician || '',
            wiltSF: Number(linkData.wiltSF),
            oneFtSoilTexture: linkData.oneFtSoilTexture,
            twoFtSoilTexture: linkData.twoFtSoilTexture,
            threeFtSoilTexture: linkData.threeFtSoilTexture,
            probesToAverage: linkData.probesToAverage
        };

        const crop = JSON.parse(JSON.stringify(cropData));

        if (Object.keys(crop).length > 0) {
            const vars = {
                placeGuid: placeData.guid,
                cropGuid: crop.crop.guid,
                link: {
                    plantingDate: Number(crop.plantingDate),
                    currentGrowthStage: crop.currentGrowthStage,
                    maturity: crop.maturity,
                }
            }

            if (crop.guid) {
                vars.link.guid = crop.guid;
                await updatePlaceLinkCrop({
                    variables: vars,
                    context: { service: 'places' }
                });

            } else {
                const response = await updatePlaceLinkCrop({
                    variables: vars,
                    context: { service: 'places' }
                });

                const { data: { updatePlaceLinkCrop: { results } } } = response;

                if (results.length > 0) {
                    const newGuid = results[0];

                    placeData.link.crop.guid = newGuid;
                }
            }

        }

        const place = {
            ...placeData,
            lat: Number(placeData.lat) || 0,
            lng: Number(placeData.lng) || 0,
            pivotPassAmount: Number(placeData.pivotPassAmount) || 0,
            wellCapacity: Number(placeData.wellCapacity) || 0,
            acreInches: Number(placeData.acreInches) || 0,
            soilTexture: placeData.soilTexture || '',

        }

        delete place.link;
        delete place.fieldSize;
        delete place.et;
        delete place.endDate;
        delete place.startDate;
        delete place.soilTexture;
        delete place.link;

        if (!thingData.link) {
            thingData.links = [];
        }

        thingData.links[0] = link;

        const response = await updatePlaceLinkThing(
            {
                variables: {
                    place,
                    link,
                    thing
                },
                context: {
                    service: 'things'
                },
            }
        );

        const { data: { updatePlaceLinkThing: { ok } } } = response;

        // var ok = true;
        if (ok && ok === true) {
            addToast('Settings were successfully updated', {
                appearance: 'success',
                autoDismiss: true,
            });

            place.link = cropData;
            refetchPlaceLinkThing();
            getSensorReadingData();
        } else {
            addToast('There was a problem saving the settings', {
                appearance: 'error',
            });
        }

    }, [updatePlaceLinkThing, updatePlaceLinkCrop, refetchPlaceLinkThing, addToast, getSensorReadingData]);

    const handleDeviceSettings = React.useCallback(async (data) => {
        const d = JSON.parse(JSON.stringify(data));
        const placeData = { ...d.place };
        const thingData = { ...d.thing };
        const linkData = { ...d.link };
        const cropData = { ...placeData.link }

        delete thingData['__typename'];
        delete placeData['__typename'];
        delete linkData['__typename'];

        const thing = {
            ...thingData,
        };

        delete thing.links;

        const link = {
            ...linkData,
            startDate: Number(linkData.startDate) || 0,
            fullSF: Number(linkData.fullSF),
            growthStage: linkData.growthStage || '',
            lat: Number(linkData.lat) || 0,
            lng: Number(linkData.lng) || 0,
            pinFileName: linkData.pinFileName || '',
            state: linkData.state || '',
            technician: linkData.technician || '',
            wiltSF: Number(linkData.wiltSF),
            oneFtSoilTexture: linkData.oneFtSoilTexture,
            twoFtSoilTexture: linkData.twoFtSoilTexture,
            threeFtSoilTexture: linkData.threeFtSoilTexture,
        };

        const crop = JSON.parse(JSON.stringify(cropData));

        if (Object.keys(crop).length > 0) {
            const vars = {
                placeGuid: placeData.guid,
                cropGuid: crop.crop.guid,
                link: {
                    plantingDate: Number(crop.plantingDate),
                    currentGrowthStage: crop.currentGrowthStage,
                    maturity: crop.maturity,
                }
            }

            if (crop.guid) {
                vars.link.guid = crop.guid;
                await updatePlaceLinkCrop({
                    variables: vars,
                    context: { service: 'places' }
                });

            } else {
                const response = await updatePlaceLinkCrop({
                    variables: vars,
                    context: { service: 'places' }
                });

                const { data: { updatePlaceLinkCrop: { results } } } = response;

                if (results.length > 0) {
                    const newGuid = results[0];

                    placeData.link.crop.guid = newGuid;
                }
            }

        }

        const place = {
            ...placeData,
            lat: Number(placeData.lat) || 0,
            lng: Number(placeData.lng) || 0,
            pivotPassAmount: Number(placeData.pivotPassAmount) || 0,
            wellCapacity: Number(placeData.wellCapacity) || 0,
            acreInches: Number(placeData.acreInches) || 0,
        }

        delete place.link;
        delete place.link;
        delete place.fieldSize;
        delete place.et;
        delete place.wellCapacity;
        delete place.endDate;
        delete place.startDate;

        if (!thingData.link) {
            thingData.links = [];
        }

        thingData.links[0] = link;

        const response = await updatePlaceLinkThing(
            {
                variables: {
                    place,
                    link,
                    thing
                },
                context: {
                    service: 'things'
                },
            }
        );

        const { data: { updatePlaceLinkThing: { ok } } } = response;

        // var ok = true;
        if (ok && ok === true) {
            addToast('Settings were successfully updated', {
                appearance: 'success',
                autoDismiss: true,
            });

            place.link = cropData;
            refreshDevices();
            refetchPlaceLinkThing();
            getSensorReadingData();
        } else {
            addToast('There was a problem saving the settings', {
                appearance: 'error',
            });
        }
    }, [updatePlaceLinkThing, updatePlaceLinkCrop, refetchPlaceLinkThing, addToast, getSensorReadingData, refreshDevices])

    const handleChartSelect = (event) => {
        const value = event.target.value;
        setSelectedChart(value);
    }

    const handleEtsOpen = (card) => {
        if (card === null || etsOpen !== null) {
            setEtsOpen(null);
        } else {
            const cardName = card + '-' + field.guid.toString();
            setEtsOpen(cardName);
        }

        setIrOpen(null);
        setSpecsOpen(null);
        setCalcOpen(null);
    }

    const handleIrOpen = (card) => {
        if (card === null || irOpen !== null) {
            setIrOpen(null);
        } else {
            const cardName = card + '-' + field.guid.toString();
            setIrOpen(cardName);
        }

        setSpecsOpen(null);
        setCalcOpen(null);
    }

    const handleSpecsOpen = (card) => {
        if (card === null || specsOpen !== null) {
            setSpecsOpen(null);
        } else {
            const cardName = card + '-' + field.guid.toString();
            setSpecsOpen(cardName);
        }
        setIrOpen(null);
        setCalcOpen(null);

    }

    const handleCalcOpen = (card) => {
        if (card === null || calcOpen !== null) {
            setCalcOpen(null);
        } else {
            const cardName = card + '-' + field.guid.toString();
            setCalcOpen(cardName);
        }
        setIrOpen(null);

    }

    const handleCalculatorInputChange = (e, inputName) => {
        const { value } = e.target;
        const number = value.replace(/[^0-9.]/g, '');

        const updatedCalculatorData = {
            ...calculatorData,
            [inputName]: number,
        };

        const daysToApplyIrrigation = calculateDaysToApplyIrrigation(updatedCalculatorData);

        setCalculatorData({
            ...updatedCalculatorData,
            daysToApplyIrrigation,
        });
    }


    const getAverageData = (depths, rerun) => {
        const key = "sample";
        const d = new Date();
        const year = d.getFullYear();
        // console.log("DEVICE", device);
        const startDate = new Date(year + '/01/01');
        let probesToAverage = depths || [];
        // console.log("DEPTHS", depths);
        // setRerunPreviews(rerun || false);
        if (device && device.links && device.links[0].probesToAverage) {
            probesToAverage = device.links[0].probesToAverage
        }

        if (probesToAverage === null) {
            probesToAverage = [];
        }
        getTribusDeviceAverageMoistureChart({
            variables: {
                key,
                start: startDate.getTime(),
                end: new Date().getTime(),
                sensorSerial: device.sensorSerial,
                depthMeasurement: device.depthMeasurement,
                probesToAverage: probesToAverage
            },
            context: { service: 'tribusThings' }
        });
    }

    const getAndStoreDepthSensors = (data) => {
        const sensorList = [];
        // console.log("DATA", data);
        for (let i = 0; i < data.length; i++) {
            const name = parseInt(data[i]['name']);
            if (!isNaN(name)) {
                sensorList.push(parseInt(name));
            }
        }
        setDepthSensors(sensorList);
    }
    // PLT Set
    useEffect(() => {
        if (!gettingPLT && !errorGettingPLT) {
            const { getPlaceLinkThing: { results } } = pltData;

            if (results.length > 0) {
                const row = results[0];

                setPLT({
                    thing: row.thing,
                    place: row.place,
                    link: row.link
                });
            }
        }
    }, [gettingPLT, errorGettingPLT, pltData]);

    // Device Moisture Chart Set
    useEffect(() => {
        if (tribusDeviceMoistureChartCalled && tribusDeviceMoistureChartData) {
            const { getTribusDeviceMoistureChart } = tribusDeviceMoistureChartData;
            const result = getTribusDeviceMoistureChart || undefined;
            const depthToSave = [];

            if (result && result !== null) {
                const parsed = JSON.parse(result);
                parsed.forEach((mdepth, index) => {
                    const name = mdepth.name
                    if (!name.includes("temp")) {
                        depthToSave.push(mdepth)
                    }
                })
                getAndStoreDepthSensors(depthToSave);
                setSensorSeries([...parsed]);
            } else if (result === null || !result) {
                setSensorSeries([]);
            }
        }
    }, [tribusDeviceMoistureChartCalled, tribusDeviceMoistureChartData]);

    // Average Moisture Chart Set
    useEffect(() => {
        if (tribusDeviceAverageMoistureChartCalled && tribusDeviceAverageMoistureChartData) {
            // if (!previousMoistureData) {
            //     setPreviousMoistureData(tribusDeviceAverageMoistureChartData);
            // }
            // console.log("average called");
            const { getTribusDeviceAverageMoistureChart } = tribusDeviceAverageMoistureChartData;
            // console.log(tribusDeviceAverageMoistureChartData);
            const aResult = getTribusDeviceAverageMoistureChart || undefined;

            if (aResult) {
                const aParsed = JSON.parse(aResult);
                setAverageSeries(JSON.parse(aParsed));
                // if (rerunPreviews) {
                //     setRerunPreviews(false);
                // }
            }
        }
    }, [tribusDeviceAverageMoistureChartCalled, tribusDeviceAverageMoistureChartData]);

    //
    // Listen for Selected Chart to change
    useEffect(() => {
        if (selectedChart !== null & selectedChart !== '' && (selectedChart !== 'rain' && selectedChart !== 'pivot')) {
            if (selectedChart.toLowerCase().indexOf('sensor') > -1) {
                getSensorReadingData();
            } else {
                if (!instantiated) {
                    setInstantiated(true);
                    getAverageData();
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedChart]);


    if (selectedChart === null) {
        return (null);
    }



    return (
        <>
            <Row>
                <Col xs={12} md={4}>
                    <Form.Group controlId="exampleForm.ControlSelect2">
                        {selectedChart !== "rain" && selectedChart !== "chart" &&
                            <Form.Control
                                as="select"
                                onChange={e => handleChartSelect(e)}
                                value={selectedChart || ''}
                            >
                                {(tribusDeviceType.toLowerCase().indexOf('greenshield') > -1) &&
                                    <option value="sensorReading">Raw Sensor Reading (Volumetric % / each sensor)</option>
                                }
                                {(tribusDeviceType.toLowerCase().indexOf('aquacheck') > -1) &&
                                    <option value="sensorReading">Raw Sensor Reading (Standard % / each sensor)</option>
                                }
                                {(tribusDeviceType.toLowerCase().indexOf('hydrawize') > -1) &&
                                    <option value="sensorReading">Raw Sensor Reading (Standard % / each sensor)</option>
                                }
                                <option value="avgTrendline">Average Trend Line</option>
                            </Form.Control>
                        }
                    </Form.Group>
                </Col>
                <Col xs={12} md={8} className="align-right">
                    {selectedChart === 'sensorReading' ? (
                        <Button
                            variant="primary"
                            size="small"
                            onClick={() => setModalShow(true)}
                        >
                            Device Settings
                        </Button>
                    ) : (null)}
                </Col>

            </Row>
            <Row>
                <Col xs={12}>
                    <div className="chart-outer-wrapper">
                        {(selectedChart === 'sensorReading' || selectedChart === 'rain' || selectedChart === 'pivot') ? (
                            (tribusDeviceMoistureChartCalled && sensorSeries.length === 0) ? (
                                <div className="chart-spinner"><Spinner>...Getting Chart Data</Spinner></div>
                            ) : (
                                !tribusDeviceMoistureChartCalled && sensorSeries.length === 0  ? (
                                    <div className="chart-spinner"><p>No results returned</p></div>
                                ) : (
                                    <SensorReadingChart
                                        bindTo={`chart-${field.guid}`}
                                        deviceType={tribusDeviceType}
                                        series={sensorSeries}
                                        plt={plt}
                                    />
                                )
                            )
                        ) : (
                            (averageSeries === null || loadingAverageChart) ? (
                                <div className="chart-spinner"><Spinner>...Getting Chart Data</Spinner></div>
                            ) : (
                                <TribusAverageTrendLineChart
                                    bindTo={`chart-${field.guid}`}
                                    device={device}
                                    deviceType={tribusDeviceType}
                                    series={averageSeries}
                                    depthSensors={depthSensors}
                                    handleFieldSettings={handleFieldSettings}
                                    getAverageData={getAverageData}
                                    plt={plt}
                                    refreshDevices={refreshDevices}
                                />
                            )
                        )}
                    </div>
                </Col>
            </Row>
            {
                selectedChart === 'sensorReading' &&
                <Card className="soil-moisture-accordion-card">
                    <Accordion activeKey={etsOpen}>
                        <AccordionHeader
                            eventKey={'ets-' + field.guid}
                            onClick={() => handleEtsOpen('ets')}
                            isOpen={etsOpen}
                            title="ET Summary"
                        />
                        <Accordion.Collapse eventKey={'ets-' + field.guid} mountOnEnter unmountOnExit>
                            <Card.Body>
                                <EtSummary plt={plt} />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion activeKey={irOpen}>
                        <AccordionHeader
                            eventKey={'ir-' + field.guid}
                            onClick={() => handleIrOpen('ir')}
                            isOpen={irOpen}
                            title="Irrigation Recommendations"
                        />
                        <Accordion.Collapse eventKey={'ir-' + field.guid} mountOnEnter unmountOnExit>
                            <Card.Body>
                                <IrrigationRecommendations
                                    acreInches={calculatorData.acreInches}
                                    deviceType={device.type}
                                    fieldPreview={device.links[0]}
                                    handleCalculatorInputChange={handleCalculatorInputChange}
                                    plt={plt}
                                    visible={tileToggle.irrigationRecommendations}
                                />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion activeKey={specsOpen}>
                        <AccordionHeader
                            eventKey={'irs-' + field.guid}
                            onClick={() => handleSpecsOpen('irs')}
                            isOpen={specsOpen}
                            title="Irrigation Specs"
                        />
                        <Accordion.Collapse eventKey={'irs-' + field.guid} mountOnEnter unmountOnExit>
                            <Card.Body>
                                <IrrigationSpecs
                                    deviceType={tribusDeviceType}
                                    plt={plt}
                                />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion activeKey={calcOpen}>
                        <AccordionHeader
                            eventKey={'irc-' + field.guid}
                            onClick={() => handleCalcOpen('irc')}
                            isOpen={calcOpen}
                            title="Irrigation Calculator"
                        />
                        <Accordion.Collapse eventKey={'irc-' + field.guid} mountOnEnter unmountOnExit>
                            <Card.Body>
                                <IrrigationCalculator
                                    handleFieldSettings={handleFieldSettings}
                                    plt={plt}
                                />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Accordion>
                </Card>
            }
            <SettingsModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                handleDeviceSettings={handleDeviceSettings}
                plt={plt}
            />
        </>
    );
};

export default TribusChartComponent;
