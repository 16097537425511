import { gql } from '@apollo/client';

export const GET_DEVICE_MOISTURE_CHART = gql`
    query GetDeviceMoistureChart( $deviceGuid: ID!, $start: Float, $end: Float ) {
        getDeviceMoistureChart( deviceGuid: $deviceGuid, start: $start, end: $end ) {
            ok
            results
        }
    }
`;

export const GET_DEVICE_RAIN_CHART = gql`
    query GetDeviceRainChart( $deviceGuid: ID!, $start: Float, $end: Float ) {
        getDeviceRainChart( deviceGuid: $deviceGuid, start: $start, end: $end ) {
            ok
            results
        }
    }
`;

export const GET_PIVOT_CHART = gql`
    query getPivotChart( $deviceGuid: ID!) {
        getPivotChart( deviceGuid: $deviceGuid) {
            ok
            results{
                highChartData
                status{
                    currentBearing
                    currentCompass
                    currentStatus
                    previousBearing
                    previousCompass
                    previousStatus
                }
                pivotTotals{
                    epoch
                    event
                    dateTime
                    hoursOff
                    hoursForward
                    hoursReverse
                }
            }
        }
    }
`;

export const GET_DEVICE_MOISTURE_AVERAGE_CHART = gql`
    query GetDeviceMoistureChartAverage(
            $deviceGuid: ID!, 
            $start: Float, 
            $end: Float, 
            $probesToAverage: [Int]!
        ) {
            getDeviceMoistureChartAverage(
                deviceGuid: $deviceGuid, 
                start: $start, 
                end: $end, 
                probesToAverage: $probesToAverage
            ) {   
                ok
                results
            }
    }
`;

export const GET_RECOMMENDATIONS_BY_PLACE = gql`
    query GetRecommendationsByPlace($guid: ID!) {
        getRecommendationsByPlace(guid: $guid) {
            ok
            results{
                guid
                recommendation
                dateCreated
                dateUpdated
                authorGuid
                authorName
            }
        }
    }
`;

export const GET_NOTES_BY_PLACE = gql`
    query GetNotesByPlace($guid: ID!) {
        getNotesByPlace(guid: $guid) {
            results{
                guid
                message
                dateCreated
                dateUpdated
                authorGuid
                authorName
            }
        }
    }
`;

export const GET_PLANT_AVAILABLE_WATER_CHART = gql`
    query GetPlantAvailableWaterChart($deviceGuid: ID!) {
        getPlantAvailableWaterChart(deviceGuid: $deviceGuid) {
            ok
            results{
                depth
                waterDepleted
                paw
                raw
                soilTexture
                sensorReading
            }
        }
    }
`;

export const GET_FIELD_SENSOR_PREVIEW = gql`
    query GetFieldSensorPreview($placeGuid: ID!) {
        getFieldSensorPreview(placeGuid: $placeGuid) {
            ok
            results {
                rain24h
                stage
                crop
                et
                devices{
                    deviceGuid
                    svgGauge,
                    gaugeValue
                    probesToAverage
                    state
                }
            }
        }
    }
`;

export const GET_FIELD_SENSOR_PREVIEWS = gql`
    query GetFieldSensorPreviews( $placeGuids: [ID!]) {
        getFieldSensorPreviews( placeGuids: $placeGuids) {
            ok
            results{
                rain24h
                stage
                et
                fieldGuid
                devices{
                    deviceGuid
                    svgGauge,
                    gaugeValue
                    probesToAverage
                    state
                }
            }
        }
    }
`;

export const GET_DEVICE_MOISTURE_GAUGE = gql`
    query GetDeviceMoistureGauge( $deviceGuid: [ID!]) {
        getDeviceMoistureGauge( deviceGuid: $deviceGuid) {
            ok
            results{
                deviceGuid
                deveui
                type
                svgGauge
                probesToAverage
                wiltSF
                fullSF
                gaugeValue
                pivot
            }
        }
    }
`;