import React, { useState, useEffect } from 'react';
import { Card, } from 'react-bootstrap';
import { useQuery, useLazyQuery } from "@apollo/client";
import Spinner from '../../common/Spinner';
import { sortCollection } from '../../services/Data';
import FieldCard from './FieldCard';

import { GET_GROWER_FIELDS } from '../../GraphQL/Queries/User';
import { GET_FIELD_SENSOR_PREVIEW } from '../../GraphQL/Queries/Things';

const FieldListCard = (props) => {
    const { grower, guid, handleFieldOpen, fieldOpen, } = props;
    const [fieldList, setFieldList] = useState(null);

    const [guidArray, setGuidArray] = useState(null);

    const [
        {

            loading: gaugeLoading,
            called: gaugeCalled,
        }] = useLazyQuery(
            GET_FIELD_SENSOR_PREVIEW, { fetchPolicy: 'network-only' }
        );

    const {
        loading: gettingFields,
        error: errorGettingFields,
        data: fieldData,
        // refetch: refetchFields
    } = useQuery(
        GET_GROWER_FIELDS,
        {
            variables: { guid: guid || grower.guid },
            context: { service: 'places' }
        }
    );

    useEffect(() => {
        if (!gettingFields && !errorGettingFields) {
            const { getPlacesByGrower } = fieldData;
            const { results } = getPlacesByGrower;
                const sorted = sortCollection(results, { 'fieldName': 'asc' });
                setFieldList(sorted);


                const thisGuidArray = [];
                for (let i = 0; i < sorted.length; i++) {
                    const g = sorted[i].guid;
                    if (thisGuidArray.indexOf(g) < 0) {
                        thisGuidArray.push(g);
                    }
                }

                if (thisGuidArray.length > 0) {
                    setGuidArray(thisGuidArray);
                    // lazyPreview({
                    //     variables: { placeGuids: thisGuidArray },
                    //     context: { service: 'things' }
                    // });
                }
        }
    }, [gettingFields, errorGettingFields, fieldData])

    if (gettingFields) {
        return (<Spinner align="left">Getting Fields</Spinner>)
    }

    if (!fieldList || fieldList === null || fieldList.length === 0) {
        return (<div>No Fields Found</div>);
    }

    return (
        fieldList && fieldList.map((field, idx) =>
            <Card key={idx}>
                <FieldCard
                    field={field}
                    fieldOpen={fieldOpen}
                    grower={grower}
                    handleFieldOpen={handleFieldOpen}
                    gaugeCalled={gaugeCalled}
                    gaugeLoading={gaugeLoading}
                    guidArray={guidArray}
                    // handleRefetchField={refetchFields}
                />
            </Card>
        )
    );

}

export default FieldListCard;