import { gql } from '@apollo/client';

export const UPDATE_THING = gql`
    mutation UpdateThing($guid: ID!, $thing: InputThing) {
        updateThing(guid: $guid, thing: $thing) {
            ok
            message
        }
    }
`;

export const ADD_THING = gql`
    mutation AddThing($thing: InputThing) {
        addThing(thing: $thing) {
            ok
            message
        }
    }
`;

export const REPLACE_OLD_THING_WITH_NEW_THING = gql`
    mutation replaceOldThingWithNewThing($growerName: String, $fieldName: String, $oldDeveui: Int, $newDeveui: Int) {
            replaceOldThingWithNewThing(
              growerName:$growerName
              fieldName:$fieldName
              oldDeveui:$oldDeveui
              newDeveui:$newDeveui
            ){ ok success}
          
    }
`;

export const SAVE_GATEWAY = gql`
    mutation saveGateway($thing: InputThing) {
            saveGateway(
              thing:$thing
            ){ ok success}
          
    }
`;
export const ADD_DEVICE_LINK = gql`
    mutation AddDeviceLink(
        $deveui: Int!,
        $lineItemGuid: ID!, 
        $link: InputLink!, 
        $orderGuid: ID,
        $placeGuid: ID!
    ) {
        addDeviceLink(
            deveui: $deveui,
            lineItemGuid: $lineItemGuid, 
            link: $link, 
            orderGuid: $orderGuid,
            placeGuid: $placeGuid
        ) {
            ok
            success
            message
        }
    }
`;

export const ADD_DEVICE_LINK_OR_SAVE_LINE_ITEM = gql`
    mutation AddDeviceLinkOrSaveLineItem(
        $deveui: Int,
        $oldDeveui: Int,
        $lineItemGuid: ID, 
        $link: InputLink, 
        $orderGuid: ID,
        $placeGuid: ID
    ) {
        addDeviceLinkOrSaveLineItem(
            deveui: $deveui,
            oldDeveui: $oldDeveui
            lineItemGuid: $lineItemGuid, 
            link: $link, 
            orderGuid: $orderGuid,
            placeGuid: $placeGuid
        ) {
            ok
            success
            message
        }
    }
`;

export const SAVE_THING = gql`
    mutation SaveThing($deveui: Int, $oldDeveui: Int, $link: InputLink, $placeGuid: ID, $lineItemGuid: ID, $orderGuid: ID, $newDevice: Boolean, $unassociated: Boolean, $tribusProbeType: String, $sensorSerial: String, $depthMeasurement: String ){
        saveThing(deveui: $deveui, oldDeveui: $oldDeveui, link:$link, placeGuid: $placeGuid, lineItemGuid: $lineItemGuid, orderGuid: $orderGuid, newDevice: $newDevice, unassociated: $unassociated, tribusProbeType:$tribusProbeType, sensorSerial:$sensorSerial, depthMeasurement:$depthMeasurement){
            ok
            success
            results {
                guid
                deviceName
                title
                deveui
                type
                deviceNotes
                fieldAccessNotes
                gatewayNeeded
                technician {
                    guid
                    firstName
                    lastName
                    name
                    limsId
                    fieldXGrowerId
                    isManager
                    isAgronomist
                    isGrower
                    technician
                    agronomist
                  }
                links {
                    guid
                    startDate
                    endDate
                    wiltSF
                    fullSF
                    avgWiltSF
                    avgFullSF
                    oneFtSoilTexture
                    twoFtSoilTexture
                    threeFtSoilTexture
                    lat
                    lng
                    technician
                    state
                    pinFileName
                    cropGuid
                    cropName
                    growthStage
                    complete
                    itemGuid
                    gatewayNeeded
                    deviceName
                    deviceNotes
                    fieldAccessNotes
                    maintenanceNotes
                    textureNeeded
                    textureReceived
                }
                lat
                lng
                isGateway
                isWatermark
                isPivot
                isRainGauge
                isWeatherStation
                isCapacitance
                tribusProbeType
                sensorSerial
                depthMeasurement
            }
        }
    }
`;
export const SAVE_LINE_ITEM = gql`
    mutation SaveLineItem($lineItem: InputLineItem){
        saveLineItem(lineItem: $lineItem){
            ok
            success
            results {
                guid
                itemGuid
                name
                imageFileName
                summary
                price
                sellingUnit
                minimumQuantity
                pinFileName
                externalData
                bridgeFeaturesDelivered
                gatewayNeeded
                deviceNameNeeded
                lat
                lng
                deviceName
                deviceNotes
                fieldAccessNotes
                deviceType
                complete
            }
        }
    }
`;
export const EDIT_DEVICE_LINK = gql`
    mutation EditDeviceLink($link: InputLink) {
        editDeviceLink(link: $link) {
            ok
            message
        }
    }
`;

export const UPDATE_PLACE_LINK_THING = gql`
    mutation UpdatePlaceLinkThing($place: InputPlace!, $link: InputLink, $thing: InputThing!) {
        updatePlaceLinkThing(
            place: $place,
            link: $link,
            thing: $thing
        ) {
            ok
            message
            success
        }
    }
`;

export const ADD_IRRIGATION_RECOMMENDATION = gql`
    mutation AddIrrigationRecommendation($fieldId: ID!, $recommendation: InputRecommendation!) {
        addIrrigationRecommendation(fieldId: $fieldId, recommendation: $recommendation) {
            ok
            results{
                guid
                recommendation
            }
        }
    }
`;

export const UPDATE_IRRIGATION_RECOMMENDATION = gql`
    mutation UpdateIrrigationRecommendation($fieldId: ID!, $recommendation: InputRecommendation!) {
        updateIrrigationRecommendation(fieldId: $fieldId, recommendation: $recommendation) {
            ok
            results{
                guid
                recommendation
            }
        }
    }
`;

export const DELETE_IRRIGATION_RECOMMENDATION = gql`
    mutation DeleteIrrigationRecommendation($guid: ID!) {
        deleteIrrigationRecommendation(guid: $guid) {
            ok
        }
    }
`;

export const ADD_IRRIGATION_NOTE = gql`
    mutation AddIrrigationNote($fieldId: ID!, $note: InputNote!) {
        addIrrigationNote(fieldId: $fieldId, note: $note) {
            ok
            results{
                guid
                message
            }
        }
    }
`;

export const UPDATE_IRRIGATION_NOTE = gql`
    mutation updateIrrigationNote($fieldId: ID!, $note: InputNote!) {
        updateIrrigationNote(fieldId: $fieldId, note: $note) {
            ok
            results{
                guid
                message
            }
        }
    }
`;

export const DELETE_IRRIGATION_NOTE = gql`
    mutation deleteIrrigationNote($guid: ID!) {
        deleteIrrigationNote(guid: $guid) {
            ok
        }
    }
`;

export const UPDATE_PROBE_DEPTHS = gql`
    mutation updateProbeDepths($guid: ID!, $probesToAverage: [Int]) {
        updateProbeDepths(guid: $guid, probesToAverage: $probesToAverage) {
            ok
            success
        }
    }
`;

export const SAVE_RELATIONSHIP_STATE = gql`
    mutation saveRelationshipState($guid: ID!, $state: String!) {
        saveRelationshipState(guid: $guid, state: $state) {
            ok
            success
        }
    }
`;

export const SAVE_LINEITEM_STATE = gql`
    mutation saveLineItemState($guid: ID!, $state: String!) {
        saveLineItemState(guid: $guid, state: $state) {
            ok
            success
        }
    }
`;
export const SAVE_GATEWAY_STATE = gql`
    mutation saveGatewayState($deveui: Int!, $state: String!) {
        saveGatewayState(deveui: $deveui, state: $state) {
            ok
            success
        }
    }
`;

export const CALL_SONALERT = gql`
    mutation sonalert($deveui: Int!) {
        sonalert(deveui: $deveui) {
            ok
            success
            message
        }
    }
`;
