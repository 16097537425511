const dev = {
    domain: 'https://client-staging-bjudfyf5za-uc.a.run.app',
    fetchUrl: 'https://gql-bridge-staging-bjudfyf5za-uc.a.run.app/refresh_token',
    googleClientId: '495656309447-a5mtrmd8cojq90qo0j7j5eh6q3ig1p6n.apps.googleusercontent.com',
    uri: 'https://gql-bridge-staging-bjudfyf5za-uc.a.run.app/graphql',
    
    itemsURI  :'https://items-staging-bjudfyf5za-uc.a.run.app/graphql',
    // itemsURI  :'http://localhost:4001/graphql',
    loginURI  :'https://gql-bridge-staging-bjudfyf5za-uc.a.run.app/graphql',
    peopleURI : 'https://people-staging-bjudfyf5za-uc.a.run.app/graphql',
    // peopleURI : 'http://localhost:4000/graphql',
    placesURI : 'https://places-staging-bjudfyf5za-uc.a.run.app/graphql',
    // placesURI :'http://localhost:4002/graphql',
    // thingsURI  :'http://localhost:4004/graphql',
    thingsURI  :'https://iot-staging-bjudfyf5za-uc.a.run.app/graphql',
    // thingsURI  :'http://localhost:4001/graphql',
    cropsURI  :'https://crops-staging-bjudfyf5za-uc.a.run.app/graphql',    
    ordersURI : 'https://orders-staging-bjudfyf5za-uc.a.run.app/graphql',
    // ordersURI: 'http://localhost:4001/graphql',
    pastdataURI: 'https://iot-data-gql-staging-bjudfyf5za-uc.a.run.app/',
    tribusThingsURI: 'https://tribus-redis-data-staging-bjudfyf5za-uc.a.run.app/graphql',
};
const stage = {
    domain: 'https://client-staging-bjudfyf5za-uc.a.run.app',
    fetchUrl: 'https://gql-bridge-staging-bjudfyf5za-uc.a.run.app/refresh_token',
    googleClientId: '495656309447-a5mtrmd8cojq90qo0j7j5eh6q3ig1p6n.apps.googleusercontent.com',
    uri: 'https://gql-bridge-staging-bjudfyf5za-uc.a.run.app/graphql',
    
    itemsURI  :'https://items-staging-bjudfyf5za-uc.a.run.app/graphql',
    loginURI  :'https://gql-bridge-staging-bjudfyf5za-uc.a.run.app/graphql',
    peopleURI : 'https://people-staging-bjudfyf5za-uc.a.run.app/graphql',
    placesURI : 'https://places-staging-bjudfyf5za-uc.a.run.app/graphql',
    thingsURI  :'https://iot-staging-bjudfyf5za-uc.a.run.app/graphql',
    cropsURI  :'https://crops-staging-bjudfyf5za-uc.a.run.app/graphql',    
    ordersURI : 'https://orders-staging-bjudfyf5za-uc.a.run.app/graphql',
    pastdataURI: 'https://iot-data-gql-staging-bjudfyf5za-uc.a.run.app/',
    tribusThingsURI: 'https://tribus-redis-data-staging-bjudfyf5za-uc.a.run.app/graphql',
};
const prod = {
    domain: 'https://client-staging-bjudfyf5za-uc.a.run.app',
    fetchUrl: 'https://gql-bridge-staging-bjudfyf5za-uc.a.run.app/refresh_token',
    googleClientId: '495656309447-a5mtrmd8cojq90qo0j7j5eh6q3ig1p6n.apps.googleusercontent.com',
    uri: 'https://gql-bridge-staging-bjudfyf5za-uc.a.run.app/graphql',
    
    itemsURI  :'https://items-staging-bjudfyf5za-uc.a.run.app/graphql',
    loginURI  :'https://gql-bridge-staging-bjudfyf5za-uc.a.run.app/graphql',
    peopleURI : 'https://people-staging-bjudfyf5za-uc.a.run.app/graphql',
    placesURI : 'https://places-staging-bjudfyf5za-uc.a.run.app/graphql',
    thingsURI  :'https://iot-staging-bjudfyf5za-uc.a.run.app/graphql',
    cropsURI  :'https://crops-staging-bjudfyf5za-uc.a.run.app/graphql',    
    ordersURI : 'https://orders-staging-bjudfyf5za-uc.a.run.app/graphql',
    pastdataURI: 'https://iot-data-gql-staging-bjudfyf5za-uc.a.run.app/',
    tribusThingsURI: 'https://tribus-redis-data-staging-bjudfyf5za-uc.a.run.app/graphql',
};

let config = dev;
switch (process.env.REACT_APP_STAGE) {
    case 'prod':
    case 'production':
        config = prod
        break;

    case 'stage':
    case 'staging':
        config = stage
        break;

    default:
        break;
}

export const tokenName = 'token';
export const useTestToken = false;
export const jwtTokenKey = 'qwertyuiopasdfghjklzxcvbnm123456';
export const testToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE2MTY2MDg3ODMsImV4cCI6MTY0ODE0NDc5OCwiYXVkIjoibG9jYWxob3N0Iiwic3ViIjoibWFyay5tb3J0ZW5Ac2Vydml0ZWNoLmNvbSIsImZpcnN0TmFtZSI6Ik1hcmsiLCJsYXN0TmFtZSI6Ik1vcnRlbiIsIkVtYWlsIjoibWFyay5tb3J0ZW5Ac2Vydml0ZWNoLmNvbSIsImd1aWQiOiI3ZDk0MTA5Zi05ODM4LTRiYzAtYWQxZi0yMmNhZmJhMWFkMjkifQ.4E7SrFWh550d-Vv1Sz038Lzy5cwKx-d9_d_g9_tWsOE';

export const GMAPS_API_KEY = 'AIzaSyCGHB6uBAyY_R5sAqtdJajsnqTu1g-HGoI';

export default {
    pageSize: 20,
    jwtTokenKey,
    testToken,
    ...config,
};
