import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import './topNav.scss';

const tabs = [
    {
        route: '',
        label: 'Soil Moisture',
    }, 
    // {
    //     route: 'imagery',
    //     label: 'Imagery',
    // },
    // {
    //     route: 'maps',
    //     label: 'Maps',
    // }, 
    // {
    //     route: 'weather',
    //     label: 'Weather',
    // }, 
    // {
    //     route: 'reports',
    //     label: 'Reports',
    // }, 
    // {
    //     route: 'recommendations',
    //     label: 'Recommendations',
    // }, 
    {
        route: 'orders',
        label: 'Orders',
    }, 
    // {
    //     route: 'files',
    //     label: 'Files',
    // }, 
    // {
    //     route: 'devices',
    //     label: 'Devices',
    // }, 
    // {
    //     route: 'dashboard',
    //     label: 'Dashboard',
    // }    
];

class TopNav extends React.Component {
    state = {
        activeTab: '',
    }

    constructor(props) {
        super(props);

        const { match } = props;
        const { path } = match;

        this.state = {
            activeTab: path.replace(/\//ig, ''),
        }
    }

    linkClass = (tab) => {
        const { activeTab } = this.state;
        return (activeTab === tab.route) ? 'selected' : ''
    }

    render() {

        return(
            <div className="action-pills bridge-nav">
                {tabs.map((tab, index) => (
                    <Link to={'/' + tab.route} key={index} className={this.linkClass(tab)}>
                        <div>{tab.label}</div>
                    </Link>
                ))}
            </div>
        )
    }
}

export default withRouter(TopNav);