import React, { useCallback, useEffect, useState } from "react";
import HighStocks from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { Button, Col, Form, Row } from 'react-bootstrap';
import { CheckSquare, Square } from 'react-feather';
import { useMutation } from '@apollo/client';
import { UPDATE_PROBE_DEPTHS } from '../../../GraphQL/Mutations/Things';
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";

exporting(HighStocks);
exportData(HighStocks);

const defaultChartData = {
    exporting: {
        fallbackToExportServer: true,
        scale: 3,
        buttons: {
            contextButton: {
                menuItems: [
                    'downloadPNG',
                    'downloadJPEG',
                    'downloadPDF',
                    'downloadCSV',
                    'downloadXLS'
                ]
            }
        },
        csv: {
            itemDelimiter: ` ",`
        }
    },
    chart: {
        type: 'spline'
    },
    legend: {
        enabled: true
    },
    rangeSelector: {
        buttons: [{
            type: 'day',
            count: 1,
            text: '1 Day'
        }, {
            type: 'week',
            count: 1,
            text: '1 Week'
        }, {
            type: 'week',
            count: 2,
            text: '2 Weeks'
        }, {
            type: 'month',
            count: 1,
            text: '1 Month'
        }, {
            type: 'year',
            count: 1,
            text: 'Year'
        }, {
            type: 'all',
            text: 'All'
        }, {
            type: 'ytd',
            text: 'YTD'
        }],
        buttonTheme: {
            width: 60
        },
        selected: 2
    },
    plotOptions: {
        series: {
            marker: {
                enabled: false
            }
        }
    },
    series: [],
    title: {
        text: 'Average Trend Line'
    },
    tooltip: {
        shared: true,
        crosshairs: true,
        useHTML: true,
        backgroundColor: null,
        borderWidth: 0,
        shadow: false,
        formatter: function () {
            const d = new Date(this.x);
            const m = d.getMonth() + 1;
            const dd = d.getDate();
            const y = d.getFullYear();
            let h = d.getHours();
            let mm = d.getMinutes();
            let ss = d.getSeconds();
            let amPm = 'AM'

            if (h > 12) {
                h = h - 12;
                amPm = 'PM';
            }

            if (mm < 10) {
                mm = '0' + mm;
            }

            if (ss < 10) {
                ss = '0' + ss;
            }

            const dFormat = m + '/' + dd + '/' + y + ' ' + h + ':' + mm + ':' + ss + amPm;

            let yLines = '';

            for (let i = 0; i < this.points.length; i++) {
                const point = this.points[i];
                const { color, y, series } = point;
                yLines += '<span class="tooltip-info-line">'
                    + '<span class="tooltip-info-axis">'
                    + '<span class="tooltip-info-color" style="background-color: ' + color + ';">&nbsp;</span>'
                    + '<span class="tooltip-info-name">' + series['name'] + '</span>'
                    + '</span>'
                    + '<span class="tooltip-info-text">'
                    + Number(y).toFixed(2)
                    + '</span>'
                    + '</span>';
            }

            return '<span class="chart-tooltip">'
                + '<span class="tooltip-date">'
                + dFormat
                + '</span>'
                + '<span class="tooltip-info">'
                + yLines
                + '</span>'
                + '</span>';
        }
    },
    xAxis: {
        type: 'datetime',
        startOnTick: true,
        endOnTick: true,
        labels: {
            formatter: function () {
                const d = new Date(this.value);
                const m = d.getMonth() + 1;
                const dd = d.getDate();
                // const y = d.getFullYear();
                return m + '/' + dd;
            }
        }
    },
    yAxis: [{
        title: {
            text: 'Average Plant Available Water (% PAW)',
            enabled: false,
        },
        opposite: false,
        labels: {
            enabled: false,
        },
        plotLines: [
            {
                color: 'red',
                width: 2,
                value: 0,
                dashStyle: 'dash',
                name: 'avgWiltSF',
            },
            {
                color: 'red',
                width: 2,
                value: 0,
                dashStyle: 'dash',
                name: 'avgFullSF',
            }
        ]
    }, 
    // {
    //     title: {
    //         text: "Rain (Daily)",
    //         enabled: false,
    //     },
    //     labels: { enabled: false },
    // }
]
};

const TribusAverageTrendLineChart = (props) => {
    const {
        depthSensors,
        // fieldPreview,
        device,
        handleFieldSettings,
        plt: { link },
        plt,
        series,
        refreshDevices
    } = props;
    // const deviceType = (props.deviceType) ? props.deviceType : 'capacitance';
    // console.log(series);
    const [chartSeries, setSeries] = useState(null);
    const [selectedSensors, setSelectedSensors] = useState([]);
    const [sensorsEstablished, establishSensors] = useState(false);

    const [updateProbeDepths] = useMutation(UPDATE_PROBE_DEPTHS);

    const [form, setForm] = useState({
        avgFullSF: 80,
        avgWiltSF: 50,
    });

    const [chartData, setChartData] = useState(null);

    const handleSetForm = (event) => {
        const f = { ...form };

        f[event.target.name] = event.target.value;

        setForm({ ...f });
    }

    const applyAxisChanged = async () => {
        const chart = chartData;
        const yAxis = chart.yAxis;
        let plotLines = undefined;

        if (!Array.isArray(yAxis)) {
            plotLines = chart.yAxis.plotLines;
        } else {
            plotLines = chart.yAxis[0].plotLines;
        }

        for (let i = 0; i < plotLines.length; i++) {
            const line = plotLines[i];
            if (form[line.name]) {
                line.value = Number(form[line.name]);
            }
        };

        if (typeof yAxis === 'object') {
            chart.yAxis.plotLines = plotLines;
        } else {
            chart.yAxis[0].plotLines = plotLines;
        }

        setChartData({ ...chart });

        const sensors = selectedSensors;

        await updateProbeDepths({
            variables: {
                guid: device.guid,
                probesToAverage: sensors
            },
            context: { service: 'things' }
        });

        props.getAverageData(sensors, true);

        const l = {
            ...link,
            avgFullSF: parseFloat(form['avgFullSF']),
            avgWiltSF: parseFloat(form['avgWiltSF']),
            probesToAverage: sensors
        }

        const p = { ...plt, link: { ...link, ...l } };
        refreshDevices()
        handleFieldSettings(p);
    }

    const handleSetSelectedSensors = async (sensor) => {
        const sensors = [];
        selectedSensors.forEach((x) => {
            sensors.push(x);
        });
        const sensorIdx = sensors.indexOf(sensor);
        
        if (sensors.includes(sensor)) {
            sensors.splice(sensorIdx, 1);
        } else {
            sensors.push(sensor);
        }

        setSelectedSensors([...sensors]);
    }
    const applyDisabled = () => {
        const { wilting, full } = form;

        return (wilting === '' || wilting === 0 || full === '' || full === 0);
    }

    const updateChartSeries = useCallback(() => {
        const { plt: { link } } = props;

        if (props.series !== chartSeries) {
            const cData = defaultChartData;
            const dSeries = props.series;

            for (let i = 0; i < dSeries.length; i++) {
                if (dSeries[i]['name'] === 'Rain (Daily)' || dSeries[i]['name'] === 'Daily Run Time') {
                    dSeries[i]['type'] = 'column';
                    dSeries[i]['yAxis'] = 1;
                    dSeries[i]['pointPlacement'] = -1.2;
                } else {
                    dSeries[i]['type'] = 'spline';
                }
                if (dSeries[i]['name'].toLowerCase().indexOf('rain') > -1) {
                    // dSeries[i]['visible'] = false;
                }
                if (dSeries[i]['name'] === 'Average Trend Line') {
                    dSeries[i]['pointerInterval'] = 24 * 3600 * 1000 * 7;

                    let data = dSeries[i]['data'];
                    data.sort(function (a, b) {
                        return a[0] - b[0]
                    });

                    dSeries[i]['data'] = data;
                }
            }

            const updatePlotlines = (yAxis) => {
                const plotLines = yAxis.plotLines;

                if (plotLines && plotLines.length) {
                    for (let i = 0; i < plotLines.length; i++) {
                        const name = plotLines[i]['name'];

                        if (name && link[name]) {
                            plotLines[i]['value'] = link[name];
                            setForm(f => ({
                                ...f,
                                [name]: link[name]
                            }));
                        }
                    }
                }

                if (plt.thing.type === 'Watermark Probe') {
                    yAxis.title.enabled = true;
                    yAxis.labels.enabled = true;
                } else {
                    yAxis.title.enabled = false
                    yAxis.labels.enabled = false
                }
                return yAxis;

            }

            let yAxis = cData.yAxis;

            if (plt.thing.type === 'Rain Gauge'){
                yAxis = updatePlotlines(yAxis);
            }
            else if (!Array.isArray(yAxis)) {
                yAxis = updatePlotlines(yAxis);
            } else {
                for (let i = 0; i < yAxis.length; i++) {
                    yAxis[i] = updatePlotlines(yAxis[i]);
                }
            }

            cData.yAxis = yAxis;
            cData['series'] = dSeries;
            cData['rangeSelector']['selected'] = 6

            setChartData(c => ({ ...c, ...cData }));
            setSeries([...dSeries]);
        }

    }, [props, chartSeries, plt]);

    useEffect(() => {
        updateChartSeries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plt, series]);

    // useEffect(() => {
    //     if (fieldPreview && device && !sensorsEstablished) {
    //         const { devices } = fieldPreview;
    //         for (let i = 0; i < devices.length; i++) {
    //             if (device.guid === devices[i].deviceGuid) {
    //                 const probesToAverage = JSON.parse(devices[i].probesToAverage);
    //                 if (probesToAverage) {
    //                     establishSensors(true);
    //                     setSelectedSensors(probesToAverage);
    //                 }
    //             }
    //         }
    //     }
    // }, [fieldPreview, selectedSensors, device, sensorsEstablished]);
    useEffect(() => {
        if( device && !sensorsEstablished) {
            if (device.guid) {
                // console.log(device.links[0].probesToAverage);
                const probesToAverage = device.links[0].probesToAverage;
                if (probesToAverage) {
                    establishSensors(true);
                    setSelectedSensors(probesToAverage)
                }
            }
        }
    }, [selectedSensors, device, sensorsEstablished])
    useEffect(() => {
        if (device.type === 'Watermark Probe') {
            setForm(prevState => ({
                avgFullSF: 100,
                avgWiltSF: 50,
            }));
        }
    }, [device.type]);

    return (
        <>
            <Row>
                <Col xs={12}>
                    {(chartData !== null) ? (
                        <HighchartsReact
                            highcharts={HighStocks}
                            options={chartData}
                            constructorType={"stockChart"}
                            updateArgs={[true, true, false]}
                            containerProps={{ style: { height: "450px" } }}
                        />
                    ) : (null)}
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="d-flex align-center">
                    <ul className="series-checkbox-selection">
                        {depthSensors !== null && depthSensors.length && depthSensors.map((sensor, index) =>
                            <li
                                key={index}
                                className={(selectedSensors && selectedSensors.indexOf(sensor) > -1) ? 'selected' : ''}
                                onClick={() => handleSetSelectedSensors(sensor)}
                            >
                                {(selectedSensors.indexOf(sensor) > -1) ? (
                                    <><CheckSquare /> {sensor}</>
                                ) : (
                                    <><Square /> {sensor}</>
                                )}
                            </li>
                        )}
                    </ul>
                </Col>
                <Col md={12} lg={4}>
                    <Row className="atl-setpoint-settings">
                        <Col xs={4} className="d-flex align-items-end">
                            <Form.Group>
                                <Form.Label>Full</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="avgFullSF"
                                    value={form['avgFullSF']}
                                    onChange={handleSetForm}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={4} className="d-flex align-items-end">
                            <Form.Group>
                                <Form.Label>Wilting</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="avgWiltSF"
                                    value={form['avgWiltSF']}
                                    onChange={handleSetForm}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={4} className="d-flex align-items-center">
                            <Button
                                variant="primary"
                                onClick={applyAxisChanged}
                                style={{ marginTop: 10 }}
                                disabled={applyDisabled()}
                            >Apply</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default TribusAverageTrendLineChart;
