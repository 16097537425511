import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    Form,
    Row,
} from 'react-bootstrap';
import { Search } from 'react-feather';
import { useQuery } from '@apollo/client';
import { Icon } from '../../common/icons';
import { GET_ALL_ITEMS } from '../../GraphQL/Queries/Items';
import Spinner from '../../common/Spinner';
import { sortCollection } from '../../services/Data';

let timeout;

const OrderItems = (props) => {
    const { onClick, pendingMarker, onCancelPin } = props;
    const [ settingMarker, setSettingMarker ] = useState(null);
    const [ search, setSearch ] = useState('');
    const [ itemsList, setItemsList ] = useState(null);
    const [ refItemsList, setRefItemsList ] = useState(null);
    
    const { loading: gettingItems, error: errorGettingItems, data: itemsData } = useQuery(
        GET_ALL_ITEMS,
        {
            context: {
                service: 'items'
            }
        }
    );
    
    const handleFormDataChange = (event) => {
        const value = event.target.value;
        
        window.clearTimeout(timeout);

        setSearch(value);

        timeout = window.setTimeout(() => {
            if ( value === '' ) {
                setItemsList(refItemsList);

            } else {
                const filtered = refItemsList.filter((row) => {
                    const val = value.toLowerCase();
                    const name = row.name.toLowerCase();
                    const summary = row.summary.toLowerCase();
                    
                    return ( name.indexOf(val) > -1 || summary.indexOf(val) > -1 );
                });

                const sorted = sortCollection(filtered, { 'name': 'asc' });

                setItemsList(sorted);
            }
        }, 250);
    }

    const handleClick = (item) => {
        onClick(item);
        setSettingMarker(item);
    }

    const handleCancel = () => {
        onClick(null);
        setSettingMarker(null);
        onCancelPin();
    }

    const hasPendingMarker = (item) => {
        return ( 
            (item && settingMarker && pendingMarker) &&
            (
                settingMarker !== null || 
                pendingMarker !== null
             ) && 
            pendingMarker.guid === item.guid 
        );
    }

    useEffect(() => {
        if ( !gettingItems && !errorGettingItems ) {
            const { getAllItems } = itemsData;
            const { results } = getAllItems;

            if ( results && results.length > 0 ) {
                setItemsList(results);
                setRefItemsList(results);
            }
        }
    }, [errorGettingItems, gettingItems, itemsData, setItemsList]);

    return(
        <Row>
            <Col xs={12} className="orders-filter-container">
                <div className="filter-wrapper">
                    <Form.Control
                        type="text"
                        placeholder="Search"
                        name="search"
                        onChange={handleFormDataChange}
                        value={search}
                    />
                    <Button variant="primary" ><Search /></Button>
                </div>
            </Col>
            <Col xs={12} md={12} className="scrollable-container">
                {gettingItems && !errorGettingItems ? (
                    <Spinner>Getting Available Items</Spinner>
                ) : (
                    itemsList !== null ? (
                        (itemsList.length < 1) ? (
                            <div className="no-results">No Items Were Found</div>
                        ) : (
                            itemsList.map((item, index) => 
                                <div className="item-card-wrapper" key={index}>
                                    {!item.imageFileName ? (
                                        <div className="item-card-image">
                                            <img src={item.imageFileName} border="0" alt="" />
                                        </div>
                                    ): (
                                        <div className="image-placeholder">
                                            <Icon 
                                                type="tiles" 
                                                name={item.imageFileName} 
                                                width={72} 
                                            />
                                        </div>
                                    )}
                                    <div className="item-card-title">{item.name}</div>
                                    <div className="item-card-price">${Number(item.price).toFixed(2)} per {item.sellingUnit}</div>
                                    <div className="item-card-description">{item.summary}</div>
                                    <div className="item-card-button">
                                        { hasPendingMarker(item) &&
                                            <Button variant="light" onClick={handleCancel}>Cancel</Button>
                                        }
                                        <Button 
                                            variant={hasPendingMarker(item) ? 'info' : 'primary'}
                                            disabled={hasPendingMarker(item)} 
                                            onClick={() => handleClick(item)}
                                        >
                                            {hasPendingMarker(item) ? (
                                                <>Click Map to Drop Pin</>
                                            ) : (
                                                <>Drop Pin</>
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            )
                        )
                    ) : (
                        null
                    )
                )}
            </Col>
        </Row>
    )
}

export default OrderItems;