import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Form, Modal } from 'react-bootstrap';
import { Edit2, Trash2 } from 'react-feather';
import moment from 'moment';
import { useToasts } from 'react-toast-notifications';
import { getUserGUID } from '../../../../services/User';
import IrrigationTable from './SpecsTable';
import PawTable from './PAWTable';
import { GET_NOTES_BY_PLACE } from '../../../../GraphQL/Queries/Things';
import { 
    ADD_IRRIGATION_NOTE, 
    UPDATE_IRRIGATION_NOTE,
    DELETE_IRRIGATION_NOTE
} from '../../../../GraphQL/Mutations/Things';

// const initialNotes = [{
//     id: 1,
//     text: 'Grower says bad well.',
// }];

const IrrigationSpecs = (props) => {
    const { deviceType, fieldPreview, plt, plt: { place } } = props;

    const userGuid = getUserGUID();
    const { addToast } = useToasts();
    const [textareaValue, setTextareaValue] = useState('');
    const [ editingNote, setEditingNote ] = useState(null);
    const [ showRemoveModal, setShowRemoveModal ] = useState(false);

    const [ addNote ] = useMutation(ADD_IRRIGATION_NOTE);
    const [ updateNote ] = useMutation(UPDATE_IRRIGATION_NOTE);
    const [ deleteNote ] = useMutation(DELETE_IRRIGATION_NOTE);
    const [ notes, setNotes ] = useState(null);
    const { loading: gettingNotes, error: errorGettingNotes, data: notesData, refetch: refetchNotes } = useQuery(
        GET_NOTES_BY_PLACE,
        {
            variables: {
                guid: place.guid
            },
            context: {
                service: 'things'
            }
        }
    );

    const resetTextArea = () => {
        setTextareaValue('');
    };

    const handleTextAreaChange = e => {
        const { value } = e.target;

        if (!value) {
            setTextareaValue('');
        }

        return setTextareaValue(value);
    };

    const handleAddNote = async () => {
        let response = undefined;

        if ( editingNote === null ) {
            response = await addNote({
                variables: {
                    fieldId: place.guid,
                    note: {
                        authorGuid: userGuid,
                        message: textareaValue
                    }
                },
                context: { service: 'things' }
            });
        } else {
            response = await updateNote({
                variables: {
                    fieldId: place.guid,
                    note: {
                        authorGuid: userGuid,
                        guid: editingNote.guid,
                        message: textareaValue
                    }
                },
                context: { service: 'things' }
            });

        }

        if ( response ) {
            refetchNotes();
            resetTextArea();
        }
    };

    const editNote = (row) => {
        let rec = row.message;
        rec = rec.replace(/(\r\n|\n|\r)/gm, "");
        rec = rec.replace(/ {2}/ig, '');

        setEditingNote(row);
        setTextareaValue(rec);
    }

    const removeNoteConfirm = (row) => {
        setEditingNote(row);

        setShowRemoveModal(true);
    }

    const hideRemoveModal = () => {
        setEditingNote(null);
        setShowRemoveModal(false);
    }

    const handleRemoveNote = async () => {
        const response = await deleteNote({
            variables: {
                guid: editingNote.guid,
            },
            context: { service: 'things' }
        });

        if ( response ) {
            addToast('Notes Successfully Removed', { 
                appearance: 'success', 
                autoDismiss: true,
            });

            setEditingNote(null);
            setShowRemoveModal(false);
            refetchNotes();
            resetTextArea();
        }
    }

    useEffect(() => {
        if ( !gettingNotes && !errorGettingNotes ) {
            const { getNotesByPlace: { results } } = notesData;
            
            setNotes(results);
        }
    }, [gettingNotes, errorGettingNotes, notesData])

    return (
        <div className="irrigation-specs-wrapper">
            <IrrigationTable plt={plt} fieldPreview={fieldPreview} />
            <div className="divider" />
            {( deviceType && deviceType.toLowerCase().indexOf('watermark') > -1 ) &&
                <PawTable plt={plt} />
            }
            <div className="divider" />
            <div className="irrigation-notes">
                <h6>Notes</h6>
                <table className="notes-table">
                    <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th style={{width: 100}}>Create</th>
                        <th style={{width: 100}}>Updated</th>
                        <th style={{width: 100}}>By</th>
                        <th>Recommendation</th>
                    </tr>
                    </thead>
                    <tbody>
                    {notes !== null && notes.map((note, index) => 
                        <tr key={index} className={(editingNote !== null && editingNote.guid === note.guid) ? 'selected' : ''}>
                            <td onClick={() => editNote(note)}><Edit2 size={18} /></td>
                            <td onClick={() => removeNoteConfirm(note)}><Trash2 size={18} /></td>
                            <td>{(note.dateCreated === null) ? ( <>&nbsp;</> ) : ( moment(note.dateCreated).format("MM/DD/YYYY hh:MM:SS A") )}</td>
                            <td>{(note.dateUpdated === null) ? ( <>&nbsp;</> ) : ( moment(note.dateUpdated).format("MM/DD/YYYY hh:MM:SS A") )}</td>
                            <td>{note.authorName}</td>
                            <td>{note.message}</td>
                        </tr>
                    )}
                    </tbody>
                </table>
                <div className="textarea-container">
                    <Form.Control as="textarea" onChange={e => handleTextAreaChange(e)} rows={4} value={textareaValue} />
                </div>
                <div className="button-container">
                    <Button
                        variant="primary" 
                        disabled={textareaValue === ''} 
                        onClick={() => handleAddNote()} 
                    >
                        {editingNote === null ? (
                            `Add Note`
                        ) : (
                            `Save Changes`
                        )}
                    </Button>
                    <Button 
                        variant="primary"
                        disabled={textareaValue === ''} 
                        onClick={() => resetTextArea()} 
                    >Cancel</Button>
                </div>
            </div>
            <Modal
                show={showRemoveModal}
                onHide={hideRemoveModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header><h5>Remove Note?</h5></Modal.Header>
                <Modal.Body>
                    Are you sure you want to remove this note?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={hideRemoveModal}>Cancel</Button>
                    <Button variant="danger" onClick={handleRemoveNote}>Remove Note</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default IrrigationSpecs;
