import React, { useCallback, useEffect, useState } from 'react';
import {
    Button,
    Col,
    Form,
    Modal,
    Row,
} from 'react-bootstrap';
import { useQuery } from '@apollo/client';

import { GET_SOIL_TEXTURE_LIST } from '../../../GraphQL/Queries/Things';

const SettingsModal = (props) => {
    const { plt, show, onHide, handleDeviceSettings } = props;
    const [form, setForm] = useState(null);
    const [soilTextures, setSoilTextures] = useState(null);
    const [currentPLT, setCurrentPLT] = useState(null);
    const [needRun, setNeedRun] = useState(null);

    const { loading: gettingSoilTextures, error: errorSoilTextures, data: dataSoilTextures } = useQuery(GET_SOIL_TEXTURE_LIST, { context: { service: 'things' } });


    const handleSetLinkForm = (event) => {
        const link = { ...form.link };

        link[event.target.name] = event.target.value;

        setForm({ ...form, link: { ...link } });
    }

    const handleSetThingForm = (event) => {
        const thing = { ...form.thing };
        thing[event.target.name] = event.target.value;
        setForm({ ...form, thing: { ...thing } });
    }

    const handleSave = () => {
        handleDeviceSettings(form);
        setCurrentPLT(form);
        setNeedRun(true);
        onHide();
    }

    const setForms = useCallback(() => {
        const place = { ...currentPLT.place };
        // const { pivot } = place;

        if (place.acresInches === null) {
            place.acresInches = 0;
        }
        if (place.wellCapacity === null) {
            place.wellCapacity = 0;
        }
        if (place.pivotPassAmount === null) {
            place.pivotPassAmount = 0;
        }
        let tempPLT = { place: place, link: currentPLT.link, thing: currentPLT.thing }
        setCurrentPLT(tempPLT);

        setForm(f => ({ ...f, ...currentPLT }));
        setCurrentPLT(form);
    }, [currentPLT, setCurrentPLT, setForm, form]);

    useEffect(() => {
        if (plt !== null && plt['place'] && !currentPLT) {
            setCurrentPLT(plt);
            setNeedRun(true);
        }
    }, [plt, currentPLT]);

    useEffect(() => {
        if (currentPLT && needRun) {
            setNeedRun(false);
            setForms();
        }
    }, [currentPLT, needRun, setForms]);

    useEffect(() => {
        if (!gettingSoilTextures && !errorSoilTextures) {
            const { getSoilTextureList } = dataSoilTextures;
            let results = JSON.parse(JSON.stringify(getSoilTextureList.results));
            results.sort();
            setSoilTextures(results);
        }
    }, [gettingSoilTextures, errorSoilTextures, dataSoilTextures]);

    if (form === null) {
        return (null);
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <Row>
                    <Col xs={12} md={8}>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="deviceName"
                                value={form['thing']['deviceName'] || ''}
                                onChange={handleSetThingForm}
                            >
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={8}>
                        <Form.Group>
                            <Form.Label>1 ft Soil Texture</Form.Label>
                            <Form.Control
                                as="select"
                                name="oneFtSoilTexture"
                                value={form['link']['oneFtSoilTexture'] || ''}
                                onChange={handleSetLinkForm}
                            >
                                <option value=""></option>
                                {soilTextures !== null && soilTextures.map((texture, index) =>
                                    <option value={texture} key={index}>{texture}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                {/* Row */}
                <Row>
                    <Col xs={12} md={8}>
                        <Form.Group>
                            <Form.Label>2 ft Soil Texture</Form.Label>
                            <Form.Control
                                as="select"
                                name="twoFtSoilTexture"
                                value={form['link']['twoFtSoilTexture'] || ''}
                                onChange={handleSetLinkForm}
                            >
                                <option value=""></option>
                                {soilTextures !== null && soilTextures.map((texture, index) =>
                                    <option value={texture} key={index}>{texture}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={8}>
                        <Form.Group>
                            <Form.Label>3 ft Soil Texture</Form.Label>
                            <Form.Control
                                as="select"
                                name="threeFtSoilTexture"
                                value={form['link']['threeFtSoilTexture'] || ''}
                                onChange={handleSetLinkForm}
                            >
                                <option value=""></option>
                                {soilTextures !== null && soilTextures.map((texture, index) =>
                                    <option value={texture} key={index}>{texture}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="divider" />
                </Row>
                <Row>
                    <Col xs={12}>
                        <h6>Moisture Set Points</h6>
                    </Col>
                    <Col xs={12} md={8}>
                        <Form.Group>
                            <Form.Label>Full</Form.Label>
                            <Form.Control
                                type="text"
                                name="fullSF"
                                value={form['link']['fullSF'] || ''}
                                onChange={handleSetLinkForm}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Wilting</Form.Label>
                            <Form.Control
                                type="text"
                                name="wiltSF"
                                value={form['link']['wiltSF'] || ''}
                                onChange={handleSetLinkForm}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={onHide}>Cancel</Button>
                <Button variant="primary" onClick={handleSave}>Save</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SettingsModal