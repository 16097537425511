import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
// import Styled from './style';
import { Form, Row, Col, Button } from 'react-bootstrap';

const IrrigationCalculator = (props) => {
    const  { handleFieldSettings, plt } = props;
    const [ form, setForm ] = useState(null);
    const [ noPivotData, setNoPivotData ] = useState(false);

    const handleSetForm = (event) => {
        setForm( {...form, [event.target.name]: event.target.value });
    }

    const [daysToApplyIrrigation, setDaysToApply] = useState(0);

    const updateFormula = () => {
        let { acres, wellCapacity, pivotPassAmount } = form;
        const { place } = plt;

        if ( acres !== "0" && pivotPassAmount !== "0" && wellCapacity !== "0" ) {
            acres = Number(acres);
            pivotPassAmount = Number(pivotPassAmount);
            wellCapacity = Number(wellCapacity);
            
            const results = ( ( acres * pivotPassAmount ) / (wellCapacity / 450) ) / 24;
            setDaysToApply(Number(results).toFixed(1));

            handleFieldSettings({
                ...plt,
                place: { ...place, acres, pivotPassAmount, wellCapacity }
            });
        } else {
            setDaysToApply(0);
            handleFieldSettings(plt);
        }

    }

    const applyDisabled = () => {
        const { fieldSize, wellCapacity, pivotPassAmount } = form;

        return ( 
            fieldSize === '' || fieldSize === 0 ||
            wellCapacity === '' || wellCapacity === 0 ||
            pivotPassAmount === '' || pivotPassAmount === 0
        );
    }

    useEffect(() => {
        const { place } = plt;

        if ( place !== null ) {
            let { pivotPassAmount, wellCapacity, acres} = place;

            if ( acres !== 0 && pivotPassAmount !== 0 && wellCapacity !== 0 ) {
                acres = Number(acres);
                pivotPassAmount = Number(pivotPassAmount);
                wellCapacity = Number(wellCapacity);

                const results = ( ( acres * pivotPassAmount ) / (wellCapacity / 450) ) / 24;
                
                setDaysToApply(Number(results).toFixed(1));
            }

            const obj = {
                acres,
                pivotPassAmount,
                wellCapacity
            };
            
            setForm(f => ({...f, ...obj }));
        } else {
            setNoPivotData(true);
        }

    }, [plt]);

    if ( noPivotData ) {
        return(
            <div className="irrigation-calc-wrapper">
                <div className="irrigation-calc-container">
                    <p>No Pivot Data for the selected Field</p>
                </div>
            </div>
        );
    }

    if ( form === null ) {
        return(null);
    }

    return (
        <div className="irrigation-calc-wrapper">
            <div className="irrigation-calc-container">
                <Row>
                    <Col xs={12} sm={9}>
                        <Row>
                            <Col xs={12} sm={3}>
                                <Form.Group required>
                                    <Form.Label>Irrigated Acres *</Form.Label>
                                    <Form.Control
                                        type="text"
                                        onChange={handleSetForm}
                                        name="acres"
                                        value={form['acres'] || 0}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={3}>
                                <Form.Group>
                                    <Form.Label>Well Capacity (GPM) *</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        onChange={handleSetForm}
                                        name="wellCapacity"
                                        value={form['wellCapacity'] || 0}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={3}>
                                <Form.Group>
                                    <Form.Label>Acre-inches *</Form.Label>
                                    <Form.Control
                                        type="text"
                                        onChange={handleSetForm}
                                        name="pivotPassAmount"
                                        value={form['pivotPassAmount'] || 0}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={3} className="d-flex align-items-center">
                                <Button 
                                    variant="primary" 
                                    style={{marginTop: 10}}
                                    onClick={updateFormula}
                                    disabled={applyDisabled()}
                                    title={(applyDisabled()) ? "Complete the required fields first" : ''}
                                >Apply</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <span style={{ fontSize: '0.9em', color: '#999', fontStyle: 'italic'}}>* Required</span>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={3}>
                        <Form.Group>
                            <Form.Label>Days to Apply Irrigation</Form.Label>
                            <Form.Control
                                type="text"
                                disabled
                                value={daysToApplyIrrigation + ' days'}
                                className="disabled-text-only"
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default IrrigationCalculator;
