import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { GET_PEOPLE_BY_EMAIL, GET_PERSON_BY_GUID } from '../../GraphQL/Queries/User';
import PageWrapper from '../../components/PageWrapper';

import {
    clearToken,
    getLastLocation,
    getUserObject,
    setIsAgronomist,
    setIsGrower,
    setIsManager,
    setToken,
    setUserGUID,
    setUserDetails,
    setEmail,
} from '../../services/User';
import Spinner from '../../common/Spinner';

const LoginComponent = () => {
    const [loginSuccess, setLoginSuccess] = useState(null);
    const [loginLoading, setLoginLoading] = useState(null);
    const [loginError, setLoginError] = useState(false);
    const [lastLocation, setUserLastLocation] = useState(null);

    const [getPeopleByEmail, { called: guidCalled, data: guidData }] = useLazyQuery(GET_PEOPLE_BY_EMAIL);
    const [getPersonByGUID, { called: personCalled, data: personData }] = useLazyQuery(GET_PERSON_BY_GUID);

    const handleLoginFailure = () => {
        setLoginError(true);
        setLoginLoading(false);
        setLoginSuccess(false);
        clearToken();
    }

    const handleOnRequest = () => {
        setLoginLoading(true);
    }

    const handleLogin = async (response) => {
        handleOnRequest()
        console.log(response);
        const {
            credential = '',
        } = response;

        if (credential) {

            const fetchLogIn = await fetch('https://google-auth-node-login-staging-bjudfyf5za-uc.a.run.app/login', { method: "POST", headers: { 'Content-type': 'application/json' }, body: JSON.stringify({ credential: credential, GOOGLE_CLIENT_ID:"495656309447-a5mtrmd8cojq90qo0j7j5eh6q3ig1p6n.apps.googleusercontent.com" }) });
            const fetchLogInJson = await fetchLogIn.json();
            console.log(fetchLogInJson);
            if (fetchLogInJson && fetchLogInJson.user) {
                const accessToken = fetchLogInJson.user.accessToken;
                setToken(accessToken);
                const user = getUserObject();
                if (user.email && user.email !== null) {
                    getPeopleByEmail({
                        variables: {
                            email: user.email
                        },
                        context: {
                            service: 'people'
                        }
                    });
                }
            } else {
                handleLoginFailure();
            }

        } else {
            handleLoginFailure();

        }
    }

    if (loginSuccess === null && loginLoading === true && guidCalled && guidData && !sessionStorage.getItem("userDetails")) {
        const { getPeopleByEmail: { results } } = guidData;
        const userDetails = results[0];

        if (userDetails.guid) {
            setUserGUID(userDetails.guid);

            getPersonByGUID({
                variables: {
                    guid: userDetails.guid
                },
                context: {
                    service: 'people'
                }
            });
        }

        setLoginLoading(false);

    }

    if (loginSuccess === null && !loginLoading && personCalled && personData) {
        const { getPersonByGuid: { results: person } } = personData;
        const personDetails = person[0];
        const emailDetails = personDetails.emails[0];

        setUserDetails(personDetails);
        setIsManager(personDetails.isManager || false);
        setIsGrower(personDetails.isGrower || false);
        setIsAgronomist(personDetails.isAgronomist || false);
        setEmail(emailDetails.email);

        const url = getLastLocation();

        if (!url || url === null) {
            setUserLastLocation('/');
        } else {
            setUserLastLocation(url);
        }

        setLoginSuccess(true);
    }





    useEffect(() => {
        if (window.google) {
            window.google.accounts.id.initialize({
                client_id: '495656309447-a5mtrmd8cojq90qo0j7j5eh6q3ig1p6n.apps.googleusercontent.com',
                callback: handleLogin
            });
            window.google.accounts.id.renderButton(
                document.getElementById("googleSignInButtonDiv"),
                { theme: "outline", size: "large", shape: "rectangle", logo_alignment: "left", text: "signin_with" }  // customization attributes
            );
            window.google.accounts.id.prompt();
        }
        // eslint-disable-next-line
    }, [])

    if (loginLoading) {
        return (
            <div className="login-wrapper">
                <div className="logging-in-message">
                    <Spinner>Logging In</Spinner>
                </div>
            </div>
        );
    }


    if (loginSuccess && loginSuccess !== null) {
        return <Redirect to={lastLocation} />;
    }

    return (
        <PageWrapper>

            {(!loginError || loginError === 'false') && (
                <div className="login-wrapper">
                    <div id="googleSignInButtonDiv"></div>
                </div>
            )}
            {(loginError || loginError === 'true') && (
                <div className="login-error align-center">Login failed, please try again</div>
            )}
        </PageWrapper>
    );
}

export default LoginComponent;
