import React, { useEffect, useState } from 'react';
import { Collapse, Card, } from 'react-bootstrap';
import { properCase } from '../../services/Data';
import AccordionHeader from '../../components/AccordionHeader';
import DeviceDetails from './DeviceDetails';
import { Icon } from '../../common/icons';

const DeviceDetailsWrapper = (props) => {
    const {
        handleThingSave,
        handleStateSave,
        handleUnassociatedSave,
        thingsForMap,
        onDeviceSelect,
        selectedDevice,
        updateSelectedDevice,
        updateDeviceBackup,
        addGateway,
        getDeviceGPSHistory
    } = props;
    const [cardOpen, setCardOpen] = useState(false);

    const handleGetHistory = () => {
        // console.log(getDeviceGPSHistory);
        getDeviceGPSHistory();
    }
    const handleCardOpen = (thing, item) => {
        const combGuids = thing.properties.orderGuid + '' + thing.properties.lineItemGuid + '' + thing.properties.deveui;
        // console.log(combGuids);
        if (cardOpen !== combGuids) {
            setCardOpen(combGuids);
            onDeviceSelect(item);
            updateSelectedDevice(thing);
            updateDeviceBackup(thing);
        } else {
            setCardOpen(null);
            onDeviceSelect(null);
        }
    }

    const closeMarkerDetails = () => {
        setCardOpen(null);
        onDeviceSelect(null);
    }

    const itemHeader = (thing) => {
        const c = thing.properties;
        const { grower } = c;
        const { field } = c;
        const { deviceName } = c;
        let growerName = 'no grower';
        let fieldName = 'no field';
        let displayName = '';
        if (c && grower) {
            if (grower.firstName) {
                growerName = grower.firstName + ' ' + grower.lastName
            } else {
                if (grower.name) {
                    growerName = grower.name;
                }
            }
            growerName = properCase(growerName)
        }

        if (c && field) {
            if (field.fieldName) {
                fieldName = field.fieldName;
            }
            fieldName = properCase(fieldName);
        }

        if (c && deviceName) {
            if (isNaN(deviceName)){
                displayName = `> ${deviceName}`
            }
        }
        return (
            <>{growerName} &gt;&nbsp; {fieldName}{displayName}</>
        );

    }

    useEffect(() => {
        if (selectedDevice !== null && selectedDevice !== undefined) {
            const thing = selectedDevice;
            if (thing.properties) {
                const combGuids = thing.properties.orderGuid + '' + thing.properties.lineItemGuid + '' + thing.properties.deveui;
                setCardOpen(combGuids);
            }
        }
    }, [selectedDevice]);

    if (!thingsForMap || thingsForMap === null) {
        return (null);
    }

    return (
        thingsForMap.map((thing, index) =>
            (thing.properties.pinFileName !== 'pinHouseBlue.svg' &&
            <div key={index} className="accordion device-details-accordion">
                <Card>
                    <AccordionHeader
                        onClick={() => handleCardOpen(thing)}
                        isOpen={(cardOpen === thing.properties.orderGuid + '' + thing.properties.lineItemGuid + '' + thing.properties.deveui)}
                        eventKey={thing.properties.orderGuid + '' + thing.properties.lineItemGuid + '' + thing.properties.deveui}
                        parent="#deviceDetailsColumn"
                    >
                        <div className="icon-wrapper">
                            <Icon
                                type="pins"
                                name={thing.properties.pinFileName}
                                fill="#000"
                                width={24}
                                style={{ marginRight: 8 }}
                            />
                        </div>
                                ID: #{thing.properties.deveui || thing.properties.sensorSerial} &gt;&nbsp; {itemHeader(thing)}
                    </AccordionHeader>
                    <Collapse in={(cardOpen === thing.properties.orderGuid + '' + thing.properties.lineItemGuid + '' + thing.properties.deveui)} mountOnEnter unmountOnExit>
                        <Card.Body>
                            <DeviceDetails
                                card={thing}
                                selectedDevice={selectedDevice}
                                asAccordion={true}
                                handleThingSave={handleThingSave}
                                handleStateSave={handleStateSave}
                                handleUnassociatedSave={handleUnassociatedSave}
                                setCardOpen={setCardOpen}
                                closeMarkerDetails={closeMarkerDetails}
                                updateSelectedDevice={updateSelectedDevice}
                                addGateway={addGateway}
                                getDeviceGPSHistory={handleGetHistory}
                            />
                        </Card.Body>
                    </Collapse>
                </Card>
            </div>
        ))
    );
}

export default React.memo(DeviceDetailsWrapper);