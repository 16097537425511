import { gql } from '@apollo/client';

export const PastData = gql`
    query Query($where: ThingWhere) {
        things(where: $where) {
            hasdata{
                data
            }
        }
    }
`;