import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import config from './config';

const uris = {
    // item: itemsURI,
    items: config.itemsURI,
    // place: placesURI,
    places: config.placesURI,
    login: config.loginURI,
    // person: peopleURI,
    people: config.peopleURI,
    // thing: thingURI,
    things: config.thingsURI,
    // crop: cropsURI,
    crops: config.cropsURI,
    // order: ordersURI,
    orders: config.ordersURI,
    pastData: config.pastdataURI,
    tribusThings: config.tribusThingsURI
}

const getLink = (operation) => {
    const service = operation.getContext().service;
    // console.log(operation.getContext);
    // console.log(service);
    if (!service) {
        console.trace();
        console.log('no service');
    }
    // console.log('uri: :', uris[service]);
    const uri = (!service) ? uris['people'] : uris[service];
    // const uri = uris[service];
    // console.log(uri);
    if (!uri) {
        console.error('The specified context ("' + service + '") does not have a URI associated with it');
    }

    const link = new HttpLink({ uri });
    return link.request(operation);
}

const authLink = setContext((_, { headers, ...context }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        },
        ...context,
    }
});
console.log(authLink);
const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(ApolloLink.split(
        () => true,
        operation => getLink(operation)
    ))
});

export default client;
