import { gql } from '@apollo/client';

export const GET_ALL_ITEMS = gql`
    query {
        getAllItems {
            ok
            results {
                guid
                name
                imageFileName
                summary
                price
                sellingUnit
                minimumQuantity
                pinFileName
                externalData
                bridgeFeaturesDelivered
                gatewayNeeded
                deviceNameNeeded
            }
        }
    }
`;