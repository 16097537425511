import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Form, Modal } from 'react-bootstrap';
import { Edit2, Trash2 } from 'react-feather';
import moment from 'moment';
import { useToasts } from 'react-toast-notifications';
import { getUserGUID } from '../../../services/User';
import { GET_RECOMMENDATIONS_BY_PLACE } from '../../../GraphQL/Queries/Things';
import { 
    ADD_IRRIGATION_RECOMMENDATION, 
    UPDATE_IRRIGATION_RECOMMENDATION,
    DELETE_IRRIGATION_RECOMMENDATION
} from '../../../GraphQL/Mutations/Things';

const templates = ['Pivot Pass', 'No Irrigation', 'Start Date', 'Watermark Recommendations', 'Custom'];

const initialPivotPassState = {
    pivotPassMoisture: 'adequate',
    pivotPassTime: 'no',
};

const IrrigationRecommendations = (props) => {
    const userGuid = getUserGUID();
    const { addToast } = useToasts();
    const { 
        acreInches, 
        deviceType, 
        fieldPreview,
        handleCalculatorInputChange,
        plt: { place }, 
    } = props;
    const [ showRemoveModal, setShowRemoveModal ] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [textareaValue, setTextareaValue] = useState('');
    const [ editingRec, setEditingRec ] = useState(null);

    const [startDate, setStartDate] = useState((new Date()).toLocaleDateString());
    const [startDateMoisture, setStartDateMoisture] = useState('adequate');
    const [startDatePivot, setStartDatePivot] = useState('0.5');
    const [startDateTime, setStartDateTime] = useState('0');
    const [ etFixed ] = useState(Number(Number(fieldPreview.et).toFixed(2)).toFixed(2));

    const [pivotPassAcreInches, setPivotPassAcreInches] = useState(Number(fieldPreview.et).toFixed(2));
    const [pivotPassMoisture, setPivotPassMoisture] = useState(initialPivotPassState.pivotPassMoisture);
    const [pivotPassTime, setPivotPassTime] = useState(initialPivotPassState.pivotPassTime);

    const [watermarkPivotPassTime, setWatermarkPivotPassTime] = useState('no');
    const [watermarkStartDate, setWatermarkStartDate] = useState((new Date()).toLocaleDateString());
    const [watermarkPivotPassAcreInches, setWatermarkAcreInches] = useState(Number(fieldPreview.et).toFixed(2));

    const [ addRecommendation ] = useMutation(ADD_IRRIGATION_RECOMMENDATION);
    const [ updateRecommendation ] = useMutation(UPDATE_IRRIGATION_RECOMMENDATION);
    const [ deleteRecommendation ] = useMutation(DELETE_IRRIGATION_RECOMMENDATION);
    const [ recommendations, setRecommendations ] = useState(null);
    const { loading: gettingRecs, error: errorGettingRecs, data: recsData, refetch: refetchRecommendations } = useQuery(
        GET_RECOMMENDATIONS_BY_PLACE,
        {
            variables: {
                guid: place.guid
            },
            context: {
                service: 'things'
            }
        }
    );

    const resetTemplate = () => {
        setPivotPassMoisture('');
        setPivotPassTime('');
        setSelectedTemplate('');
        setTextareaValue('');
        setEditingRec(null);
    };

    const handleTemplateSelect = e => {
        const { value } = e.target;
        const template = templates.find(el => el === value);

        setSelectedTemplate(template);
        setTextareaValue('');
    };

    const handleTextAreaChange = e => {
        const { value } = e.target;

        if (!value) {
            return resetTemplate();
        }

        if (!selectedTemplate) {
            setSelectedTemplate('Custom');
        }

        return setTextareaValue(value);
    };

    const getTemplateText = templateType => {
        if (templateType === 'Pivot Pass') {
            return `Soil moisture is ${pivotPassMoisture} in the root zone. The crop is currently using ${Number(fieldPreview.et).toFixed(2)}" of water/day. 
                    I recommend making ${pivotPassTime} ${acreInches}" pivot passes over the next seven days. 
                    Shut down/delay irrigation one day for every ${pivotPassAcreInches}" of rain.`;
        }

        if (templateType === 'Start Date') {
            return `Soil moisture is ${startDateMoisture}. Start a ${startDatePivot}" pivot pass on ${startDate}. 
                    Shutdown ${startDateTime} days between passes until next check. Delay 1 day per ${acreInches}" of rain.`;
        }

        if (templateType === 'Watermark Recommendations') {
            return `Soil moisture is 73.29% 71.31% 64.17% in the top 3 ft with 0.82in of readily available water. 
                    Crop is using 0.25in/day so you have ${0.82 / 0.25} days of available moisture at this time. 
                    Make ${watermarkPivotPassTime} pivot passes by ${watermarkStartDate}. 
                    Shut down one day for every ${watermarkPivotPassAcreInches}in of rain.`;
        }

        if (templateType === 'No Irrigation') {
            return 'Soil moisture is saturated, no irrigation needed for the next seven days.';
        }

        return textareaValue;
    };

    const handleAddRecommendation = async () => {
        let response = undefined;
        if ( editingRec === null ) {

            response = await addRecommendation({
                variables: {
                    fieldId: place.guid,
                    recommendation: {
                        recommendation: getTemplateText(selectedTemplate)
                    }
                },
                context: { service: 'things'  }
            });
        } else {
            response = await updateRecommendation({
                variables: {
                    fieldId: place.guid,
                    recommendation: {
                        guid: editingRec.guid,
                        authorGuid: userGuid,
                        recommendation: getTemplateText(selectedTemplate)
                    }
                },
                context: { service: 'things' }
            });

        }

        if ( response ) {
            refetchRecommendations();
            resetTemplate();
        }

    };

    const editRecommendation = (row) => {
        let rec = row.recommendation;
        rec = rec.replace(/(\r\n|\n|\r)/gm, "");
        rec = rec.replace(/ {2}/ig, '');

        setEditingRec(row);

        setSelectedTemplate('Custom');
        setTextareaValue(rec);
    }

    const removeRecConfirm = (row) => {
        setEditingRec(row);

        setShowRemoveModal(true);
    }

    const hideRemoveModal = () => {
        setEditingRec(null);
        setShowRemoveModal(false);
    }

    const handleRemoveRecommendation = async () => {
        const response = await deleteRecommendation({
            variables: {
                guid: editingRec.guid,
            },
            context: { service: 'things' }
        });

        if ( response ) {
            addToast('Recommendation Successfully Removed', { 
                appearance: 'success', 
                autoDismiss: true,
            });

            setEditingRec(null);
            setShowRemoveModal(false);
            refetchRecommendations();
        }
    }

    useEffect(() => {
        if ( !gettingRecs && !errorGettingRecs ) {
            const { getRecommendationsByPlace: { results } } = recsData;
            setRecommendations(results);
        }
    }, [gettingRecs, errorGettingRecs, recsData])

    return (
        <div className="irrigation-recos-wrapper">
                {recommendations !== null && recommendations.length > 0 && (
                    <>
                    <h6>Recommendations</h6>
                    <div className="recommendations-wrapper">
                        <table className="recommendation-table">
                            <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th>&nbsp;</th>
                                <th style={{width: 100}}>Create</th>
                                <th style={{width: 100}}>Updated</th>
                                <th style={{width: 100}}>By</th>
                                <th>Recommendation</th>
                            </tr>
                            </thead>
                            <tbody>
                                {recommendations.map((row, index) => (
                                    row.reommendation !== null &&
                                    <tr key={index} className={(editingRec !== null && editingRec.guid === row.guid) ? 'selected' : ''}>
                                        <td onClick={() => editRecommendation(row)}><Edit2 size={18} /></td>
                                        <td onClick={() => removeRecConfirm(row)}><Trash2 size={18} /></td>
                                        <td>{(row.dateCreated === null) ? ( <>&nbsp;</> ) : ( moment(row.dateCreated).format("MM/DD/YYYY hh:MM:SS A") )}</td>
                                        <td>{(row.dateUpdated === null) ? ( <>&nbsp;</> ) : ( moment(row.dateUpdated).format("MM/DD/YYYY hh:MM:SS A") )}</td>
                                        <td>{row.authorName}</td>
                                        <td>{row.recommendation}</td>
                                    </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                    </>
                )}

            <Form inline style={{width: '100%'}}>
            <div className="add-recommendation">
                {recommendations !== null && recommendations.length > 0 && (
                    <div className="heading">Add Recommendation</div>
                )}

                {/* TODO - move select to own element */}
                <Form.Control
                    as="select"
                    onChange={e => handleTemplateSelect(e)}
                    style={{ width: '216px' }}
                    value={selectedTemplate || ''}
                >
                    <option disabled value="">Select a template</option>
                    {templates.filter(el => (deviceType !== 'Watermark Sensor' ? el !== 'Watermark Recommendations' : el)).map(el => (
                        <option key={el}>{el}</option>
                    ))}
                </Form.Control>
                {/* END TODO - move select to own element */}

                {(!selectedTemplate || (selectedTemplate === 'Custom')) && (
                    <div className="textarea-container">
                        <Form.Control 
                            as="textarea" 
                            style={{width: '100%' }} 
                            onChange={e => handleTextAreaChange(e)} 
                            rows="4" 
                            value={textareaValue} 
                        />
                    </div>
                )}

                {selectedTemplate === 'Pivot Pass' && (
                    <div style={{ minHeight: '160px', marginTop: '16px', color: 'rgba(0,0,0,.85)', lineHeight: 1.5715, border: 'solid 1px rgb(216,216,216)', borderRadius: '4px', padding: '4px 12px' }}>
                        Soil moisture is
                        <Form.Control
                            as="select"
                            onChange={e => setPivotPassMoisture(e.target.value)}
                            style={{ margin: '4px 8px', width: 'auto!important' }}
                        >
                            <option>adequate</option>
                            <option>dry</option>
                            <option>drying down</option>
                            <option>saturated</option>
                            <option>wetting up</option>
                        </Form.Control>
                        in the root zone. The crop is currently using {etFixed}&quot; of water/day. I recommend making
                        <Form.Control
                            as="select"
                            onChange={e => setPivotPassTime(e.target.value)}
                            style={{ margin: '4px 8px', width: 'auto!important' }}
                        >
                            <option>no</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                        </Form.Control>
                        <Form.Control
                            className="calculator-inline"
                            onChange={e => handleCalculatorInputChange(e, 'acreInches')}
                            value={acreInches || ''}
                            style={{width: 96}}
                        />
                        &quot; pivot passes over the next 7 days. Shut down/delay irrigation 1 day for every
                        <Form.Control
                            className="input-inline"
                            onChange={e => setPivotPassAcreInches(e.target.value)}
                            value={pivotPassAcreInches}
                            style={{width: 96}}
                        />
                        &quot; of rain.
                    </div>
                )}

                {selectedTemplate === 'Start Date' && (
                    <div style={{ minHeight: '160px', marginTop: '16px', color: 'rgba(0,0,0,.85)', lineHeight: 1.5715, border: 'solid 1px rgb(216,216,216)', borderRadius: '4px', padding: '4px 12px' }}>
                        Soil moisture is
                        <Form.Control
                            as="select"
                            onChange={e => setStartDateMoisture(e.target.value)}
                            style={{ margin: '4px 8px', width: 'auto!important' }}
                        >
                            <option>adequate</option>
                            <option>dry</option>
                            <option>drying down</option>
                            <option>saturated</option>
                            <option>wetting up</option>
                        </Form.Control>.
                        Start a
                        <Form.Control
                            className="input-inline"
                            onChange={e => setStartDatePivot(e.target.value)}
                            value={startDatePivot || ''}
                            style={{ width: 96 }}
                        />
                        &quot; pivot pass on
                        <Form.Control
                            className="input-inline date"
                            onChange={e => setStartDate(e.target.value)}
                            value={startDate || ''}
                            style={{ width: 110 }}
                        />
                        . Shutdown
                        <Form.Control
                            as="select"
                            onChange={e => setStartDateTime(e.target.value)}
                            style={{ margin: '4px 8px', width: 'auto!important' }}
                        >
                            <option>0</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                        </Form.Control>
                        days between passes until next check. Delay 1 day per
                        <Form.Control
                            className="input-inline"
                            // onChange={e => setStartDateDelay(e.target.value)}
                            // value={startDateDelay || ''}
                            onChange={e => handleCalculatorInputChange(e, 'acreInches')}
                            value={acreInches || ''}
                            style={{ width: 96 }}
                        />
                        &quot; of rain.
                    </div>
                )}

                {selectedTemplate === 'Watermark Recommendations' && (
                    <div style={{ minHeight: '160px', marginTop: '16px', color: 'rgba(0,0,0,.85)', lineHeight: 1.5715, border: 'solid 1px rgb(216,216,216)', borderRadius: '4px', padding: '4px 12px' }}>
                        Soil moisture is 73.29% 71.31% 64.17% in the top 3 ft with 0.82in of readily available water. Crop is using 0.25in/day so you have {0.82 / 0.25} days of available moisture at this time. Make
                        <Form.Control
                            as="select"
                            onChange={e => setWatermarkPivotPassTime(e.target.value)}
                            style={{ margin: '4px 8px', width: 'auto!important' }}
                        >
                            <option>no</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                        </Form.Control> pivot passes by
                        <Form.Control
                            className="input-inline date"
                            onChange={e => setWatermarkStartDate(e.target.value)}
                            value={watermarkStartDate || ''}
                        />. Shut down/delay irrigation 1 day for every
                        <Form.Control
                            className="input-inline"
                            onChange={e => setWatermarkAcreInches(e.target.value)}
                            value={watermarkPivotPassAcreInches}
                        />
                        &quot; of rain.
                    </div>
                )}

                {selectedTemplate === 'No Irrigation' && (
                    <div style={{ minHeight: '160px', marginTop: '16px', color: 'rgba(0,0,0,.85)', lineHeight: 1.5715, border: 'solid 1px rgb(216,216,216)', borderRadius: '4px', padding: '4px 12px' }}>
                        Soil moisture is saturated, no irrigation needed for the next 7 days.
                    </div>
                )}
            </div>
            </Form>

            <div className="add-recommendation-button-container">
                <Button 
                    disabled={!selectedTemplate || (selectedTemplate === 'Custom' && textareaValue === '')} 
                    onClick={() => handleAddRecommendation()} 
                    type="primary"
                >
                    {editingRec === null ? (
                        `Add Recommendation`
                    ) : (
                        `Save Changes`
                    )}
                </Button>
                <Button 
                    disabled={!selectedTemplate || (selectedTemplate === 'Custom' && textareaValue === '')} 
                    onClick={() => resetTemplate()} 
                    type="default"
                >Cancel</Button>
            </div>
            <Modal
                show={showRemoveModal}
                onHide={hideRemoveModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header><h5>Remove Recommendation?</h5></Modal.Header>
                <Modal.Body>
                    Are you sure you want to remove this recommendation?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={hideRemoveModal}>Cancel</Button>
                    <Button variant="danger" onClick={handleRemoveRecommendation}>Remove Recommendation</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default IrrigationRecommendations;
