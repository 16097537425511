export const thingStatusList = [
    'SID Connection Lost',
    'Sensor Connection Lost',
    'Check Device Signal',
    'Check Gateway Signal',
    'Check Device Battery',
    'Check Gateway Battery',
];

export const thingStateList = [
    'All',
    'Not Ready',
    'Ready To Install',
    'Installed',
    'Check Device',
    'Needs Repair',
    'Replace Batteries',
    'Replace SID',
    'Replace Gateway',
    'Replace Device',
    'Ready to Extract',
    'URGENT Extract',
    'Extracted',
    'Service Ready to Perform',
    'Service in Progress',
    'Service Complete',
];

export const thingDeviceOrTaskList = [
    'Gateway',
    'Capacitance Probe',
    'Watermark Probe',
    'Rain Gauge',
    'Weather Station',
    'Pivot Monitor',
    'Scouting Operation',
    'Sampling Operation',
    'Drone Flight',
];