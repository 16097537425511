import React, { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { useQuery } from "@apollo/client";
import AccordionHeader from '../../components/AccordionHeader';
import Growers from './Growers';
import FieldListCard from './FieldListCard';
import { getUserObject } from '../../services/User';
import Spinner from '../../common/Spinner';
import { properCase } from '../../services/Data';
import { sortCollection } from '../../services/Data';

import { 
    GET_MANAGED_PEOPLE, 
} from '../../GraphQL/Queries/User';

import './soilMoisture.scss';
import './Cards/soilMoistureCards.scss';

const ManagedPeople = (props) => {
    const { guid } = props;
    const user = getUserObject();

    const [ growerOpen, setGrowerOpen ] = useState(null);
    const [ peopleList, setPeopleList ] = useState(null);
    const [ personOpen, setPersonOpen ] = useState(null);
    const [ listToView, setListToView ] = useState(null);
    const [ fieldOpen, setFieldOpen ] = useState(null);
    
    const { 
        loading: gettingPeople, 
        error: peopleError, 
        data: peopleResults 
    } = useQuery(
        GET_MANAGED_PEOPLE, 
        { 
            variables: {  guid: (guid) ? guid : user.guid },
            context: { service: 'people'}
        }
    );

    const handleFieldOpen = (fieldId) => {
        const fieldName = 'field-' + fieldId;

        if ( fieldId === null ) {
            return;
        }

        if ( fieldName !== fieldOpen ) {
            setFieldOpen(fieldName);
        } else {
            setFieldOpen(null);
        }
    }
    
    const handleGrowerOpen = (grower) => {
        if ( grower !== growerOpen ) {
            setGrowerOpen(grower);
        } else {
            setGrowerOpen(null);
        }
    }

    const handlePersonOpen = (person) => {
        if ( person !== personOpen ) {
            setPersonOpen(person);
            setGrowerOpen(null);
        } else {
            setPersonOpen(null);
            setGrowerOpen(null);
        }
    }

    const handleSetListToView = (list) => {
        if ( list !== listToView ) {
            setListToView(list);
        } else {
            setListToView(null);
        }
    }

    const makePossesive = (name) => {
        const proper = properCase(name);
        const lastPos = proper.length-1;
        const lastLetter = proper.substr(lastPos);

        return (lastLetter === 's') ? proper + "'" : proper + "'s";
    }

    const hasPeopleList = () => {
        return (peopleList && peopleList !== null && peopleList.hasOwnProperty('length') && peopleList.length > 0);
    }

    useEffect(() => {
        if ( !gettingPeople && !peopleError ) {
            const { getManagedPeople } = peopleResults;
            let { results } = getManagedPeople;
            const sorted = sortCollection(results, { 'name': 'asc' } );

            setPeopleList(sorted);
        }
    }, [peopleResults, gettingPeople, peopleError ]);

    return(
        gettingPeople && !hasPeopleList() ? (
            <Spinner align="left">Getting Managed People</Spinner>
        ) : (
            <Accordion>
                {peopleList && peopleList.map((person, index) => 
                    <Card key={index}>
                        <AccordionHeader
                            eventKey={person.guid}
                            onClick={() => handlePersonOpen(person.guid)}
                            isOpen={personOpen}
                        >
                            {properCase(person.name)}
                        </AccordionHeader>
                        <Accordion.Collapse eventKey={person.guid} mountOnEnter unmountOnExit>
                            <Card.Body>
                                <Accordion>
                                    <Card>
                                        <AccordionHeader
                                            eventKey={'fields'}
                                            onClick={() => { handleSetListToView('fields') }}
                                            isOpen={listToView}
                                        >
                                            {makePossesive(person.name)} Fields
                                        </AccordionHeader>
                                        <Accordion.Collapse eventKey={'fields'} mountOnEnter unmountOnExit>
                                            <Card.Body>
                                                <Accordion>
                                                    <FieldListCard 
                                                        guid={person.guid} 
                                                        handleFieldOpen={handleFieldOpen}
                                                        fieldOpen={fieldOpen}
                                                    />
                                                </Accordion>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <AccordionHeader
                                            eventKey={'growers'}
                                            onClick={() => { handleSetListToView('growers') }}
                                            isOpen={listToView}
                                        >
                                            {makePossesive(person.name)} Growers
                                        </AccordionHeader>
                                        <Accordion.Collapse eventKey={'growers'} mountOnEnter unmountOnExit>
                                            <Card.Body>
                                                <Accordion>
                                                    <Growers
                                                        agronomist={person}
                                                        growerOpen={growerOpen}
                                                        handleGrowerOpen={handleGrowerOpen}
                                                    />
                                                </Accordion>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    { person.isManager &&
                                        <Card>
                                            <AccordionHeader
                                                eventKey={'managedPeople'}
                                                onClick={() => { handleSetListToView('managedPeople') }}
                                                isOpen={listToView}
                                            >
                                                {makePossesive(person.name)} People
                                            </AccordionHeader>
                                            <Accordion.Collapse eventKey={'managedPeople'} mountOnEnter unmountOnExit>
                                                <Card.Body>
                                                    <ManagedPeople guid={person.guid} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    }
                                </Accordion>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                )}
            </Accordion>
        )
    );
}

export default ManagedPeople;