import React, { useState, useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

const listItems = [
    'All',
    'Gateway',
    'Capacitance Probe',
    'Watermark Probe',
    'Rain Gauge',
    'Weather Station',
    'Pivot Monitor',
    'Scouting Operation',
    'Sampling Operation',
    'Drone Operation',
    'Ready to Scout',
    'Ready to Sample',
    'Ready to Fly',
    'GPS History',
    'All Current GPS'
]

const DeviceTaskModal = (props) => {
    const {
        filter,
        filterList,
        isOpen,
        setModalOpen
    } = props;

    const [ deviceTask, setDeviceTask ] = useState([]);

    const handleOnHide = () => {
        setModalOpen(!isOpen);
    }

    const handleSave = () => {
        setModalOpen(!isOpen);
        filter(deviceTask);
    }

    const isChecked = (id) => {
        return ( deviceTask.indexOf(id.toString()) > -1 );
    }
    
    const handleFormUpdate = (event) => {
        const value = event.target.value.toString();
        let tasks = deviceTask;
        const index = tasks.indexOf(event.target.value);

        // All is selected for first time: add all items to the list
        if ( value === "All" && index < 0 ) {
            tasks = [];
            for ( let i = 0; i < listItems.length; i++ ) {
                tasks.push(listItems[i].toString());
            }

            setDeviceTask([...tasks]);

        // All is selected, but being unchecked: remove all items
        } else if ( value === "All" && index > -1 ) {
            setDeviceTask([]);

        // Add/Remove one item at a time as their selected
        } else {

            if ( index > -1 ) {
                tasks.splice(index, 1);
            } else {
                tasks.push(event.target.value);
            }

            setDeviceTask([...tasks]);
        }
    }

    useEffect(() => {
        if ( filterList === null && deviceTask !== null ) {
            setDeviceTask([]);
        }
        if ( filterList !== null && filterList !== deviceTask ) {
            setDeviceTask([...filterList]);
        }
    // eslint-disable-next-line
    }, [filterList]);

    return(
        <Modal
            show={isOpen}
            onHide={handleOnHide}
            size="sm"
        >
            <Modal.Header><h6>Filter by Device/Task</h6></Modal.Header>
            <Modal.Body className="device-filter-modal">
                {listItems.map((row, index) => 
                    <Form.Check
                        key={index}
                        type="checkbox"
                        label={row}
                        name="taskState"
                        id={'deviceTask' + index}
                        value={row}
                        onChange={handleFormUpdate}
                        checked={isChecked(row)}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={handleOnHide}>Cancel</Button>
                <Button variant="primary" onClick={handleSave}>Apply</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DeviceTaskModal;