import { gql } from '@apollo/client';

export const GET_PLACE_BY_GUID = gql`
    query GetPlaceByGuid($guid: ID!) {
        getPlaceByGuid(guid: $guid) {
            ok
            results {
                acres
                boundary
                endDate
                fieldName
                guid
                irrigationType
                cropYear
                wellCapacity
                pivotPassAmount
                acreInches
                senteraMapUrl
                link {
                    cropGuid
                    cropName
                    currentGrowthStage
                    maturity
                    plantingDate
                    guid
                }
                startDate
            }
        }
    }
`;

export const GET_PLACE_CROP = gql`
    query GetPlaceByGuid($guid: ID!) {
        getPlaceByGuid(guid: $guid) {
            ok
            results {
                plantedZones{
                    cropGuid
                    cropName
                    plantingDate
                    currentGrowthStage {
                        name
                    }
                }
            }
        }
    }
`;

export const GET_PLACES_BY_GROWER = gql`
query GetPlacesByGrower($guid: ID!){
    getPlacesByGrower(guid:$guid){
      ok
      results{
        fieldName
        guid
        boundary
      }
    }
  }
`;