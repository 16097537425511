import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Search, Filter, Map, XCircle } from 'react-feather';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useMutation, useQuery, useLazyQuery, NetworkStatus } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import PageWrapper from '../../components/PageWrapper';
import MapComponent from './MapComponent';
import DeviceDetails from './DeviceDetails';
import DeviceDetailsWrapper from './DeviceDetailsWrapper';
import {
    DeviceTaskModal,
    TaskStateModal,
    TechnicianModal,
} from './Modals';
import { 
    // ADD_DEVICE_LINK_OR_SAVE_LINE_ITEM, 
    SAVE_THING, SAVE_GATEWAY, 
    SAVE_LINE_ITEM, 
    REPLACE_OLD_THING_WITH_NEW_THING, 
    SAVE_LINEITEM_STATE, 
    SAVE_RELATIONSHIP_STATE, 
    SAVE_GATEWAY_STATE 
} from '../../GraphQL/Mutations/Things';
import { GET_THINGS_FOR_MAP, GET_DEVICE_GPS_HISTORY, GET_ALL_CURRENT_GPS } from '../../GraphQL/Queries/Things';
import './devices.scss';

const DevicesComponent = () => {
    const detailsRef = useRef();
    const mapColRef = useRef();
    const { addToast } = useToasts();
    const position = { lat: 40.586830986, lng: -98.38833178 };
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [deviceBackup, setDeviceBackup] = useState(null);
    const [mapSearch, setMapSearch] = useState(null);
    const [addGateway, setAddGateway] = useState(false);
    const [updateGateway, setUpdateGateway] = useState(false);

    const [gateways, setGateways] = useState(null);
    const [gatewayBackup, setGatewayBackup] = useState(null);
    const [technicians, setTechnicians] = useState(null);
    const [allCurrentGps, setAllCurrentGps] = useState(null);
    const [boundaries, setBoundaries] = useState(null);
    const [showSearchResults, setShowSearchResults] = useState(null);

    const [thingsForMap, setThingsForMap] = useState(null);

    const [deviceTaskModalOpen, setDTModalOpen] = useState(false);
    const [taskStateModalOpen, setTSModalOpen] = useState(false);
    const [technicianModalOpen, setTModalOpen] = useState(false);

    const [deviceTaskFilterList, setDeviceTaskFilterList] = useState(null);
    const [taskStateFilterList, setTaskStateFilterList] = useState(null);
    const [technicianFilterList, setTechnicianFilterList] = useState(null);

    const [gpsHistory, setGpsHistory] = useState(null);

    const {
        loading: gettingThingsForMap,
        error: errorGettingThingsForMap,
        data: thingsForMapData,
        refetch: refetchThingsForMap,
        fetchMore: thingsForMapFetchMore,
        networkStatus: orderNetworkStatus,
    } = useQuery(
        GET_THINGS_FOR_MAP,
        {
            context: { service: 'things' },
            fetchPolicy: "network-only",
            variables: { searchString: '' },
            notifyOnNetworkStatusChange: true,
        }
    );

    const {
        loading: gettingAllCurrentGps,
        error: errorGettingAllCurrentGps,
        data: gpsAllCurrentGps,
        // refetch: refetchCurrentGps,
        fetchMore: gpsForMapFetchMore,
        networkStatus: gpsNetworkStatus,
    } = useQuery(
        GET_ALL_CURRENT_GPS,
        {
            context: { service: 'things' },
            fetchPolicy: "network-only",
            variables: {},
            notifyOnNetworkStatusChange: true,
        }
    );
    // const [getAllCurrentGps, { called: allCurrentGpsCalled, loading: gettingAllCurrentGps, error: errorGettingAllCurrentGps, data: gpsAllCurrentGps }] = useLazyQuery(
    //     GET_ALL_CURRENT_GPS,
    //     { fetchPolicy: 'network-only' }
    // );
    const [getDeviceGpsHistory, { called: gpsHistoryCalled, loading: gettingGpsHistory, error: errorGettingGpsHistory, data: gpsHistoryData }] = useLazyQuery(
        GET_DEVICE_GPS_HISTORY,
        { fetchPolicy: 'network-only' }
    );



    // const [addDeviceLink, { loading: savingDeviceLink }] = useMutation(ADD_DEVICE_LINK_OR_SAVE_LINE_ITEM);
    const [saveThing] = useMutation(SAVE_THING);
    const [saveLineItem] = useMutation(SAVE_LINE_ITEM);
    const [saveGateway] = useMutation(SAVE_GATEWAY);
    const [saveRelationshipState] = useMutation(SAVE_RELATIONSHIP_STATE)
    const [saveLineItemState] = useMutation(SAVE_LINEITEM_STATE)
    const [saveGatewayState] = useMutation(SAVE_GATEWAY_STATE)
    const [replaceOldThingWithNewThing] = useMutation(REPLACE_OLD_THING_WITH_NEW_THING)
    // const [updateThing] = useMutation(UPDATE_THING);
    const [defaultZoom, setDefaultZoom] = useState(6);
    const [refreshMap, setRefreshMap] = useState(false);

    // const getCurrentGps = async () => {
    //     if (allCurrentGps !== null) {
    //         setAllCurrentGps(null);
    //     }
    //     console.log(allCurrentGps);
    //     await getAllCurrentGps({
    //         context: {
    //             service: 'things'
    //         }
    //     });
    //     console.log(allCurrentGps);
    // }


    const getGPSHistory = async () => {
        console.log("SELECTED DEVICE", selectedDevice);
        if (gpsHistory !== null) {
            setGpsHistory(null);
        }
        getDeviceGpsHistory({
            variables: {
                deveui: selectedDevice.properties.deveui,
            },
            context: {
                service: 'things'
            }
        });
    }

    const handleStateSave = async (thingToSave) => {
        let localCopy = { ...thingToSave };
        if (localCopy && !localCopy.link && localCopy.type === 'Gateway') {
            const response = await saveGatewayState({
                variables: { deveui: localCopy.deveui, state: localCopy.state },
                context: { service: 'things' }
            });
            // console.log(response);
            const { data: { saveGatewayState: { ok } } } = response;
            if (!ok) {
                addToast('There was a problem saving your changes', {
                    appearance: 'error',
                });
            }

            const localGateways = [...gateways];
            const index = localGateways.findIndex(x => x.properties.deveui === localCopy.deveui);
            let t = { ...localGateways.filter(x => x.properties.deveui === localCopy.deveui)[0] };
            const thingForMap = JSON.parse(JSON.stringify(t));

            let currentPin = thingForMap.properties.pinFileName;
            let newPin = stripColor(currentPin);
            newPin = addColor(newPin, localCopy.state);
            thingForMap.properties.pinFileName = newPin;
            thingForMap.properties.state = localCopy.state;

            localGateways[index] = thingForMap;
            setGateways(localGateways);

        } else if (localCopy && !localCopy.link && localCopy.type === 'Rain Gauge') {
            const response = await saveGatewayState({
                variables: { deveui: localCopy.deveui, state: localCopy.state },
                context: { service: 'things' }
            });
            // console.log(response);
            const { data: { saveGatewayState: { ok } } } = response;
            if (!ok) {
                addToast('There was a problem saving your changes', {
                    appearance: 'error',
                });
            } else {
                await updateLocalThings(localCopy).then(() => {
                    addToast('Changes were successfully saved', {
                        appearance: 'success',
                        autoDismiss: true,
                    });
                });
            }
        } else {
            console.log("LOCAL COPY", localCopy);
            if (localCopy && localCopy.guid) {
                const response = await saveRelationshipState({
                    variables: { guid: localCopy.guid, state: localCopy.state },
                    context: { service: 'things' }
                });
                console.log(response);
                const { data: { saveRelationshipState: { ok } } } = response;
                if (!ok) {
                    addToast('There was a problem saving your changes', {
                        appearance: 'error',
                    });
                } else {
                    //UPDATE LOCAL
                    await updateLocalThings(localCopy).then(() => {
                        addToast('Changes were successfully saved', {
                            appearance: 'success',
                            autoDismiss: true,
                        });
                        // rerenderMap();
                        // setDeviceBackup(null);
                        // closeMarkerDetails();
                    });
                }
            } else {
                const response = await saveLineItemState({
                    variables: { guid: localCopy.lineItemGuid, lineItem: localCopy.state },
                    context: { service: 'things' }
                });
                console.log(response);
                const { data: { saveLineItemState: { ok } } } = response;
                if (!ok) {
                    addToast('There was a problem saving your changes', {
                        appearance: 'error',
                    });
                } else {
                    //UPDATE LOCAL
                    await updateLocalThings(localCopy).then(() => {
                        addToast('Changes were successfully saved', {
                            appearance: 'success',
                            autoDismiss: true,
                        });
                        // rerenderMap();
                        // setDeviceBackup(null);
                        // closeMarkerDetails();
                    });;
                }
            }
        }
    }

    // const rerenderMap = async () => {
    //     setTimeout(() => {
    //         console.log("THINGS FOR MAP", thingsForMap);
    //         setThingsForMap(thingsForMap);
    //         setGateways(gateways);
    //     }, 1000);
    // }

    const updateLocalThings = async (localCopy) => {
        let thingForMap = { ...thingsForMap.filter(x => x.properties.orderGuid === localCopy.orderGuid && x.properties.lineItemGuid === localCopy.lineItemGuid)[0] };
        if (thingForMap === undefined) {
            thingForMap = { ...thingsForMap.filter(x => x.properties.deveui === localCopy.deveui)[0] };
        }
        thingForMap = JSON.parse(JSON.stringify(thingForMap));
        let index = thingsForMap.findIndex(x => x.properties.orderGuid === localCopy.orderGuid && x.properties.lineItemGuid === localCopy.lineItemGuid);
        if (index < 0) {
            index = thingsForMap.findIndex(x => x.properties.deveui === localCopy.deveui);
        }
        console.log(thingForMap, index);
        let currentPin = thingForMap.properties.pinFileName;
        console.log("CURRENT PIN", currentPin);
        let newPin = stripColor(currentPin);
        if (localCopy.link) {
            Object.keys(localCopy.link).forEach(x => {
                if (thingForMap.properties[x]) {
                    thingForMap.properties[x] = localCopy.link[x];
                }
            });
            newPin = addColor(newPin, localCopy.link.state);
        } else {
            Object.keys(localCopy).forEach(x => {
                if (thingForMap.properties[x]) {
                    thingForMap.properties[x] = localCopy[x];
                }
            });
            newPin = addColor(newPin, localCopy.state);
        }

        console.log("NEW PIN", newPin);
        thingForMap.properties.pinFileName = newPin;

        const localCopyThings = JSON.parse(JSON.stringify(thingsForMap));
        setThingsForMap(null);
        setTimeout(() => {
            localCopyThings[index] = thingForMap;
            setSelectedDevice(thingForMap);
            setDeviceBackup(thingForMap);
            setThingsForMap(localCopyThings);
        }, 1000);

        return;
    }
    const handleThingSave = async (thingToSave) => {
        let localCopy = { ...thingToSave };
        console.log("LOCAL COPY", localCopy);
        let goSaveLineItem = false;
        if ((localCopy.deveui && !localCopy.link.complete) || (localCopy.sensorSerial && !localCopy.link.complete)) {
            // console.log("SETTING COMPLETE");
            localCopy.link.complete = true;
            goSaveLineItem = true;
        }

        if (localCopy.link.textureNeeded) {
            if (localCopy.link.textureNeeded === "false") {
                localCopy.link.textureNeeded = false;
            } else if (localCopy.link.textureNeeded === "true") {
                localCopy.link.textureNeeded = true;
            }
        }

        if (localCopy.link.textureReceived) {
            if (localCopy.link.textureReceived === "false") {
                localCopy.link.textureReceived = false;
            } else if (localCopy.link.textureReceived === "true") {
                localCopy.link.textureReceived = true;
            }
        }

        if (localCopy.link.complete && localCopy.orderGuid) {
            let thingForMap = { ...thingsForMap.filter(x => x.properties.orderGuid === localCopy.orderGuid && x.properties.lineItemGuid === localCopy.lineItemGuid)[0] };
            thingForMap = JSON.parse(JSON.stringify(thingForMap));
            if (goSaveLineItem) {
                // console.log("SAVING LINE ITEM", goSaveLineItem);
                localCopy.link.deviceType = localCopy.link.type;
                delete localCopy.link.type;
                localCopy.link.guid = localCopy.link.lineItemGuid;
                delete localCopy.link.lineItemGuid;
                let orderGuid = localCopy.link.orderGuid;
                delete localCopy.link.orderGuid;
                let technician = localCopy.link.technician;
                delete localCopy.link.technician;
                let textureNeeded = localCopy.link.textureNeeded;
                delete localCopy.link.textureNeeded;
                let textureReceived = localCopy.link.textureReceived;
                delete localCopy.link.textureReceived;
                const response = await saveLineItem({
                    variables: { lineItem: localCopy.link },
                    context: { service: 'things' }
                });
                const { data: { saveLineItem: { ok } } } = response;
                if (!ok) {
                    addToast('There was a problem saving your changes', {
                        appearance: 'error',
                    });
                }
                localCopy.link.technician = technician;
                localCopy.link.textureNeeded = textureNeeded;
                localCopy.link.textureReceived = textureReceived;
                localCopy.link.lineItemGuid = localCopy.link.guid;
                localCopy.link.orderGuid = orderGuid;
            }


            if (localCopy.link.deviceType) {
                delete localCopy.link.deviceType;
            }

            // console.log("COMPLETED DEVICE");
            localCopy.unassociated = false;
            if (localCopy.oldDeveui) {
                const response = await replaceOldThingWithNewThing({
                    variables: { oldDeveui: localCopy.oldDeveui, newDeveui: localCopy.deveui, growerName: thingForMap.properties.grower.name, fieldName: thingForMap.properties.field.fieldName },
                    context: { service: 'things' }
                });
                const { data: { replaceOldThingWithNewThing: { ok } } } = response;
                if (!ok) {
                    addToast('There was a problem saving your changes', {
                        appearance: 'error',
                    });
                }
            }
            localCopy.newDevice = goSaveLineItem;
            const response = await saveThing({
                variables: localCopy,
                context: { service: 'things' }
            });
            const { data: { saveThing: { ok, success } } } = response;
            if (ok && ok === true) {
                if (success) {

                    let thingForMap = { ...thingsForMap.filter(x => x.properties.orderGuid === localCopy.orderGuid && x.properties.lineItemGuid === localCopy.lineItemGuid)[0] };
                    thingForMap = JSON.parse(JSON.stringify(thingForMap));
                    const index = thingsForMap.findIndex(x => x.properties.orderGuid === localCopy.orderGuid && x.properties.lineItemGuid === localCopy.lineItemGuid);
                    // console.log(thingForMap, index);
                    Object.keys(localCopy.link).forEach(x => {
                        if (thingForMap.properties[x]) {
                            thingForMap.properties[x] = localCopy.link[x];
                        }
                    });
                    thingForMap.properties.deveui = localCopy.deveui;
                    thingForMap.properties.deviceNotes = localCopy.link.deviceNotes;
                    thingForMap.properties.fieldAccessNotes = localCopy.link.fieldAccessNotes;
                    let currentPin = thingForMap.properties.pinFileName;
                    let newPin = stripColor(currentPin);
                    newPin = addColor(newPin, localCopy.link.state);
                    thingForMap.properties.pinFileName = newPin;

                    const localCopyThings = [...thingsForMap];
                    localCopyThings[index] = thingForMap;
                    setThingsForMap(localCopyThings);

                    addToast('Changes were successfully saved', {
                        appearance: 'success',
                        autoDismiss: true,
                    });

                    setDeviceBackup(null);
                    // if (mapSearch || (technicianFilterList && technicianFilterList.length > 0) || (taskStateFilterList && taskStateFilterList.length > 0) || (deviceTaskFilterList && deviceTaskFilterList.length > 0)) {
                    //     searchThingsForMap(mapSearch);
                    // } else {
                    //     refetchThingsForMap();
                    // }

                    if (!showSearchResults) {
                        closeMarkerDetails();
                    } else {
                        setSelectedDevice(null);
                    }

                } else {
                    addToast('There was an error saving the device. The deveui may not be valid.', {
                        appearance: 'error',
                    });
                }
            } else {
                addToast('There was a problem saving your changes', {
                    appearance: 'error',
                });
            }
        } else if (localCopy.orderGuid === null && localCopy.link.type === "Rain Gauge") {
            console.log("Unassociated");
            let thingForMap = { ...thingsForMap.filter(x => x.properties.guid === localCopy.guid)[0] };
            thingForMap = JSON.parse(JSON.stringify(thingForMap));

            if (localCopy.oldDeveui) {
                const response = await replaceOldThingWithNewThing({
                    variables: { oldDeveui: localCopy.oldDeveui, newDeveui: localCopy.deveui, growerName: thingForMap.properties.grower.name, fieldName: thingForMap.properties.field.fieldName },
                    context: { service: 'things' }
                });
                const { data: { replaceOldThingWithNewThing: { ok } } } = response;
                if (!ok) {
                    addToast('There was a problem saving your changes', {
                        appearance: 'error',
                    });
                }
            }
            localCopy.newDevice = false;
            localCopy.unassociated = true;
            const response = await saveThing({
                variables: localCopy,
                context: { service: 'things' }
            });
            const { data: { saveThing: { ok, success } } } = response;
            if (ok && ok === true) {
                if (success) {

                    let thingForMap = { ...thingsForMap.filter(x => x.properties.orderGuid === localCopy.orderGuid && x.properties.lineItemGuid === localCopy.lineItemGuid)[0] };
                    thingForMap = JSON.parse(JSON.stringify(thingForMap));
                    const index = thingsForMap.findIndex(x => x.properties.orderGuid === localCopy.orderGuid && x.properties.lineItemGuid === localCopy.lineItemGuid);
                    // console.log(thingForMap, index);
                    Object.keys(localCopy.link).forEach(x => {
                        if (thingForMap.properties[x]) {
                            thingForMap.properties[x] = localCopy.link[x];
                        }
                    });
                    thingForMap.properties.deveui = localCopy.deveui;
                    let currentPin = thingForMap.properties.pinFileName;
                    let newPin = stripColor(currentPin);
                    newPin = addColor(newPin, localCopy.link.state);
                    thingForMap.properties.pinFileName = newPin;

                    const localCopyThings = [...thingsForMap];
                    localCopyThings[index] = thingForMap;
                    setThingsForMap(localCopyThings);

                    addToast('Changes were successfully saved', {
                        appearance: 'success',
                        autoDismiss: true,
                    });

                    setDeviceBackup(null);
                    // if (mapSearch || (technicianFilterList && technicianFilterList.length > 0) || (taskStateFilterList && taskStateFilterList.length > 0) || (deviceTaskFilterList && deviceTaskFilterList.length > 0)) {
                    //     searchThingsForMap(mapSearch);
                    // } else {
                    //     refetchThingsForMap();
                    // }

                    if (!showSearchResults) {
                        closeMarkerDetails();
                    } else {
                        setSelectedDevice(null);
                    }

                } else {
                    addToast('There was an error saving the device. The deveui may not be valid.', {
                        appearance: 'error',
                    });
                }
            } else {
                addToast('There was a problem saving your changes', {
                    appearance: 'error',
                });
            }
        } else {
            console.log("LINE ITEM", localCopy);
            localCopy.link.deviceType = localCopy.link.type;
            delete localCopy.link.type;
            localCopy.link.guid = localCopy.link.lineItemGuid;
            delete localCopy.link.lineItemGuid;

            const response = await saveLineItem({
                variables: { lineItem: localCopy.link },
                context: { service: 'things' }
            });
            const { data: { saveLineItem: { ok } } } = response;
            if (ok && ok === true) {
                let thingForMap = { ...thingsForMap.filter(x => x.properties.orderGuid === localCopy.orderGuid && x.properties.lineItemGuid === localCopy.lineItemGuid)[0] };
                thingForMap = JSON.parse(JSON.stringify(thingForMap));
                const index = thingsForMap.findIndex(x => x.properties.orderGuid === localCopy.orderGuid && x.properties.lineItemGuid === localCopy.lineItemGuid);
                // console.log(thingForMap, index);
                Object.keys(localCopy.link).forEach(x => {
                    if (thingForMap.properties[x]) {
                        thingForMap.properties[x] = localCopy.link[x];
                    }
                });

                let currentPin = thingForMap.properties.pinFileName;
                let newPin = stripColor(currentPin);
                newPin = addColor(newPin, localCopy.link.state);
                thingForMap.properties.pinFileName = newPin;

                const localCopyThings = [...thingsForMap];
                localCopyThings[index] = thingForMap;
                setThingsForMap(localCopyThings);

                addToast('Changes were successfully saved', {
                    appearance: 'success',
                    autoDismiss: true,
                });

                setDeviceBackup(null);
                // if (mapSearch || (technicianFilterList && technicianFilterList.length > 0) || (taskStateFilterList && taskStateFilterList.length > 0) || (deviceTaskFilterList && deviceTaskFilterList.length > 0)) {
                //     searchThingsForMap(mapSearch);
                // } else {
                //     refetchThingsForMap();
                // }

                if (!showSearchResults) {
                    closeMarkerDetails();
                } else {
                    setSelectedDevice(null);
                }

            } else {
                addToast('There was a problem saving your changes', {
                    appearance: 'error',
                });
            }
        }

    }
    // function getPinBaseName(type) {
    //     let pinFileName = '';
    //     if (type) {
    //         switch (type) {
    //             case 'Gateway':
    //                 pinFileName = 'pinGateway';
    //                 break;

    //             case 'Pivot Monitor':
    //             case 'Pivot':
    //             case 'Service Ready to Perform':
    //                 pinFileName = 'pinPivotStatus';
    //                 break;

    //             case 'Rain':
    //             case 'Rain Gauge':
    //                 pinFileName = 'pinRainGauge';
    //                 break;

    //             case 'Capacitance Probe':
    //             case 'Capacitance':
    //             case 'Watermark Probe':
    //             case 'Watermark':
    //                 pinFileName = 'pinProbe';
    //                 break;

    //             case 'Weather Station':
    //                 pinFileName = 'pinWeatherStation';
    //                 break;

    //             default:
    //                 pinFileName = 'pinLab';
    //                 break;
    //         }
    //     } else {
    //         console.log("OOPSY", 'pinLab');
    //     }
    //     return pinFileName;
    // }
    const stripColor = (nameIn) => {
        let pinFileName = nameIn;
        if (nameIn) {
            pinFileName = pinFileName.replace('Silver.svg', '');
            pinFileName = pinFileName.replace('Darkorange.svg', '');
            pinFileName = pinFileName.replace('Green.svg', '');
            pinFileName = pinFileName.replace('Yellow.svg', '');
            pinFileName = pinFileName.replace('Red.svg', '');
            pinFileName = pinFileName.replace('Gray.svg', '');
            pinFileName = pinFileName.replace('Blue.svg', '');
            pinFileName = pinFileName.replace('Sage.svg', '');
            pinFileName = pinFileName.replace('Magenta.svg', '');
        }
        return pinFileName;
    }
    const addColor = (nameIn, state) => {
        let pinFileName = nameIn;
        if (nameIn) {
            if (!pinFileName.includes('.svg')) {
                switch (state) {
                    case 'Not Ready':
                        pinFileName += 'Silver.svg';
                        break;

                    case 'Ready To Install':
                    case 'Ready to Extract':
                    case 'Service Ready to Perform':
                        pinFileName += 'Darkorange.svg';
                        break;

                    case 'Installed':
                    case 'Service in Progress':
                        pinFileName += 'Green.svg';
                        break;

                    case 'Check Device':
                        pinFileName += 'Yellow.svg';
                        break;

                    case 'Needs Repair':
                    case 'Replace Batteries':
                    case 'Replace Gateway':
                    case 'Replace Device':
                        pinFileName += 'Red.svg';
                        break;

                    case 'URGENT Extract':
                        pinFileName += 'Magenta.svg';
                        break;

                    case 'Extracted':
                        pinFileName += 'Gray.svg';
                        break;

                    case 'Service Complete':
                        pinFileName += 'Blue.svg';
                        break;

                    default:
                        pinFileName += 'Silver.svg';
                        break;
                }
            } else {
                console.log("OOPSY", pinFileName);
            }
        }
        return pinFileName;
    }

    const handleUnassociatedSave = async (guid, thingToSave) => {
        setUpdateGateway(true);
        var localCopy = { ...thingToSave };
        delete localCopy.growthStage;
        delete localCopy.cropGuid;
        delete localCopy.lineItemGuid;
        delete localCopy.complete;
        delete localCopy.avgFullSF;
        delete localCopy.avgWiltSF;
        delete localCopy.fullSF;
        delete localCopy.wiltSF;
        delete localCopy.startDate;
        if (localCopy.state === 'Installed'){
            localCopy.pinFileName='pinGatewayGreen.svg'
        }
        var params = { guid, thing: localCopy };
        const response = await saveGateway({
            variables: params,
            context: { service: 'things' }
        });

        const { data: { saveGateway: { ok } } } = response;
        if (ok && ok === true) {
            addToast('Changes were successfully saved', {
                appearance: 'success',
                autoDismiss: true,
            });

            setDeviceBackup(null);
            refetchThingsForMap();
            searchThingsForMap(mapSearch);

            if (!showSearchResults) {
                closeMarkerDetails();
            } else {
                setSelectedDevice(null);
            }

        } else {
            addToast('There was a problem saving your changes', {
                appearance: 'error',
            });
        }
    }

    const handleCardOpen = (card) => {
        // console.log("CARD", card);
        setSelectedDevice(card);
        setDeviceBackup(card);
    }

    const handleSearchButton = async (event) => {
        event.preventDefault();
        setMapSearch(event.target[0].value);
        searchThingsForMap(event.target[0].value);
    }

    const handleOpenMap = () => {
        const classes = mapColRef.current.classList;

        if (classes.contains('opened')) {
            mapColRef.current.classList.remove('opened');
        } else {
            mapColRef.current.classList.add('opened');
        }
    }

    const handleAddGateway = () => {
        setAddGateway(true);
    }


    const handleAddPin = async (markers) => {
        if (markers !== null) {
            gatewayHandled(true);
            const response = await thingsForMapFetchMore({});
            const { data: { getThingsForMap: { results } } } = response;
            const justGateways = results[0].features.filter((x) => { return x.properties.type === "Gateway" });
            setGateways([...justGateways]);
        }
    }

    const closeMarkerDetails = useCallback(() => {
        detailsRef.current.classList.remove('open');
        setSelectedDevice(null);
        setGpsHistory(null);
        if (deviceBackup !== null) {
            // console.log("BACKUP", deviceBackup);
            var index = thingsForMap.findIndex(x => x.properties.guid === deviceBackup.properties.guid);
            // console.log("INDEX", thingsForMap[index]);
            thingsForMap[index] = deviceBackup;
            setDeviceBackup(null);
        }
    }, [detailsRef, deviceBackup, setSelectedDevice, thingsForMap, setDeviceBackup])

    const gatewayHandled = useCallback((close) => {
        setAddGateway(false);
        setUpdateGateway(false);
        if (close) {
            closeMarkerDetails();
        }
    }, [setAddGateway, setUpdateGateway, closeMarkerDetails]);

    const searchThingsForMap = async (searchString) => {
        const deviceTypeFilter = deviceTaskFilterList === null ? [] : deviceTaskFilterList;
        const stateFilter = taskStateFilterList === null ? [] : taskStateFilterList;
        const technicianFilter = technicianFilterList === null ? [] : technicianFilterList;
        if (searchString === undefined || searchString === null) {
            searchString = "";
        }
        // console.log('here', deviceTypeFilter);
        if (deviceTypeFilter.filter(x => x === "All Current GPS").length > 0) {

            const response = await gpsForMapFetchMore({});
            const { data: { getAllCurrentGps } } = response;
            const results = getAllCurrentGps.results;
            setThingsForMap([...results[0].features]);
            setAllCurrentGps([...results[0].features])
            // setThingsForMap([]);
        } else {
            setAllCurrentGps(null);
            const variables = {
                searchString,
                deviceTypeFilter,
                stateFilter,
                technicianFilter,
            }
            const response = await thingsForMapFetchMore({
                variables
            });
            const { data: { getThingsForMap } } = response;
            const results = getThingsForMap.results;
            const justDevices = results[0].features.filter((x) => { return x.properties.pinFileName !== "pinHouseBlue.svg" });


            setThingsForMap([...justDevices]);
            if (technicians === null) {
                const justTechnicians = results[0].features.filter((x) => { return x.properties.pinFileName === "pinHouseBlue.svg" });
                setTechnicians([...justTechnicians]);
            }

            if (gateways === null || addGateway || updateGateway || deviceTypeFilter === null || deviceTypeFilter.filter(x => x === "Gateway").length > 0) {
                if (addGateway || updateGateway) {
                    gatewayHandled(true);
                }
                if (searchString === "") {
                    const justGateways = results[0].features.filter((x) => { return x.properties.type === "Gateway" });
                    if (justGateways.length > 0 && deviceTypeFilter.length === 0 && stateFilter.length === 0 && technicianFilter === 0) {
                        setGateways([...justGateways]);
                        if (!gatewayBackup) {
                            setGatewayBackup([...justGateways]);
                        }
                    }
                } else {
                    if (deviceTypeFilter.length === 0 && stateFilter.length === 0 && technicianFilter === 0) {
                        setGateways([...gatewayBackup]);
                    } else if (deviceTypeFilter.length > 0 && deviceTypeFilter.filter(x => x === "Gateway").length === 0) {
                        setGateways([]);
                    } else {
                        const justGateways = results[0].features.filter((x) => { return x.properties.type === "Gateway" });
                        setGateways([...justGateways]);
                    }
                }
            } else if (deviceTypeFilter.length > 0 && deviceTypeFilter.filter(x => x === "Gateway").length === 0) {
                setGateways([]);
            }
            setShowSearchResults((searchString !== ''));

            const resultsWithBoundaries = results[0].features.filter((x) => { return x.properties.boundary && x.properties.boundary.features });
            const justBoundaries = resultsWithBoundaries.map(x => x.properties.boundary.features)
            setBoundaries([...justBoundaries]);
        }
    }

    const resetFilters = () => {
        setDeviceTaskFilterList(null);
        setTaskStateFilterList(null);
        setTechnicianFilterList(null);
    }

    const openFilterModal = (modal) => {
        switch (modal) {
            case 'taskState':
                setTSModalOpen(true);
                break;

            case 'deviceTask':
                setDTModalOpen(true);
                break;

            case 'technician':
                setTModalOpen(true);
                break;

            default:
                setTSModalOpen(false);
                setDTModalOpen(false);
                setTModalOpen(false);
                break;
        }
    }

    const filterByTaskState = (taskStateList) => {
        setTaskStateFilterList(taskStateList);
    }

    const filterByDeviceTask = (deviceTaskList) => {
        setDeviceTaskFilterList(deviceTaskList)
    }

    const filterByTechnician = (techList) => {
        setTechnicianFilterList(techList);
    }

    const handleUpdatingSelectedDevice = (device) => {
        // console.log("DEVICE", device);
        setSelectedDevice(device);
    }

    const handleDeviceBackup = (device) => {
        setDeviceBackup(device);
    }

    const deviceReview = (event) => {
        console.log(event);
    }

    const installDevice = (event) => {
        console.log(event);
    }

    const runDiagnostics = (event) => {
        console.log(event);
    }

    const settings = (event) => {
        console.log(event);
    }

    const contextMenuOptions = () => {
        return (
            <ul>
                <li onClick={deviceReview}>Device Review</li>
                <li onClick={installDevice}>Install Device</li>
                <li onClick={runDiagnostics}>Run Diagnostics</li>
                <li className="spacer">&nbsp;</li>
                <li onClick={settings}>Settings</li>
            </ul>
        )
    }

    const expandMarkerDetails = (thing) => {
        if (detailsRef.current && (thing.properties.orderGuid || thing.properties.deveui || thing.properties.deviceGuid)) {
            if (selectedDevice !== null && thing.guid === thing.properties.orderGuid + '' + thing.properties.lineItemGuid + '' + thing.properties.deveui) {
                closeMarkerDetails();
            } else {
                detailsRef.current.classList.add('open');
                handleCardOpen({ ...thing });
            }

        } else {
            handleCardOpen({ ...thing });
            if (selectedDevice !== null && deviceBackup !== null && JSON.stringify(deviceBackup) !== '{}') {
                setDefaultZoom(null);
                setRefreshMap(true);
                var index = thingsForMap.findIndex(x => x.properties.guid === deviceBackup.properties.guid);
                thingsForMap[index] = deviceBackup;
            } else {
                setRefreshMap(false);
            }
        }
    }



    const showAllAndResetZoom = async () => {
        if (detailsRef.current) {
            detailsRef.current.classList.remove('open');
        }

        resetFilters();
        window.setTimeout(() => {
            setMapSearch('');
            searchThingsForMap('');
            setGpsHistory(null);
            // handleFilterAndSearch('');
            setSelectedDevice(null);
        }, 500)
    }

    useEffect(() => {
        if (deviceBackup !== null) {
            // console.log("DEVICE BACKUP", deviceBackup);
        }
    }, [deviceBackup])

    useEffect(() => {
        const deviceTypeFilter = deviceTaskFilterList === null ? [] : deviceTaskFilterList;
        const stateFilter = taskStateFilterList === null ? [] : taskStateFilterList;
        const technicianFilter = technicianFilterList === null ? [] : technicianFilterList;
        if (!gettingThingsForMap && !errorGettingThingsForMap && orderNetworkStatus === NetworkStatus.ready && mapSearch === null
            && deviceTypeFilter.length === 0 && stateFilter.length === 0 && technicianFilter.length === 0) {
            // console.log("FETCHING ALL THINGS", mapSearch);
            const { getThingsForMap: { results } } = thingsForMapData;
            if (results) {
                const justDevices = results[0].features.filter((x) => { return x.properties.pinFileName !== "pinHouseBlue.svg" });
                setThingsForMap([...justDevices]);
                setMapSearch('');
                if (technicians === null) {
                    const justTechnicians = results[0].features.filter((x) => { return x.properties.pinFileName === "pinHouseBlue.svg" });
                    setTechnicians([...justTechnicians]);
                }

                if (gateways === null || addGateway || updateGateway || deviceTypeFilter.length === 0 || deviceTypeFilter.length === 0 || deviceTypeFilter.filter(x => x === "Gateway").length > 0) {
                    if (addGateway || updateGateway) {
                        gatewayHandled(true);
                    }
                    const justGateways = results[0].features.filter((x) => { return x.properties.type === "Gateway" });
                    if (justGateways.length > 0) {
                        setGateways([...justGateways]);
                        if (!gatewayBackup) {
                            setGatewayBackup([...justGateways]);
                        }
                    }
                }

                setSelectedDevice(null);
                const resultsWithBoundaries = results[0].features.filter((x) => { return x.properties.boundary && x.properties.boundary.features });
                const justBoundaries = resultsWithBoundaries.map(x => x.properties.boundary.features);
                setBoundaries([...justBoundaries]);
            }
        }
    }, [gettingThingsForMap, errorGettingThingsForMap, thingsForMapData, orderNetworkStatus, mapSearch, addGateway, deviceTaskFilterList, gatewayBackup, gatewayHandled, gateways, taskStateFilterList, technicianFilterList, technicians, updateGateway]);

    useEffect(() => {
        if (!gettingAllCurrentGps && !errorGettingAllCurrentGps && allCurrentGps !== null && gpsNetworkStatus === NetworkStatus.ready) {
            const { getAllCurrentGps: { results } } = gpsAllCurrentGps;
            if (results !== null && results.length > 0) {
                setAllCurrentGps(results[0].features);
            }
        }
    }, [gettingAllCurrentGps, errorGettingAllCurrentGps, gpsAllCurrentGps, allCurrentGps, gpsNetworkStatus]);

    useEffect(() => {
        if (
            deviceTaskFilterList !== null ||
            taskStateFilterList !== null ||
            technicianFilterList !== null
        ) {
            // console.log("FILTERS FOUND, BEGINNING SEARCH");
            // handleFilterAndSearch(mapSearch);
            searchThingsForMap(mapSearch);
        }
        // eslint-disable-next-line
    }, [deviceTaskFilterList, taskStateFilterList, technicianFilterList]);

    useEffect(() => {
        if (gpsHistoryCalled && !gettingGpsHistory && !errorGettingGpsHistory && gpsHistoryData !== null) {
            const { getDeviceGpsHistory: { results } } = gpsHistoryData;
            if (results !== null && results.length > 0) {
                setGpsHistory(results[0].features);
            }
        }
    }, [gettingGpsHistory, errorGettingGpsHistory, gpsHistoryData, gpsHistoryCalled]);


    return (
        <PageWrapper className="devices-page-wrapper" contextMenu={contextMenuOptions}>
            {!showSearchResults && allCurrentGps === null &&
                <div className="marker-details-column" ref={detailsRef}>
                    {selectedDevice !== null &&
                        <DeviceDetails
                            card={selectedDevice}
                            selectedDevice={selectedDevice}
                            updateSelectedDevice={handleUpdatingSelectedDevice}
                            updateDeviceBackup={handleDeviceBackup}
                            asAccordion={false}
                            closeMarkerDetails={closeMarkerDetails}
                            handleThingSave={handleThingSave}
                            handleStateSave={handleStateSave}
                            handleUnassociatedSave={handleUnassociatedSave}
                            // savingDeviceLink={savingDeviceLink}
                            addGateway={handleAddGateway}
                            getDeviceGPSHistory={getGPSHistory}
                        />
                    }
                </div>
            }
            <div className="content-wrapper">
                <div className="search-and-filter-wrapper">
                    <Row className="search-and-filter-row">
                        <Col sm={12} md={12} lg={4} className="search-wrapper">
                            <Form onSubmit={(event) => handleSearchButton(event)}>
                                <Form.Control
                                    type="text"
                                    placeholder="Search"
                                    name="mapSearch"
                                    // onChange={(event) => setMapSearch(event.target.value)}
                                    // onBlur={(event) => { console.log("BLURRED", event); setMapSearch(event.target.value) }}
                                    // onSubmit={(event) => {console.log("SUBMITTED",event); setMapSearch(event.target.value)}}
                                    // onKeyUp={handleSearchKeyUp}
                                    defaultValue={mapSearch}
                                />
                                <Button type="submit" variant="primary" ><Search /></Button>
                            </Form>
                        </Col>
                        <Col sm={12} md={12} lg={6} className="filter-buttons-wrapper">
                            <Row>
                                <Col xs={12} md={4}>
                                    <Button
                                        variant="outline-dark"
                                        onClick={() => { openFilterModal('taskState'); }}
                                    >
                                        <Filter size={14} /> Task State
                                    </Button>
                                </Col>
                                <Col xs={12} md={4}>
                                    <Button
                                        variant="outline-dark"
                                        onClick={() => { openFilterModal('deviceTask'); }}
                                    >
                                        <Filter size={14} /> Device / Task
                                    </Button>
                                </Col>
                                <Col xs={12} md={4}>
                                    <Button
                                        variant="outline-dark"
                                        onClick={() => { openFilterModal('technician'); }}
                                    >
                                        <Filter size={14} /> Technician
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={10} sm={10} md={12} lg={2} className="show-all-button">
                            <Button variant="primary" onClick={showAllAndResetZoom}>Show All </Button>
                        </Col>
                        <Col xs={2} sm={2} md={2} className="display-map-icon-col">
                            <Button
                                variant="outline-dark"
                                onClick={() => { handleOpenMap('technician'); }}
                            >
                                <Map />
                            </Button>
                        </Col>
                    </Row>
                </div>
                <Row className="device-map-row">
                    {showSearchResults &&
                        <Col xs={12} md={4} lg={4} id="deviceDetailsColumn" className="device-details-column">
                            <DeviceDetailsWrapper
                                asAccordion={true}
                                //orders={incompleteOrders}
                                thingsForMap={thingsForMap}
                                closeMarkerDetails={closeMarkerDetails}
                                onDeviceSelect={expandMarkerDetails}
                                handleThingSave={handleThingSave}
                                handleStateSave={handleStateSave}
                                handleUnassociatedSave={handleUnassociatedSave}
                                getDeviceGPSHistory={getGPSHistory}
                                // savingDeviceLink={savingDeviceLink}
                                selectedDevice={selectedDevice}
                                updateSelectedDevice={handleUpdatingSelectedDevice}
                                updateDeviceBackup={handleDeviceBackup}
                                addGateway={handleAddGateway}
                            />
                        </Col>
                    }
                    <Col
                        xs={12}
                        md={(showSearchResults) ? 8 : 12}
                        lg={(showSearchResults) ? 8 : 12}
                        className="map-container"
                        ref={mapColRef}
                    >
                        <div className="map-button-row">
                            <button className="map-row-button" onClick={handleOpenMap}><XCircle size={32} /></button>
                        </div>
                        <MapComponent
                            // allThings={allThingsList}
                            // incompleteOrders={incompleteOrders}
                            thingsForMap={thingsForMap}
                            defaultZoom={defaultZoom}
                            gateways={gateways}
                            technicians={technicians}
                            allCurrentGps={allCurrentGps}
                            boundaries={boundaries}
                            refreshMap={refreshMap}
                            // incompleteOrders={incompleteOrders}
                            // onOrderItemClick={expandOrderItemDetails}
                            position={position}
                            selectedDevice={selectedDevice}
                            backupDevice={deviceBackup}
                            gpsHistory={gpsHistory}
                            // data={mapData}
                            addingGateway={addGateway}
                            handleAddPin={handleAddPin}
                            onMarkerClick={expandMarkerDetails}
                        />
                    </Col>
                </Row>
            </div>
            {/* Modals */}
            <DeviceTaskModal
                isOpen={deviceTaskModalOpen}
                setModalOpen={setDTModalOpen}
                filter={filterByDeviceTask}
                filterList={deviceTaskFilterList}
            />
            <TaskStateModal
                isOpen={taskStateModalOpen}
                setModalOpen={setTSModalOpen}
                filter={filterByTaskState}
                filterList={taskStateFilterList}
            />
            <TechnicianModal
                isOpen={technicianModalOpen}
                setModalOpen={setTModalOpen}
                filter={filterByTechnician}
                filterList={technicianFilterList}
            />
        </PageWrapper>
    );
}

export default React.memo(DevicesComponent);