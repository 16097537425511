import React from 'react';
import { Redirect } from 'react-router-dom';
import client from '../../gqlClient';
import { 
    clearSession,
} from '../../services/User';

const Logout = () => {
    clearSession();

    client.resetStore();
    
    return(
        <Redirect to="/login" />
    );
}

export default Logout;