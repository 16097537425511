import React, { useState, useEffect } from 'react';
import { Accordion} from 'react-bootstrap';
import { useQuery } from "@apollo/client";
import GrowerCard from './GrowerCard';
import Spinner from '../../common/Spinner';
import { sortCollection } from '../../services/Data';
import { 
    GET_AGRONOMIST_GROWERS,
} from '../../GraphQL/Queries/User';

const Growers = (props) => {
    const {
        agronomist,
        guid,
    } = props;
    
    const [ growerOpen, setGrowerOpen ] = useState(null);
    const [ growersList, setGrowersList ] = useState(null);
    const { 
        loading: gettingGrowers, 
        error: growersError, 
        data: growerResults 
    } = useQuery(
        GET_AGRONOMIST_GROWERS, 
        { 
            variables: { guid: guid || agronomist.guid },
            context: { service: 'people'}
        }
    );
    
    const handleGrowerOpen = (grower) => {
        if ( grower !== growerOpen ) {
            setGrowerOpen(grower);
        } else {
            setGrowerOpen(null);
        }
    }

    const hasGrowesrList = () => {
        return ( growersList && growersList !== null && growersList.hasOwnProperty('length') && growersList.length > 0 );
    }

    useEffect(() => {
        if ( !gettingGrowers && !growersError ) {
            const { getAgronomistGrowers } = growerResults;
            const { results } = getAgronomistGrowers;
            const sorted = sortCollection(results, { 'name': 'asc' } );
            
            setGrowersList(sorted);
        }
    }, [growerResults, gettingGrowers, growersError]);

    if ( gettingGrowers ) {
        return(<Spinner align="left">Getting Growers</Spinner>)
    }

    return(
        hasGrowesrList() ? (
            <Accordion>
                {growersList.map((grower, index) =>
                    <GrowerCard 
                        key={index}
                        grower={grower} 
                        handleGrowerOpen={handleGrowerOpen}
                        growerOpen={growerOpen}
                    />
                )}
            </Accordion>
        ) : (
            <div>No Growers Found</div>
        )
    )
}

export default Growers;