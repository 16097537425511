import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import client from './gqlClient';
import AppWrapper from './components/AppWrapper';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/styles.scss';

const BaseApp = () => {

    return(
        <ApolloProvider client={client}>
            <BrowserRouter>
                <AppWrapper />
            </BrowserRouter>
        </ApolloProvider>
    );
};

ReactDOM.render(
    <BaseApp />
    , document.getElementById('root'),
);
