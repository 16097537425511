import React from 'react';

const IrrigationTable = (props) => {
    const { fieldPreview, plt: { place, link } } = props;
    // const link = place.link;
    // console.log("LINK", link);

    const setFixed = (value, pos) => {
        if (value === null) {
            return 0.00;
        }

        return Number(value).toFixed((pos) ? pos : 3);
    }

    const getPivotPropertyValue = (prop) => {

        if (place === null) {
            return '';
        }

        return place[prop];
    }

    const getCropPropertyValue = (prop) => {
        if (place === null) {
            return '';
        }

        let link = { ...place.link };


        if (Object.keys(link).length === 0) {
            return '';
        }

        if (link) {
            if (prop === 'cropName' && link.crop && link.crop.guid) {
                return link.crop.name;
            } else if (link[prop]) {
                return link[prop];
            } else {
                return '';
            }
        }

        return '';
    }

    const returnET = () => {
        if (!fieldPreview || fieldPreview === null || !fieldPreview.et) {
            return '';
        }

        return Math.round((Number(fieldPreview.et) + Number.EPSILON) * 100) / 100;
    }

    return (
        <div className="irrigation-grid">
            <div>
                <div>Crop</div>
                <div className="irrigation-spec">
                    {getCropPropertyValue('cropName') || '-'}
                </div>
            </div>
            <div>
                <div>Growth Stage</div>
                <div className="irrigation-spec">
                    {getCropPropertyValue('currentGrowthStage') || '-'}
                </div>
            </div>
            <div>
                <div>Evapotranspiration</div>
                <div className="irrigation-spec">
                    {returnET() || '-'}
                </div>
            </div>
            <div>
                <div>Field Size</div>
                <div className="irrigation-spec">
                    {setFixed(place.acres, 2) || '-'}
                </div>
            </div>
            <div>
                <div>Irrigation Type</div>
                <div className="irrigation-spec">
                    {place.irrigationType || '-'}
                </div>
            </div>
            <div>
                <div>Well Capacity</div>
                <div className="irrigation-spec">
                    {Math.round(getPivotPropertyValue('wellCapacity')) + 'GPM' || '-'}
                </div>
            </div>
            <div>
                <div>Pivot Pass Amount</div>
                <div className="irrigation-spec">
                    {setFixed(getPivotPropertyValue('pivotPassAmount')) + '"' || '-'}
                </div>
            </div>
            <div>
                <div>1 ft Soil Texture</div>

                {link ? <div className="irrigation-spec">
                    {link.oneFtSoilTexture || '-'}
                </div> : <div className="irrigation-spec">
                    {'-'}
                </div>}
            </div>
            <div>
                <div>2 ft Soil Texture</div>
                {link ? <div className="irrigation-spec">
                    {link.twoFtSoilTexture || '-'}
                </div> : <div className="irrigation-spec">
                    {'-'}
                </div>}
            </div>
            <div>
                <div>3 ft Soil Texture</div>
                {link ? <div className="irrigation-spec">
                    {link.threeFtSoilTexture || '-'}
                </div> : <div className="irrigation-spec">
                    {'-'}
                </div>}
            </div>
        </div>
        //     <div className="irrigation-spec">
        //         {place.area ? `${place.area} acres` : '-'}
        //     </div>
        // </div>
    );
};

export default IrrigationTable;
