import React from 'react';
import { Link } from 'react-router-dom';

import { Menu, MoreVertical } from 'react-feather';
import ServiTechLogo from '../../common/logos/servitech-logo.svg';
import TopNav from '../TopNav';
import PrivateTopNav from '../PrivateTopNav';
import { isLoggedIn } from '../../services/User';
import { getIsServitechUser } from '../../services/User';

import './pageHeader.scss';

const menuOptions = [
    {
        route: 'settings',
        label: 'Settings',
    },
    {
        url: 'https://brider.servitech.com',
        label: 'ServiTech Bridge',
    },
    {
        url: 'https://poeplemanager.servitech.com',
        label: 'People Manager',
    },
    {
        url: 'https://capture.servitech.com',
        label: 'Sample Capture',
    },
    {
        url: 'https://intranet.servitech.com',
        label: 'Intranet',
    },
    {
        route: 'logout',
        label: 'Logout',
    },
];

const PageHeader = (props) => {
    const contextRef = React.useRef(null);
    const contextIconRef = React.useRef(null);
    const contextListRef = React.useRef(null);

    const menuRef = React.useRef(null);
    const menuIconRef = React.useRef(null);
    const menuItemsRef = React.useRef(null);

    return(
        <header className="page-header">
            {isLoggedIn() &&
                <div className="header-col">
                    <div className="menu-wrapper" ref={menuRef}>
                        <div className="menu-icon" ref={menuIconRef}>
                            <Menu />
                        </div>
                        <div className="menu-icon-shadow">&nbsp;</div>
                        <ul className="menu-options" ref={menuItemsRef}>
                            {menuOptions.map((option, index) =>
                                <React.Fragment key={index}>
                                {(index === 1 || index === 5 ) &&
                                    <li className="spacer">&nbsp;</li>
                                }
                                {(option.route) ? (
                                    <Link to={option.route}><li>{option.label}</li></Link>
                                ) : (
                                    <a href={option.route} target="_new"><li>{option.label}</li></a>
                                )}
                                </React.Fragment>
                            )}
                        </ul>
                    </div>
                </div>
            }
            <div className={(!props.contextMenu) ? "header-col full-width": "header-col"}>
                <div className="nav-scroller">
                    <div className="logo-image">
                        <img alt="ServiTech" className="logo" src={ServiTechLogo} />
                    </div>
                    <div className="navigation-wrapper">
                        {isLoggedIn() && getIsServitechUser() === true &&
                            <PrivateTopNav />
                        }
                        {isLoggedIn() && getIsServitechUser() !== true &&
                            <TopNav/>
                        }
                    </div>
                </div>
            </div>
            { (isLoggedIn() && props.contextMenu) &&
                <div className="header-col">
                    <div className="context-wrapper" ref={contextRef}>
                        <div className="more-icon" ref={contextIconRef}>
                            <MoreVertical />
                        </div>
                        {props.contextMenu && 
                            <>
                                <div className="menu-icon-shadow">&nbsp;</div>
                                <div className="context-menu" ref={contextListRef}>
                                    <props.contextMenu />
                                </div>
                            </>
                        }
                    </div>
                </div>
            }
        </header>
    );
}

export default PageHeader;