import React, { useEffect, useState, } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
const listItems = [
    'All',
    'Not Ready',
    'Ready To Install',
    'Installed',
    'Check Device',
    'Needs Repair',
    'Replace Batteries',
    'Replace SID',
    'Replace Gateway',
    'Replace Device',
    'Ready to Extract',
    'URGENT Extract',
    'Extracted',
    'Service Ready to Perform',
    'Service in Progress',
    'Service Complete'
];

const TaskStateModal = (props) => {
    const {
        filter,
        filterList,
        isOpen,
        setModalOpen
    } = props;

    const [ taskState, setTaskState ] = useState([]);

    const handleOnHide = () => {
        setModalOpen(!isOpen);
    }

    const handleSave = () => {
        setModalOpen(!isOpen);
        filter(taskState);
    }

    const isChecked = (id) => {
        return ( taskState.indexOf(id.toString()) > -1 );
    }

    const handleFormUpdate = (event) => {
        const value = event.target.value.toString();
        let tState = taskState;
        const index = tState.indexOf(event.target.value);

        // All is selected for first time: add all items to the list
        if ( value === "All" && index < 0 ) {
            tState = [];
            for ( let i = 0; i < listItems.length; i++ ) {
                tState.push(listItems[i].toString());
            }

            setTaskState([...tState]);

        // All is selected, but being unchecked: remove all items
        } else if ( value === "All" && index > -1 ) {
            setTaskState([]);

        // Add/Remove one item at a time as their selected
        } else {

            if ( index > -1 ) {
                tState.splice(index, 1);
            } else {
                tState.push(event.target.value);
            }

            setTaskState([...tState]);
        }
    }

    useEffect(() => {
        if ( filterList === null && taskState !== null ) {
            setTaskState([]);
        }
        if ( filterList !== null && filterList !== taskState ) {
            setTaskState([...filterList]);
        }
    // eslint-disable-next-line
    }, [filterList]);

    return(
        <Modal
            show={isOpen}
            onHide={handleOnHide}
            size="sm"
        >
            <Modal.Header><h6>Filter by Task State</h6></Modal.Header>
            <Modal.Body className="device-filter-modal">
                {listItems.map((row, index) => 
                    <Form.Check
                        key={index}
                        type="checkbox"
                        label={row}
                        name="taskState"
                        id={'taskStaterow' + index}
                        value={row}
                        onChange={handleFormUpdate}
                        checked={isChecked(row)}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={handleOnHide}>Cancel</Button>
                <Button variant="primary" onClick={handleSave}>Apply</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default TaskStateModal;