


// type gatewayData{
//   epoch: Float
//   dateTime: String
//   snr: Float,
//   rssi: Float,
// }
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Table } from 'react-bootstrap';
import PageWrapper from '../../../components/PageWrapper';
import ContentHeader from '../../../components/ContentHeader';
import { GET_GATEWAY_DATA } from '../../../GraphQL/Queries/Things';

const Gateway = (props) => {
  const [dashboardData, setMessages] = useState(null);

  const {
    deveui,
    start,
    end,
  } = props;

  const { loading: gettingMessages, error: errorGettingMessages, data: dashboardMessages } = useQuery(
    GET_GATEWAY_DATA, { context: { service: 'things' }, variables: { deveui, start, end }, fetchPolicy: "network-only" });


  useEffect(() => {
    if (!gettingMessages && !errorGettingMessages) {
      console.log(deveui, start, end, dashboardMessages);
      const { getGatewayData: { results } } = dashboardMessages;
      if (results && results.length) {
        setMessages([...results]);
      }
    }
  }, [gettingMessages, errorGettingMessages, dashboardMessages, deveui, end, start]);


  return (
    <PageWrapper>
      <div className="content-wrapper">
        <ContentHeader title="Gateway" />
      </div>

      <Table responsive>
        <thead>
          <tr>
            <th>Date/Time</th>
            <th>snr</th>
            <th>rssi</th>
            <td>Vcc_v1000</td>
            <td>Intern_temp_c10</td>
          </tr>
        </thead>
        <tbody>
          {dashboardData !== null && dashboardData.length > 0 &&
            dashboardData.map((row, index) => {
              return (
                <tr key={index}>
                  <td>{row.dateTime}</td>
                  <td>{row.snr}</td>
                  <td>{row.rssi}</td>
                  <td>{row.Vcc_v1000}</td>
                  <td>{row.Intern_temp_c10}</td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
    </PageWrapper>
  );
}


export default Gateway;

// type gatewayData{
//   epoch: Float
//   dateTime: String
//   snr: Float,
//   rssi: Float,
//   Vcc_v1000: Float,
//   Intern_temp_c10: Float
// }
