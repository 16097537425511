import React, { useCallback, useState } from 'react';
import { 
    Accordion, 
    Card, 
} from 'react-bootstrap';
import PageWrapper from '../../components/PageWrapper';
import { getIsManager, getIsGrower, getIsAgronomist, getUserGUID, getUserObject, getUserDetails } from '../../services/User';
import AccordionHeader from '../../components/AccordionHeader';
import Growers from './Growers';
import GrowerFields from './GrowerFields';
import ManagedPeople from './ManagedPeople';

import './orders.scss';


const OrdersComponent = () => {
    const user = getUserObject();
    const userDetails = getUserDetails();
    const guid = getUserGUID();
    const isManager = getIsManager();
    const isGrower = getIsGrower();
    const isAgronomist = getIsAgronomist();

    const [ growerOpen, setGrowerOpen ] = useState(null);
    const [ listToView, setListToView ] = useState(null);
    const [ fieldOpen, setFieldOpen ] = useState(null);

    const handleFieldOpen = (field) => {
        if ( field !== fieldOpen ) {
            setFieldOpen(field);
        } else {
            setFieldOpen(null);
        }
    }

    const handleSetListToView = useCallback((list) => {
        if ( list !== listToView ) {
            setListToView(list);
        } else {
            setListToView(null);
        }
    }, [listToView]);

    const handleGrowerOpen = (grower) => {
        if ( grower !== growerOpen ) {
            setGrowerOpen(grower);
        } else {
            setGrowerOpen(null);
        }
    }

    return(
        <PageWrapper className="orders-page-wrapper">
            <div className="content-wrapper">
                <Accordion>
                    {isGrower &&
                        <Card>
                            <AccordionHeader 
                                eventKey={'fields'} 
                                onClick={() => handleSetListToView('fields') } 
                                isOpen={growerOpen}
                            >
                                Your Fields
                            </AccordionHeader>
                            <Accordion.Collapse eventKey={'fields'} mountOnEnter unmountOnExit>
                                <Card.Body>
                                    <GrowerFields
                                        fieldOpen={fieldOpen}
                                        grower={userDetails}
                                        handleFieldOpen={handleFieldOpen}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    }
                    {isAgronomist &&
                        <Card>
                            <AccordionHeader
                                eventKey={'growers'}
                                onClick={() => { handleSetListToView('growers') }}
                                isOpen={listToView}
                            >
                                Your Growers
                            </AccordionHeader>
                            <Accordion.Collapse eventKey={'growers'} mountOnEnter unmountOnExit>
                                <Card.Body>
                                    <Accordion>
                                        <Growers
                                            agronomist={user}
                                            guid={guid}
                                            growerOpen={growerOpen}
                                            handleGrowerOpen={handleGrowerOpen}
                                        />
                                    </Accordion>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    }
                    {isManager &&
                        <Card>
                            <AccordionHeader
                                eventKey={'managedPeople'}
                                onClick={() => { handleSetListToView('managedPeople') }}
                                isOpen={listToView}
                            >
                                Your People
                            </AccordionHeader>
                            <Accordion.Collapse eventKey={'managedPeople'} mountOnEnter unmountOnExit>
                                <Card.Body>
                                    <ManagedPeople guid={guid} />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    }
                </Accordion>
            </div>
        </PageWrapper>
    );
}

export default OrdersComponent;