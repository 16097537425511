import React from 'react';
import PageWrapper from '../../components/PageWrapper';

const People = () => {
    return(
        <PageWrapper>
            <div className="content-wrapper">
                People Go Here
            </div>
        </PageWrapper>
    )
}

export default People;