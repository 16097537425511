import { gql } from '@apollo/client';

export const GET_TRIBUS_DEVICE_MOISTURE_CHART = gql`
    query getTribusDeviceMoistureChart($key:String, $start:Float, $end:Float, $sensorSerial:String, $depthMeasurement:String){
        getTribusDeviceMoistureChart(key:$key, start:$start, end:$end, sensorSerial:$sensorSerial, depthMeasurement:$depthMeasurement)
    }
`;

export const GET_TRIBUS_DEVICE_AVERAGE_MOISTURE_CHART =  gql`
    query getTribusDeviceAverageMoistureChart($key:String, $start:Float, $end:Float, $sensorSerial:String, $depthMeasurement:String, $probesToAverage:[Int]) {
        getTribusDeviceAverageMoistureChart(key:$key, start:$start, end:$end, sensorSerial:$sensorSerial, depthMeasurement:$depthMeasurement, probesToAverage:$probesToAverage)
    }
`;