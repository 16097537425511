export function size(collection) {
    let count = 0;
    if ( collection instanceof Array ) {
        count = collection.length;
    } else if ( collection instanceof Object ) {
        let key;
        for (key in collection) {
            if (collection.hasOwnProperty(key)) {
                count++;
            }
        }
    }

    return count;
}

export function properCase(name) {
    const proper = name.replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );

    return proper;
}

export function isNumeric(val) {
    return (!isNaN(parseFloat(val)) && isFinite(val));
}
    
export function sortCollection(collection, keys, reverse, primer) {
    let allSorted = JSON.parse(JSON.stringify(collection));

    reverse = reverse || false;

    if ( collection instanceof Array ) {
        keys = keys || {};
        
        let obLen = function(obj) {
            let size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) {
                    size++;
                }
            }
            return size;
        };

        // avoiding using Object.keys because i guess did it have IE8 issues?
        // else let obIx = fucntion(obj, ix){ return Object.keys(obj)[ix]; } or
        // whatever
        let obIx = function(obj, ix) {
            let size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if (size === ix) {
                        return key;
                    }
                    size++;
                }
            }
            return false;
        };

        let keySort = function(a, b, d) {
            let originalA, originalB;
            
            d = d !== null ? d : 1;
            
            a = (typeof a !== 'undefined') ? ( isNumeric(a)) ? a : a.toLowerCase() : '';
            b = (typeof b !== 'undefined') ? ( isNumeric(b)) ? b : b.toLowerCase() : '';
            originalA = a;
            originalB = b;

            //
            // Handle special sorting scenarios
            // 0.00 should be a larger number than empty strings
            // Empty strings should be a larger number than '- -'
            switch (a) {
                case '0.00' :
                    a = 0.00;
                    break;

                case '' :
                    a = (isNumeric(originalB)) ? -1000000000 : ' ';
                    break;

                case '- -' :
                    a = (isNumeric(originalB)) ? -10000000000 : '';
                    break;

                default :
                    break;
            }

            switch (b) {
                case '0.00' :
                    b = 0.00;
                    break;

                case '' :
                    b = (isNumeric(originalA)) ? -1000000000 : ' ';
                    break;

                case '- -' :
                    b = (isNumeric(originalA)) ? -10000000000 : '';
                    break;

                default :
                    break;
            }

            //
            // value is number/float
            if ( !isNaN(parseFloat(a)) && isFinite(a) ) {
                return d === 1 ? a - b : b - a;
            //
            // value is string
            } else {
                return a > b ? 1 * d : a < b ? -1 * d : 0;
            }
        };

        let KL = obLen(keys);

        if (!KL) {
            return allSorted.sort(keySort);
        }
        
        for ( let k in keys) {
            // asc unless desc or skip
            if ( keys[k] ) {
                keys[k] = (keys[k] === 'desc' || keys[k] === -1)  ? -1 : 
                        (keys[k] === 'skip' || keys[k] === 0 ? 0 : 1);
            }
        }
        
        // console.log("ALL SORTED", allSorted);
        allSorted.sort(function(a, b) {
            let sorted = undefined, ix = 0;

            while (!sorted && ix < KL) {
                let k = obIx(keys, ix);

                if (k) {
                    let dir = keys[k];
                    sorted = keySort(a[k], b[k], dir);
                    ix++;
                }
            }

            return sorted;
        });

        return allSorted;
    }

    return [];
}