import { gql } from '@apollo/client';

export const ADD_ORDER = gql`
    mutation AddOrder($order: InputOrder){
        addOrder(order: $order){
            ok
            message
            results{
                guid
            }
        }
    }
`;

export const UPDATE_ORDER = gql`
    mutation UpdateOrder($guid: ID!, $order: InputOrder){
        updateOrder(guid: $guid order: $order){
            ok
            message
            results{
                guid
            }
        }
    }
`;
export const UPDATE_ORDER_LAT_LNG = gql`  
    mutation UpdateLineItemLatLng($orderGuid: ID!, $lineItemGuid: ID!, $lat:Float!, $lng:Float!){
        updateLineItemLatLng( orderGuid: $orderGuid, lineItemGuid: $lineItemGuid, lat:$lat, lng:$lng){
            ok 
            success
            message
        }
    }
`;

export const DELETE_ORDER = gql`
    mutation DeleteOrder($guid: ID!){
        deleteOrder(guid: $guid){
            ok
            message
        }
    }
`;