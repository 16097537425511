import React, { useState, useEffect, useRef, useCallback } from 'react';
import GoogleMapReact from 'google-map-react'
import { Button, Modal } from 'react-bootstrap';
import { Icon } from '../../common/icons';
import { GMAPS_API_KEY } from '../../config';
import MarkerModal from './MarkerModal';
import { useToasts } from 'react-toast-notifications';
// import { ADD_THING, SAVE_GATEWAY } from '../../GraphQL/Mutations/Things';
import { SAVE_GATEWAY } from '../../GraphQL/Mutations/Things';
import { useMutation } from '@apollo/client';

const MapComponent = (props) => {
    const mapRef = useRef(null);
    const mapsRef = useRef(null);
    const { addToast } = useToasts();
    const {
        defaultZoom,
        fields,
        gateways,
        technicians,
        allCurrentGps,
        boundaries,
        gpsHistory,
        handleAddPin,
        addingGateway,
        pendingMarker,
        onMarkerClick,
        position,
        selectedDevice,
        thingsForMap,
        refreshMap,
    } = props;

    const [mapDevices, setMapDevices] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [gmGateways, setGMGateways] = useState(null);
    const [gmCenter] = useState(null);
    const [gmFields, setGMFields] = useState(null);
    const [mapPendingMarker, setPendingMarker] = useState(null);
    const [mapThings, setMapThings] = useState(null);
    const [reloadPins, setReloadPins] = useState(false);
    const [currentLocation, setCurrentLocation] = useState(null)
    // const [infoWindowOpen, setInfoWindowOpen] = useState(null);

    // const [addThing, { called: calledAddThing }] = useMutation(ADD_THING);
    const [saveGateway, { called: calledGatewaySave }] = useMutation(SAVE_GATEWAY);
    const LocPin = (props) => {
        const { marker, onClick } = props;
        const iconSize = () => {
            return (selectedDevice !== null && marker !== null && selectedDevice.properties && selectedDevice.properties.guid === marker.guid) ? 48 : 28;
        }

        return (
            <>
                <div className="map-marker"
                    // onClick={handleOnClick()}
                    onClick={(e) => { onClick(e, marker) }}
                >
                    <Icon
                        type="pins"
                        name={marker.pinFileName}
                        // fill="#000"
                        width={iconSize()}
                    />
                </div>
            </>
        );
    }

    const GatewayPin = (props) => {
        const { marker, onClick } = props;
        const iconSize = () => {
            return (selectedDevice !== null && marker !== null && selectedDevice.properties && selectedDevice.properties.guid === marker.guid) ? 48 : 28;
        }

        return (
            <>
                <div className="map-marker"
                    // onClick={handleOnClick()}
                    onClick={(e) => { onClick(e, marker) }}
                >
                    <Icon
                        type="pins"
                        name={marker.pinFileName}
                        // fill="#000"
                        width={iconSize()}
                        radius={804.672}
                    />
                </div>
            </>
        );
    }

    const TechnicianPin = (props) => {
        const { marker, onClick } = props;
        const iconSize = () => {
            return 28;
        }

        return (
            <>
                <div className="map-marker"
                    // onClick={handleOnClick()}
                    onClick={(e) => { onClick(e, marker) }}
                >
                    <Icon
                        type="pins"
                        name={marker.pinFileName}
                        // fill="#000"
                        width={iconSize()}
                        radius={804.672}
                    />
                </div>
            </>
        );
    }

    const GPSPin = (props) => {
        const { marker, onClick } = props;
        const iconSize = () => {
            return 20;
        }

        return (
            <>
                <div className="map-marker"
                    // onClick={handleOnClick()}
                    onClick={(e) => { onClick(e, marker) }}
                >
                    <Icon
                        type="pins"
                        // name={marker.pinFileName}
                        fill="#17e1e1"
                        width={iconSize()}
                    />
                </div>
            </>
        );
    }


    const MyLocation = (props) => {
        // const { marker, onClick } = props;
        const iconSize = () => {
            return 20;
        }
        // const { lat, lng } = currentLocation;
        return (
            <>
                <div className="map-marker">
                    <Icon
                        type="pins"
                        name="pinPersonBlue.svg"
                        width={iconSize()}
                    />
                </div>
            </>
        )
    }
    const GPSCurrentPin = (props) => {
        const { marker, onClick } = props;
        const iconSize = () => {
            return 20;
        }

        return (
            <>
                <div className="map-marker"
                    // onClick={handleOnClick()}
                    onClick={(e) => { onClick(e, marker) }}
                >
                    <Icon
                        type="pins"
                        // name={marker.pinFileName}
                        fill="blue" //"#17e1e1"
                        width={iconSize()}
                    />
                </div>
            </>
        );
    }
    /* **
    *
    * Marker Related Methods
    *
    */

    const removeMarker = async () => {
        // const list = mapDevices;
        // const device = mapDevices[infoWindowOpen];
        // for (let i = 0; i < list.length; i++) {
        //     if (device.lat === list[i].lat && device.lng === list[i].lng) {
        //         index = i;
        //     }
        // }

        // TODO: Remove pin from thing/line item
    }

    const handleCancelPin = useCallback(() => {
        setPendingMarker(null);
        // mapRef.current.classList.remove('opened');
        // mapRef.current.classList.remove('covered');
    }, [setPendingMarker])

    const handleMarkerClick = (event, thing) => {
        event.stopPropagation();
        onMarkerClick(thing);
    }

    const showTechInfo = (e, tech) => {
        e.stopPropagation();
        const technician = {
            name: tech.properties.name,
            location: tech.properties.city + ", " + tech.properties.state,
            lng: tech.properties.lng,
            lat: tech.properties.lat
        }
        var content = "<p>" + technician.name + "</p>";

        if (technician.phone) {
            content += "<p>Phone:" + technician.phone + "</p>"
        }

        if (technician.email) {
            content += "<p>Email:" + technician.email + "</p>"
        }

        if (technician.location) {
            content += "<p>" + technician.location + "</p>"
        }
        const infoWindow = new mapsRef.current.InfoWindow({ content });
        infoWindow.setPosition({ lng: technician.lng, lat: technician.lat });
        infoWindow.open(mapRef.current);
    }
    const showCurrentGpsInfo = (e, gps) => {
        e.stopPropagation();
        const lat = getLat(gps);
        const lng = getLng(gps);
        var content = "<p>device " + gps.properties.deveui + "</p>";
        content += "<p>hex " + gps.properties.hex + "</p>";
        content += "<p>lat " + lat + "</p>";
        content += "<p>lat " + lng + "</p>";
        content += "<p>date " + gps.properties.dateTime + "</p>";

        const infoWindow = new mapsRef.current.InfoWindow({ content });
        infoWindow.setPosition({ lng, lat });
        infoWindow.open(mapRef.current);
    }
    const handleCloseRemoveModal = () => {
        setShowRemoveModal(false);
    }
    const getLat = (thing) => {
        let lat = undefined;
        if (thing.lat || thing.latitude) {
            lat = thing.lat || thing.latitude;

        } else if (thing.links) {
            const links = thing.links;
            if (links.length > 0) {
                lat = links[0].lat || links[0].latitude;
            }

        } else if (thing.lineItems && thing.lineItems.length > 0) {
            const { lineItems } = thing;
            lat = lineItems[0].lat || lineItems[0].latitude;
        } else if (thing.geometry) {
            lat = thing.geometry.coordinates[1];
        }

        lat = parseFloat(lat);
        return lat;
    }
    const getLng = (thing) => {
        let lng = undefined;

        if (thing.lng || thing.longitude) {
            lng = thing.lng || thing.longitude;

        } else if (thing.links) {
            const links = thing.links;
            if (links.length > 0) {
                lng = links[0].lng || links[0].longitude;
            }

        } else if (thing.lineItems && thing.lineItems.length > 0) {
            const { lineItems } = thing;
            lng = lineItems[0].lng || lineItems[0].longitude;
        } else if (thing.geometry) {
            lng = thing.geometry.coordinates[0];
        }

        lng = parseFloat(lng);
        return lng
    }
    const thingHasValidLat = (thing) => {
        let lat = undefined;

        if (thing === null) {
            return false;
        }

        if (thing.lat || thing.latitude) {
            lat = thing.lat || thing.latitude;

        } else if (thing.links) {
            const links = thing.links;
            if (links.length > 0) {
                lat = links[0].lat || links[0].latitude;
            }

        } else if (thing.lineItems && thing.lineItems.length > 0) {
            const { lineItems } = thing;
            lat = lineItems[0].lat || lineItems[0].latitude;
        } else if (thing.geometry) {
            lat = thing.geometry.coordinates[1];
        }

        return (typeof lat !== 'undefined' && lat !== null && lat !== '' && lat !== 0);
    }
    const thingHasValidLng = (thing) => {
        let lng = undefined;

        if (thing === null) {
            return false;
        }

        if (thing.lng || thing.longitude) {
            lng = thing.lng || thing.longitude;

        } else if (thing.links) {
            const links = thing.links;
            if (links.length > 0) {
                lng = links[0].lng || links[0].longitude;
            }

        } else if (thing.lineItems && thing.lineItems.length > 0) {
            const { lineItems } = thing;
            lng = lineItems[0].lng || lineItems[0].longitude;
        } else if (thing.geometry) {
            lng = thing.geometry.coordinates[0];
        }

        return (typeof lng !== 'undefined' && lng !== null && lng !== '' && lng !== 0);

    }
    /*
    *
    * // Marker Related Methods
    *
    ** */


    /* **
    *
    * Map Related Methods
    *
    */
    // const hasAnOrderList = () => {
    //     return (
    //         thingsForMap
    //         &&
    //         thingsForMap !== null
    //         &&
    //         thingsForMap.length
    //         &&
    //         thingsForMap.length > 0
    //     );
    // }

    // const hasAGatewayList = () => {
    //     return (
    //         gateways
    //         &&
    //         gateways !== null
    //         &&
    //         gateways.length
    //         &&
    //         gateways.length > 0
    //     );
    // }

    const addPoly = () => {
        if (!fields) {
            return;
        }

        const coordsArr = [];
        const coords = fields;
        for (let i = 0; i < coords.length; i++) {
            const coord = coords[i];
            const newPoint = { lat: coord[1], lng: coord[0] }
            coordsArr.push(newPoint);
        }

        const poly = new mapsRef.current.Polygon({
            paths: coordsArr,
            strokeColor: "red",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "white",
            fillOpacity: 0,
        });

        poly.setMap(mapRef.current);
    }

    const addGateways = () => {
        if (!mapsRef || mapsRef.current === null) {
            return;
        }
        const circles = [];

        if (gmGateways && gmGateways !== null) {
            for (let i = 0; i < gmGateways.length; i++) {
                if (gmGateways[i] !== undefined) {
                    gmGateways[i].setMap(null);
                }
            }
        }
        for (let i = 0; i < gateways.length; i++) {
            const gateway = gateways[i];

            if (gateway.properties.lat && gateway.properties.lng) {
                // const hasLinks = (gateway.links && gateway.links.length > 0);

                // if (hasLinks === true && !isNaN(gateway.links[0]['lat']) && !isNaN(gateway.links[0]['lng'])) {
                circles[i] = new mapsRef.current.Circle({
                    strokeColor: '#FFF',
                    strokeOpacity: 0.8,
                    strokeWeight: 1,
                    fillColor: '#FFF',
                    fillOpacity: 0.2,
                    map: mapRef.current,
                    center: {
                        lat: gateway.properties.lat,
                        lng: gateway.properties.lng
                    },
                    radius: 804.672,
                });
                // }
            }
        }

        setGMGateways([...circles]);
    };
    const addBoundaries = () => {
        if (!mapsRef || mapsRef.current === null) {
            return;
        }
        if (boundaries && boundaries !== null) {
            for (let i = 0; i < boundaries.length; i++) {
                const boundary = boundaries[i];
                const featureArray = [];
                if (boundary.length > 0) {
                    boundary.forEach((feature) => {
                        feature.geometry.coordinates.map(c => {
                            const pathArray = c.map(x => ({ lat: x[1], lng: x[0] }));
                            featureArray.push(pathArray);
                            return c;
                        });
                    });
                    const poly = new mapsRef.current.Polygon({
                        paths: featureArray,
                        strokeColor: "red",
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: "white",
                        fillOpacity: 0,
                    });
                    poly.setMap(mapRef.current);
                }
            }
        }

        // setGMBoundaries([...polyList]);
    };

    // Fit map to its bounds after the api is loaded
    const apiIsLoaded = (map, maps) => {
        if (map && maps) {
            mapRef.current = map;
            mapsRef.current = maps;
        } else {
            map = mapRef.current;
            maps = mapsRef.current;
        }

        if (gateways && gateways !== null) {
            addGateways();
        }
        if (boundaries && boundaries !== null) {
            addBoundaries();
        }

        if (gmCenter !== null) {
            gmCenter.setMap(null);
        }

        if (gmFields !== null && gmFields.length) {
            for (let i = 0; i < gmFields.length; i++) {
                gmFields[i].setMap(null);
            }
        }

        const gFields = [];

        setGMFields([...gFields]);

        addPoly();

        return maps;
    };

    const getMapBounds = () => {
        const maps = mapsRef.current;
        if (!maps) {
            return;
        }
        const bounds = new maps.LatLngBounds();
        if (gpsHistory && gpsHistory !== null) {
            const loopThings = gpsHistory;
            for (let i = 0; i < loopThings.length; i++) {
                const oneThing = loopThings[i];
                const lat = getLat(oneThing);
                const lng = getLng(oneThing);
                if (lat && lng) {
                    // if (thingHasValidLat(item) && thingHasValidLng(item)) {
                    bounds.extend(new maps.LatLng(
                        lat,
                        lng,
                    ));
                }

            }
        }

        // if (gateways && gateways.length) {
        //     gateways.forEach((circle) => {
        //         const lat = circle.properties.lat || circle.properties.latitude;
        //         const lng = circle.properties.lng || circle.properties.longitude;
        //         if (thingHasValidLat(circle.properties) && thingHasValidLng(circle.properties)) {
        //             bounds.extend(new maps.LatLng(
        //                 lat,
        //                 lng
        //             ));
        //         }
        //     });
        // }

        return bounds;
    };
    /*
    *
    * // Map Related Methods
    *
    ** */

    const onMapTypeIdChange = (mapTypeId) => {
        const type = mapTypeId.toString().toUpperCase();
        if (type === 'HYBRID' || type === 'SATELLITE') {
            for (let i = 0; i < gmGateways.length; i++) {
                gmGateways[i].setOptions({
                    strokeColor: '#FFF',
                    strokeOpacity: 0.5,
                    fillColor: '#FFF',
                    fillOpacity: 0,
                });
            }
        } else {
            for (let i = 0; i < gmGateways.length; i++) {
                gmGateways[i].setOptions({
                    strokeColor: '#000',
                    strokeOpacity: 0.5,
                    fillColor: '#000',
                    fillOpacity: 0,
                });
            }
        }
    }


    useEffect(() => {
        if (gpsHistory && gpsHistory !== null && gpsHistory.length > 0 && !reloadPins) {
            // Get bounds by our places
            const bounds = getMapBounds();
            // Fit map to bounds
            mapRef.current.fitBounds(bounds);
            // mapRef.current.setZoom(18);
            setMapThings(null);
            // setTFMBackup(gmGateways);
            // setGatewayBackup(mapDevices);
            setReloadPins(true);
            // console.log(gmGateways);
            navigator.geolocation.getCurrentPosition((position) => {
                const lat = position.coords.latitude;
                const lng = position.coords.longitude;
                setCurrentLocation({ lat, lng });
                console.log(setCurrentLocation);
            });
        } else {
            console.log();
        }
    }, [gpsHistory, reloadPins, mapRef, setMapThings, setReloadPins, mapThings]);

    useEffect(() => {
        if (!gpsHistory && thingsForMap && reloadPins) {
            setReloadPins(false);
            setMapThings(thingsForMap);
        }
    }, [gpsHistory, reloadPins, thingsForMap, mapThings, setReloadPins, setMapThings])
    useEffect(() => {
        if (thingsForMap && thingsForMap !== null) {
            setMapThings(thingsForMap);
        }
    }, [thingsForMap, setMapThings, mapThings])
    // Devices List
    useEffect(() => {
        if (gateways && gateways !== null && gateways.length > 0) {
            addGateways()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gateways]);
    useEffect(() => {
        if (boundaries && boundaries !== null && boundaries.length > 0) {
            addBoundaries()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boundaries]);

    useEffect(() => {
        if ((thingsForMap && thingsForMap !== null && thingsForMap.length
            && gateways && gateways !== null && gateways.length
            && boundaries && boundaries !== null && boundaries.length > 0
            && mapRef.current && mapsRef.current && refreshMap) || calledGatewaySave) {
            apiIsLoaded();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [thingsForMap]);

    useEffect(() => {
        if (addingGateway) {
            setPendingMarker({ "type": "Gateway", "deviceName": "", "pinFileName": "pinGatewaySilver.svg", "gatewayNeeded": false, "deviceNameNeeded": false, "state": "Not Ready" });
        }
    }, [addingGateway])

    const onMapClick = (mapProps) => {
        if (mapPendingMarker !== null && mapPendingMarker !== undefined && addingGateway) {
            const lat = mapProps.lat;
            const lng = mapProps.lng;
            const newMarker = {
                ...mapPendingMarker,
                lat,
                lng,
            };

            let devices = mapDevices;

            if (devices !== null && devices.length) {
                devices.push(newMarker);
            } else {
                devices = [newMarker];
            }

            setMapDevices([...devices]);
            setPendingMarker({ ...mapPendingMarker, ...{ lat, lng } });
            setModalShow(!modalShow);
        }
    }

    const onMarkerSave = async (form) => {
        const filteredMarker = mapDevices.filter((marker) => {
            return (marker.lat === mapPendingMarker.lat && marker.lng === mapPendingMarker.lng);
        });

        const updatedMarkerDetails = filteredMarker.map((marker) => {
            if (marker.lat === mapPendingMarker.lat && marker.lng === mapPendingMarker.lng) {
                if (marker.deviceNameNeeded === null || marker.deviceNameNeeded === '') {
                    marker.deviceNameNeeded = false;
                }

                if (form.gatewayNeeded === null || form.gatewayNeeded === '') {
                    marker.gatewayNeeded = false;
                    form.gatewayNeeded = false;
                }

                marker.name = '';
                form.name = '';

                if (form.gatewayName && form.gatewayName !== '') {
                    form.gatewayNeeded = true;
                    marker.gatewayNeeded = true;
                }

                delete marker['__typename'];
                delete form['gatewayName'];

                const combined = {
                    ...marker,
                    ...form
                };

                return combined;
            }

            return false;
        });

        // console.log("UPDATED MARKER DETAILS", updatedMarkerDetails);
        if (updatedMarkerDetails.length > 0) {
            // const growerData = { ...grower };

            // delete growerData['__typename'];
            const thisMarker = updatedMarkerDetails[0];

            delete thisMarker.deviceNameNeeded;
            delete thisMarker.gatewayNeeded;
            delete thisMarker.name;
            // TODO: SAVE THING TO DB
            // console.log("GATEWAY", thisMarker);
            const response = await saveGateway({
                variables: {
                    thing: thisMarker
                },
                context: {
                    service: 'things'
                }
            });

            const { data: { saveGateway: { ok } } } = response;

            if (ok && ok === true) {

                addToast('Gateway Has Been Saved', {
                    appearance: 'success',
                    autoDismiss: true,
                });

            } else {
                addToast('There was a problem creating the Gateway', {
                    appearance: 'error',
                });
            }
        }

        const updatedMarkers = mapDevices.map((marker) => {
            if (marker.lat === mapPendingMarker.lat && marker.lng === mapPendingMarker.lng) {
                const newObj = {
                    ...marker,
                    ...form
                }

                return newObj;
            } else {
                return marker;
            }
        });

        setMapDevices([...updatedMarkers]);

        handleAddPin(mapPendingMarker);
        setModalShow(!modalShow);
    }

    useEffect(() => {
        // setInfoWindowOpen(null);
        setPendingMarker(pendingMarker);
    }, [pendingMarker]);

    const onMarkerCancel = useCallback((form) => {
        handleCancelPin(mapPendingMarker);

        const newDevices = mapDevices.filter((marker) => {
            return (marker.lat !== mapPendingMarker.lat && marker.lng !== mapPendingMarker.lng);
        });

        setMapDevices([...newDevices]);
        handleAddPin(null);
        setPendingMarker(null);
        setModalShow(false);
    }, [mapPendingMarker, mapDevices, handleAddPin, handleCancelPin])

    return (
        <div className="google-map">
            <GoogleMapReact
                bootstrapURLKeys={{ key: GMAPS_API_KEY }}
                center={position}
                defaultZoom={defaultZoom || 10}
                ref={mapRef}
                onClick={onMapClick}
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
                options={{
                    mapTypeControl: true,
                    mapTypeId: 'hybrid',
                    fullscreenControl: false
                }}
                onMapTypeIdChange={onMapTypeIdChange}
            >
                {gateways && gateways !== null && gateways.map((row, index) =>
                    (row !== null && thingHasValidLat(row) && thingHasValidLng(row)) &&
                    <GatewayPin
                        key={index}
                        marker={row.properties}
                        lat={getLat(row)}
                        lng={getLng(row)}
                        selectedDevice={selectedDevice}

                        order={row.properties}
                        onClick={(e) => handleMarkerClick(e, row)}
                    />
                )}
                {allCurrentGps === null && mapThings && mapThings !== null && mapThings.map((row, index) =>
                    (row !== null && thingHasValidLat(row) && thingHasValidLng(row)) &&
                    <LocPin
                        key={index}
                        marker={row.properties}
                        lat={getLat(row)}
                        lng={getLng(row)}
                        selectedDevice={selectedDevice}

                        order={row.properties}
                        onClick={(e) => handleMarkerClick(e, row)}
                    />
                )}
                {technicians && technicians !== null && technicians.map((row, index) =>
                    (row !== null && thingHasValidLat(row) && thingHasValidLng(row)) &&
                    <TechnicianPin
                        key={index}
                        marker={row.properties}
                        lat={getLat(row)}
                        lng={getLng(row)}
                        selectedDevice={selectedDevice}
                        order={row.properties}
                        onClick={(e) => showTechInfo(e, row)}
                    />
                )}
                {allCurrentGps && allCurrentGps !== null && allCurrentGps.map((row, index) =>
                    (row !== null && thingHasValidLat(row) && thingHasValidLng(row)) &&
                    <GPSCurrentPin
                        key={index}
                        marker={row.properties}
                        lat={getLat(row)}
                        lng={getLng(row)}
                        selectedDevice={selectedDevice}
                        order={row.properties}
                        onClick={(e) => showCurrentGpsInfo(e, row)}
                    />
                )}
                {gpsHistory && gpsHistory !== null && currentLocation && (
                    <MyLocation
                        lat={currentLocation.lat}
                        lng={currentLocation.lng}
                        name="pinPersonBlue.svg"
                    />
                )}
                {gpsHistory && gpsHistory !== null && gpsHistory.map((row, index) =>
                    (row !== null && thingHasValidLat(row) && thingHasValidLng(row)) &&
                    <GPSPin
                        key={index}
                        marker={row.properties}
                        lat={getLat(row)}
                        lng={getLng(row)}
                        selectedDevice={selectedDevice}

                        order={row.properties}
                        onClick={(e) => showTechInfo(e, row)}
                    />
                )}

            </GoogleMapReact>
            <MarkerModal
                show={modalShow}
                onHide={() => setModalShow(!modalShow)}
                onSave={onMarkerSave}
                onCancel={onMarkerCancel}
                pendingMarker={mapPendingMarker}
            />
            <Modal
                show={showRemoveModal}
                onHide={handleCloseRemoveModal}
            >
                <Modal.Header>
                    <Modal.Title>Remove Pin From Map?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to remove this pin from the map?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => setShowRemoveModal(false)}>Cancel</Button>
                    <Button variant="danger" onClick={removeMarker}>Yes, Remove the Pin</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default React.memo(MapComponent);