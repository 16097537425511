import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import { properCase } from '../../../services/Data';
import { GET_TECHNICIANS } from '../../../GraphQL/Queries/People';

const TechnicianModal = (props) => {
    const {
        filter,
        filterList,
        isOpen,
        setModalOpen
    } = props;

    const [technicians, setTechnicians] = useState([]);
    const [selectedTechs, setSelectedTechs] = useState([]);
    const { loading: gettingTechs, error: errorGettingTechs, data: techsData } = useQuery(
        GET_TECHNICIANS,
        {
            context: {
                service: 'people'
            }
        }
    );

    const handleOnHide = () => {
        setModalOpen(!isOpen);
    }

    const handleSave = () => {
        setModalOpen(!isOpen);
        filter(selectedTechs);
    }

    const isChecked = (guid) => {
        return (selectedTechs.indexOf(guid.toString()) > -1);
    }

    const handleFormUpdate = (event) => {
        let techs = selectedTechs;
        const index = techs.indexOf(event.target.value);
        if (index > -1) {
            techs.splice(index, 1);
        } else {
            techs.push(event.target.value);
        }

        setSelectedTechs([...techs]);
    }

    useEffect(() => {
        if (filterList === null && technicians !== null) {
            setSelectedTechs([]);
        }
        if (filterList !== null && filterList !== technicians) {
            setSelectedTechs([...filterList]);
        }
        // eslint-disable-next-line
    }, [filterList]);

    useEffect(() => {
        if (!gettingTechs && !errorGettingTechs) {
            const { getTechnicians: { results } } = techsData;
            setTechnicians([...results]);
        }
    }, [gettingTechs, errorGettingTechs, techsData]);

    return (
        <Modal
            show={isOpen}
            onHide={handleOnHide}
            size="sm"
        >
            <Modal.Header><h6>Filter by Technician</h6></Modal.Header>
            <Modal.Body className="device-filter-modal">
                {technicians.map((row, index) =>
                    <Form.Check
                        key={index}
                        type="checkbox"
                        label={properCase(row.name)}
                        name="taskState"
                        id={row.guid}
                        value={row.guid}
                        onChange={handleFormUpdate}
                        checked={isChecked(row.guid)}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={handleOnHide}>Cancel</Button>
                <Button variant="primary" onClick={handleSave}>Apply</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default TechnicianModal;